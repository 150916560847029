import { ApplicationState } from '../definitions';
import { selectHistoryFacilityMatch } from '../router/selector';

export function selectCurrentFacility(state: ApplicationState) {
  return state.uiSettings.sites.find(
    (fac) => fac.path === state.uiState.activeFacility
  )!;
}

export function getActualCurrentZones(state: ApplicationState) {
  return state.zoneData
    .filter((zn) => state.uiState.activeZoneIDs.indexOf(zn.id) > -1)!
    .sort((a, b) => b.name.localeCompare(a.name));
}

export function getSpecialSelect(state: ApplicationState) {
  return state.uiState.specialSelect;
}

export function selectCurrentZones(state: ApplicationState) {
  if (state.uiState.activeZoneIDs.length === 0) {
    return state.zoneData
      .filter((zn) => state.uiState.previousZoneIDsState.indexOf(zn.id) > -1)!
      .sort((a, b) => b.name.localeCompare(a.name));
  }
  if (state.uiState.allSelected) {
    return state.zoneData
      .filter((f) => f.sitePath === state.uiState.activeFacility)
      .sort((a, b) => b.name.localeCompare(a.name));
  }
  return state.zoneData
    .filter((zn) => state.uiState.activeZoneIDs.indexOf(zn.id) > -1)!
    .sort((a, b) => b.name.localeCompare(a.name));
}

export function selectAvailableZones(state: ApplicationState) {
  return state.zoneData;
}

export function selectAvailableFacilityZones(state: ApplicationState) {
  return state.zoneData.filter(
    (f) => f.sitePath == state.uiState.activeFacility
  );
}

export function getPriceActivation(
  state: ApplicationState,
  zoneId: number,
  scheduleId: number,
  pricingId: number,
  index: number
) {
  var zone = state.zoneData.find((f) => f.id === zoneId);
  var sch = zone?.schedules.find((s) => s.id === scheduleId);
  var activation = sch?.priceActivations[index];
  if (!activation) {
    return sch?.priceActivations.find((pa) => pa.priceID === pricingId);
  }
  return activation;
}
