import { Middleware } from 'redux';

import { makeZoneUpdatePoll } from './global/zoneUpdater';
import { compareUpdatePoll } from './global/compareUpdater';
import { ApplicationState } from './definitions';
import { calcCacheUpdate, compCalcCacheUpdate } from './calcCache/action';
import { uiSetCDataTS, uiSetDataTS } from './ui/action';
import moment from 'moment';
//import { buildReq, execUpdate } from './calcCache/zoneUpdateRequestHelpers';

// A Redux middleware to be connected for each store ("api")
export const graphDataUpdater_2_0: Middleware = (api) => {
  // per-reducer connection
  return (next) => {
    // compose 2 parallel updaters for regular and compare data
    const zoneUpdatePoll = makeZoneUpdatePoll(
      (state) => ({
        from: state.uiState.startDate,
        to: moment(state.uiState.endDate)
          .startOf('day')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      }),
      (newState) => newState?.calcCache?.cached,
      uiSetDataTS,
      calcCacheUpdate
    );
    const compZoneUpdatePoll = makeZoneUpdatePoll(
      (state) => ({
        from: state.uiState.compareStartDate!,
        to: moment(state.uiState.compareEndDate!)
          .startOf('day')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      }),
      (newState) => newState?.calcCache?.compareCached,
      uiSetCDataTS,
      compCalcCacheUpdate
    );

    return (action) => {
      // We just forward all messages to the regular reducers, we're more interested in listening to the post-state.
      next(action); // as ApplicationState;
      zoneUpdatePoll(api, action);
      // only run the compare updater if we have enabled compares.
      if ((api.getState() as ApplicationState).uiState.enableCompare) {
        compZoneUpdatePoll(api, action);
      }
      compareUpdatePoll(api, action);
    };
  };
};
