import { PriceV3Api } from 'constants/api-fetch';

class DashboardController {
  /**
   *
   */
  apiHandler: PriceV3Api | null = null;

  constructor() {
    this.apiHandler = new PriceV3Api();
  }

  fetchAnalysis = async (data: DashboardRequestModel, signal: AbortSignal) => {
    var result = await this.apiHandler!.post(
      `dashboard/analysis`,
      JSON.stringify(data),
      {},
      signal
    );
    return result as DashboardResponseModel;
  };

  fetchForecast = async (data: DashboardRequestModel, signal: AbortSignal) => {
    var result = await this.apiHandler!.post(
      `dashboard/forecast`,
      JSON.stringify(data),
      {},
      signal
    );
    return result as DashboardResponseModel;
  };
}

export default DashboardController;

export class DashboardResponseModel {
  current: DashboardResponseDataModel = new DashboardResponseDataModel();
  compare: DashboardResponseDataModel = new DashboardResponseDataModel();
  callId: string = '';
}

export class DashboardRequestModel {
  ZoneIds: number[] = [];
  Segments: number[] = [];
  StartDate: string = '';
  EndDate: string = '';
  CompareStartDate: string = '';
  CompareEndDate: string = '';
  CallId: string = '';
}

export class DashboardResponseData {
  total: number = 0;
  parkings: number = 0;
  amountOfDays: number = 0;
  capacity: number = 0;
  paxEntries?: number;
  paxInland?: number;
  paxOutland?: number;
}

export class DashboardResponseDataModel extends DashboardResponseData {
  booking?: DashboardResponseData;
  driveup?: DashboardResponseData;
}
