import SeasonController from 'api/seasons';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import Modal from 'components/Modal';
import useModalStack from 'components/Modal/useModalStack';
import SubHeader from 'components/SubHeader';
import Button from 'components/input/Button';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import Select from 'components/input/Select';
import moment, { min } from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule } from 'store/zone/action';
import { PricingScheduleSpan } from 'store/zone/reducer';
import { selectCurrentFacility } from 'store/zone/selector';
import styled from 'styled-components';

interface Props {
  model?: PricingScheduleSpan;
  seasonList: PricingScheduleSpan[];
  onComplete: (callback: () => void) => void;
}

export interface PricingScheduleUpsertModel {
  name: string;
  startDate: moment.Moment;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'a b'
    'c c'
    'd d';
  grid-template-rows: repeat(60px);
  gap: 20px;
`;

const UpsertSeasonModel: React.FC<Props> = ({
  model,
  seasonList,
  onComplete,
}) => {
  const isAdd = !model;

  var _baseModel = {
    name: '',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
  } as Partial<PricingScheduleSpan>;
  if (!!model) {
    _baseModel = model;
  }

  var modalStack = useModalStack();

  const facility = useSelector(selectCurrentFacility);
  const [editModel, setEditModel] =
    useState<Partial<PricingScheduleSpan>>(_baseModel);
  const [seasonId, setSeasonId] = useState<string>(
    seasonList[0].recurringID ?? ''
  );
  var seasonController = new SeasonController();
  var dispatch = useDispatch();

  const handleUpserSeason = async () => {
    if (isAdd) {
      var result = await seasonController.createFacilitySeason({
        season: editModel,
        facilityId: facility.id,
        copySeasonRecurringGuid: seasonId,
      });
      dispatch(updateSchedule(result));
    } else {
      var result = await seasonController.updateFacilitySeason(
        editModel as PricingScheduleSpan
      );
      dispatch(updateSchedule(result));
    }
    onComplete(() => {
      modalStack.pop();
    });
  };

  return (
    <Modal title={isAdd ? 'Add season' : 'Edit season'}>
      <p
        style={{
          fontStyle: 'italic',
          fontSize: '12px',
        }}
      >
        A season will repeat every year from the same date until it reaches
        another block.
      </p>

      <div style={{ minHeight: '340px', position: 'relative' }}>
        <Wrapper>
          <Label label="Name" labelStyle={{ gridArea: 'a' }}>
            <Input
              type="text"
              value={editModel.name}
              onChange={(e) =>
                setEditModel({ ...editModel, name: e.target.value })
              }
            />
          </Label>

          <Label label="Initial Start Date" labelStyle={{ gridArea: 'b' }}>
            <PTDatePicker
              dateFormat={'yyyy-MM-dd'}
              selected={moment(editModel.startDate).toDate()}
              onChange={(date) =>
                setEditModel({
                  ...editModel,
                  startDate: moment(date).format('YYYY-MM-DD'),
                })
              }
            />
          </Label>

          {isAdd ? (
            <Label
              labelStyle={{ gridArea: 'c' }}
              label="Copy values from Season"
            >
              <Select
                value={seasonId}
                onChange={(e) => {
                  setSeasonId(e.target.value);
                }}
              >
                {seasonList.map((item) => (
                  <option key={item.recurringID} value={item.recurringID}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Label>
          ) : null}
        </Wrapper>
        <Button
          style={{
            position: 'absolute',
            maxWidth: '300px',
            margin: '0 auto',
            left: '0',
            right: '0',
            bottom: '20px',
          }}
          onClick={handleUpserSeason}
        >
          {isAdd ? 'Add season' : 'Update season'}
        </Button>
      </div>
    </Modal>
  );
};

export default UpsertSeasonModel;
