import React, { useContext, useState } from 'react';
import { GraphData, GraphPoint } from './defs';
import { DataPoint } from './GenericLineCharts';

interface DemandState {
  currentAcc: number;
  oldAcc: number;
  diff: number;
}

// Create a dummy context w/ a nil value and no-op setter action
const demandContext = React.createContext({
  demand: { currentAcc: 0, oldAcc: 0, diff: 0 },
  graphData: [] as DataPoint[],
  setAccDemand: ((v: DemandState) => {}) as React.Dispatch<
    React.SetStateAction<DemandState>
  >,
  setGraphData: (v: GraphData) => {},
});

export const DemandContextProvider: React.FC = ({ children }) => {
  const [demand, insetAccDemand] = useState({
    currentAcc: 0,
    oldAcc: 0,
    diff: 0,
  });

  const [graphData, insetGraphData] = useState<DataPoint[]>([]);

  const setAccDemand = ((v: DemandState) => {
    if (
      v.currentAcc != demand.currentAcc ||
      v.diff != demand.diff ||
      v.oldAcc != demand.oldAcc
    ) {
      insetAccDemand(v);
    }
  }) as React.Dispatch<React.SetStateAction<DemandState>>;

  const setGraphData = (data: GraphData) => {
    var dataPoints = data.data.map((f) => f as any as DataPoint);
    data.seriesIDs.forEach((curr, index) => {
      data.data.forEach((x, dIndex) => {
        dataPoints[dIndex!][curr!] = x.series[index]!;
      });
    });
    insetGraphData(dataPoints);
  };

  return (
    <demandContext.Provider
      value={{ demand, graphData, setAccDemand, setGraphData }}
    >
      {children}
    </demandContext.Provider>
  );
};

export const useDemandContext = () => useContext(demandContext);
