import { ApplicationState } from '../definitions';
import { CCState, PriceViewType } from './definitions';

export const selectCalcCacheData = (root: ApplicationState) =>
  root.calcCache.cached;
export const selectCompCalcCacheData = (root: ApplicationState) =>
  root.calcCache.compareCached;

// export const selectCalcCachePriceSeries = (root: ApplicationState) =>
//   root.calcCache.priceSeries;
