import React from 'react';
import { useSelector } from 'react-redux';
import { selectUIStateRevView } from 'store/ui/selector';
import GraphBase from './GraphBase';
import { demandTransformer } from './demandTransformer';
import { revenueTransformer } from './revenueTransformer';
import { revenueChangeTransformer } from './revenueChangeTransformer';
import { npricingTransformer } from './npricingTransformer';
import { GTransformer, GraphDataBundle } from './defs';
import { getUser } from 'store/uiSettings/selectors';
import useLocalStorage from 'provider/useLocalStorage';

const Graph: React.FC<{
  kind: 'demand' | 'revenue' | 'pricing';
  onEventClick?: (
    e: SVGGElement,
    elementIndex: number,
    index: number,
    data: GraphDataBundle
  ) => void;
}> = ({ kind, onEventClick }) => {
  const user = useSelector(getUser);
  const revView = useSelector(selectUIStateRevView);
  const { getGraphViewKey, read } = useLocalStorage();
  var key = getGraphViewKey(user!.id!);
  //console.log(revView);
  return (
    <GraphBase
      graphView={read(key) === '' ? 'bars' : read(key)!}
      kind={kind}
      onEventClick={onEventClick}
      transformer={
        kind === 'revenue'
          ? revView === 'regular'
            ? revenueTransformer
            : revenueChangeTransformer
          : kind === 'demand'
          ? demandTransformer
          : kind === 'pricing'
          ? npricingTransformer
          : (alert('TODO: transformer=' + kind) as any as GTransformer)
      }
    />
  );
};

export default Graph;
