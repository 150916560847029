import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CloseButton from './CloseButton';
import useModalStack, { ModalStackType } from './useModalStack';
import Label from 'components/input/Label';
import { AiOutlineClose } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';

const labelClass = 'labelClass';

interface Props {
  children: React.ReactNode;
  id: string;
  title: string;
}

const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 202;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.5s ease-in-out;
  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;

const Wrapper = styled.div<{ show: boolean }>`
  width: 500px;
  border-left: 2px solid ${({ theme }) => theme.palette.common.lightBorder};
  padding: 0;
  margin: 0;
  margin-right: -300px;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  transition: margin 0.5s ease-in-out;

  ${({ show }) =>
    show &&
    css`
      margin-right: 0;
    `}
`;

const TopWrapper = styled.div`
  position: relative;
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
  padding: 10px;
  align-items: center;
  height: 50px;

  .${labelClass} {
    margin: 0;
    height: auto;

    span {
      margin: 0;
    }
  }
`;
const ContentWrapper = styled.div`
  height: calc(100% - 52px);
`;

const SlideInPopup: React.FC<Props> = ({ id, title, children }) => {
  const [show, setShow] = useState<boolean>(false);

  const modalStack = useModalStack();

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Overlay
      show={show}
      onClick={() => {
        handleClose();
      }}
    >
      <Wrapper
        show={show}
        onTransitionEnd={() => {
          if (show === false) {
            modalStack.pop(ModalStackType.SLIDE_IN, id);
          }
        }}
      >
        <TopWrapper>
          <Label label={title} className={'labelClass'} />
          <IoClose size={26} onClick={handleClose} />
        </TopWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </Wrapper>
    </Overlay>
  );
};

export default SlideInPopup;
