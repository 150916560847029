import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { AiOutlineCheck } from 'react-icons/ai';
import baseInputStyle from './baseInputStyle';
import { FaCheck } from 'react-icons/fa';
//import { FacilitySpecialZonePicker } from 'store/ui/uiutil';

interface GridSelectOption {
  key: string;
  //icon: JSX.Element | ((props: any) => JSX.Element);
  shortCode: string;
  hintText: string;
  title: string;
}
interface GridSelectItem {
  key: string;
  text: string;
  options: GridSelectOption[];
  disabled?: boolean;
}
interface Props {
  selected: string[];
  available: GridSelectItem[];
  extended?: boolean;
  onToggle: (toggleKey: string) => void;
}

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  flex: 1;
  width: 100%;
`;

const SelectedWrapper = styled.div`
  ${baseInputStyle}
  padding: 6px 10px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const MultiSelectWindow = styled.div<{ extended: boolean; amount: number }>`
  position: absolute;
  top: 42px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 5px;
  width: 100%;
  background: white;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 25;
  > div {
    display: grid;
    grid-template-columns: 1fr repeat(${({ amount }) => amount - 1}, 50px);
  }

  ${({ extended }) =>
    extended &&
    css`
      max-height: 800px;
    `}
`;

const SelectOption = styled.div<{ even: boolean }>`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.palette.common.lightBorder};
  display: flex;
  align-items: center;

  ${({ even }) =>
    !even &&
    css`
      background: #f8f8f8;
    `}
`;

const ColumnWrapper = styled(SelectOption)``;

const SelectName = styled.div<{ disabled?: boolean }>`
  flex: 1;
  color: ${(props) =>
    !!props.disabled
      ? props.theme.palette.disabled.fg
      : props.theme.palette.text.fg};
`;

const ToggleSelect = styled.div`
  flex: 0;
  justify-self: center;
  min-width: 20px;
  width: 20px;
  max-width: 20px;
  min-height: 20px;
  height: 20px;
  max-height: 20px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.text.fg};
  }
`;

const ShortName = styled.div<{ toggled: boolean }>`
  color: ${(props) => (props.toggled ? '#000000' : '#c0c0c0')};

  :hover {
    color: ${(props) => (props.toggled ? '#505050' : '#909090')};
  }
`;

const MultiGridSelect: React.FC<Props> = ({
  selected,
  available,
  onToggle,
  // onChange,
  // onChangeAll,
  // facilityID,
  // allSelected = false,
  // selectAll = false,
  // selectAllLabel = 'Select All',
  // specialSelect = [],
  extended = false,
}) => {
  const wrapperRef = useRef<any>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [currentlySelected, setCurrentlySelected] =
  //   useState<string[]>(selected);

  const openSelected = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   setCurrentlySelected(selected);
  // }, [facilityID]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current!.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  var columns =
    available.length == 0
      ? ['']
      : ['', ...available[0].options.map((f) => f.title)];

  return (
    <Wrapper ref={wrapperRef}>
      <SelectedWrapper onClick={() => openSelected()}>
        <div>
          {
            //available.length !== currentlySelected.length &&
            selected &&
              (available
                .flatMap((item) => {
                  return item.options
                    .filter((subItem) =>
                      selected.find((cs) => subItem.key === cs)
                    )
                    .map((subItem) => `${item.text} (${subItem.shortCode})`);
                })
                .join(', ') as string)
          }
          {selected.length === 0 && 'None selected'}
        </div>
      </SelectedWrapper>
      {isOpen && (
        <MultiSelectWindow amount={columns.length} extended={extended}>
          {/* {selectAll && (
            <SelectOption onClick={toggleAll}>
              <SelectName>{selectAllLabel ?? 'Select all'}</SelectName>
              <ToggleSelect>
                {(all || available.length === currentlySelected.length) && (
                  <AiOutlineCheck />
                )}
              </ToggleSelect>
            </SelectOption>
          )}
          {specialSelect.map((f) => (
            <SelectOption key={f.id} onClick={() => handleSpecialSelect(f)}>
              <SelectName>{f.name}</SelectName>
              <ToggleSelect>
                {f.zoneIds.reduce((acc, curr) => {
                  if (!currentlySelected.find((x) => x.id == curr)) {
                    return false;
                  }
                  return acc;
                }, true) && <AiOutlineCheck />}
              </ToggleSelect>
            </SelectOption>
          ))} */}
          <ColumnWrapper even={true}>
            {columns.map((f) => (
              <SelectName
                style={{
                  justifySelf: 'center',
                }}
              >
                {f}
              </SelectName>
            ))}
          </ColumnWrapper>
          {available.map((f: GridSelectItem, index) => (
            <SelectOption key={f.key} even={index % 2 === 0}>
              <SelectName disabled={f.disabled ?? false}>{f.text}</SelectName>

              {f.options.map((opt) => {
                return (
                  <ToggleSelect
                    onClick={() =>
                      f.disabled ?? false
                        ? () => console.log('disabled')
                        : onToggle(opt.key)
                    }
                    key={opt.key}
                  >
                    <ShortName
                      toggled={!!selected.find((sk) => sk === opt.key)}
                    >
                      {!!selected.find((sk) => sk === opt.key) && <FaCheck />}
                    </ShortName>
                  </ToggleSelect>
                );
              })}
            </SelectOption>
          ))}
        </MultiSelectWindow>
      )}
    </Wrapper>
  );
};

export default MultiGridSelect;
