import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Label from 'components/input/Label';
import baseInputStyle from 'components/input/baseInputStyle';
import { FaCalendarAlt } from 'react-icons/fa';
import { AiOutlineCalendar } from 'react-icons/ai';
import PTDatePicker from './DatePicker';
import DatePicker from 'react-datepicker';

const periodInputClass = 'periodInput';
const Container = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;
  position: relative;
`;

export interface DateRange {
  from: Date;
  to: Date;
}

interface DateRangePickerProps {
  onChange: (dateRange: DateRange) => void;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  startLabel: string;
  endLabel: string;
  fadeIt?: boolean;
  startDisable?: boolean;
  endDisabled?: boolean;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChange,
  startDate: initialStartDate,
  endDate: initialEndDate,
  startLabel,
  endLabel,
  fadeIt = false,
  startDisable = false,
  endDisabled = false,
}) => {
  const currentDate = moment();

  const [startDate, setStartDate] = useState<Date | undefined>(
    initialStartDate?.toDate()
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    initialEndDate?.toDate()
  );

  const startDatePickerRef = useRef<DatePicker>(null);
  const endDatePickerRef = useRef<DatePicker>(null);

  const handleStartDateChange = (date: Date) => {
    var _endDate = endDate;
    var _startDate = date;
    if (_startDate && endDate && _startDate >= endDate) {
      const nextDay = moment(date).clone().add(1, 'day');
      setEndDate(nextDay.toDate());
      _endDate = nextDay.toDate();
    }
    setStartDate(_startDate);
    onChange({ from: _startDate, to: _endDate! });
  };

  const handleEndDateChange = (date: Date) => {
    var _endDate = date;
    var _startDate = startDate;
    if (_endDate && startDate && _endDate <= startDate) {
      const previousDay = moment(date).clone().subtract(1, 'day');
      setStartDate(previousDay.toDate());
      _startDate = previousDay.toDate();
    }
    setEndDate(_endDate);
    console.log('HEYO', _startDate, _endDate);
    onChange({ from: _startDate!, to: _endDate });
  };

  const handleStartDatePickerOpen = () => {
    if (startDatePickerRef.current) {
      startDatePickerRef.current.setOpen(true);
    }
  };

  const handleEndDatePickerOpen = () => {
    if (endDatePickerRef.current) {
      endDatePickerRef.current.setOpen(true);
    }
  };

  return (
    <Container>
      <div>
        <Label label={startLabel} className={periodInputClass}>
          <PTDatePicker
            disabled={startDisable}
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            placeholderText="Select start date"
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            ref={startDatePickerRef}
            onFocus={handleStartDatePickerOpen}
          />
        </Label>
      </div>
      <div>
        <Label label={endLabel} className={periodInputClass}>
          <PTDatePicker
            disabled={endDisabled}
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            minDate={startDate}
            placeholderText="Select end date"
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            ref={endDatePickerRef}
            onFocus={handleEndDatePickerOpen}
          />
        </Label>
      </div>
      {fadeIt && (
        <div
          style={{
            position: 'absolute',
            inset: '0px',
            backgroundColor: '#ffffffa0',
          }}
        ></div>
      )}
    </Container>
  );
};

export default DateRangePicker;
