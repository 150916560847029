import React from 'react';
import Graph from 'components/Graph';
import Main from 'components/Main';
import NavBar from 'components/NavBar';
import DateNavigation from '../Demand/DateNavigation';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/definitions';
import { uiDateMove } from 'store/ui/action';
import GraphParams from 'pages/Demand/GraphParams';
import { durationToMomentDuration } from 'store/ui/uiutil';
import styled from 'styled-components';
import AccRevenueSticker, {
  AccRevContextProvider,
} from 'containers/AccRevenueSticker';
import RevenueExport from 'containers/RevenueExport';
const GraphTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export default ((props) => {
  const dispatch = useDispatch();
  const duration = useSelector(
    (root: ApplicationState) => root.uiState.duration
  );
  const startDate = useSelector(
    (root: ApplicationState) => root.uiState.startDate
  );
  const endDate = useSelector((root: ApplicationState) => root.uiState.endDate);

  const mdur = durationToMomentDuration(duration);

  return (
    <>
      {/* TODO: remove this AccRevContextProvider hack */}
      <AccRevContextProvider>
        <GraphTop>
          <GraphParams hidePrices={true} showRevenue={true} />
          <AccRevenueSticker />
          <RevenueExport />
        </GraphTop>
        <Graph kind="revenue" />
      </AccRevContextProvider>
      {/* <DateNavigation
        // prevLabel={moment.utc(startDate).add(-1, mdur).format('YY-M-D')}
        // nextLabel={moment.utc(startDate).add(1, mdur).format('YY-M-D')}
        prevLabel={moment.utc(startDate).add(-1, mdur).format('yyyy-MM-DD')}
        nextLabel={moment.utc(startDate).add(1, mdur).format('yyyy-MM-DD')}
        onNextClick={() => dispatch(uiDateMove(1))}
        onPreviousClick={() => dispatch(uiDateMove(-1))}
      /> */}
    </>
  );
}) as React.FC<{}>;
