import AdjustmentController, {
  BookingCapacityRequestModel,
} from 'api/adjustment';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import { GraphDataBundle } from 'components/Graph/defs';
import CloseButton from 'components/Modal/CloseButton';
import TransparentPopup from 'components/Modal/TransparentPopup';
import useModalStack from 'components/Modal/useModalStack';
import { Title } from 'components/ZoneSchema/components';
import Button, { ButtonColors } from 'components/input/Button';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import MultiSelect from 'components/input/MultiSelect';
import moment, { Moment } from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_BOOKING_CACHE } from 'store/calcCache/action';
import {
  selectUiStateEndDate,
  selectUiStateStartDate,
} from 'store/ui/selector';
import styled from 'styled-components';

const HEIGHT = 360;
const WIDTH = 500;
const MARGINX = 65;
const MARGINY = 10;
const nameClass = 'name';

const Wrapper = styled.div<{ x: number; y: number; isReverse: boolean }>`
  top: ${({ y }) => y - (HEIGHT + MARGINY)}px;
  left: ${({ x, isReverse }) =>
    !isReverse
      ? x - WIDTH / 2 < MARGINX
        ? MARGINX
        : x - WIDTH / 2
      : x - (WIDTH + MARGINX - MARGINY - (window.innerWidth - x))}px;
  position: absolute;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
`;

const CapacitySettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

interface Props {
  defaultValue: number | null;
  index: number;
  data: GraphDataBundle;
  element: SVGGElement;
  startDate: moment.Moment;
}

const WeekdayFilter = {
  '0': {
    day: 1,
    name: 'Monday',
  },
  '1': {
    day: 2,
    name: 'Tuesday',
  },
  '2': {
    day: 3,
    name: 'Wednesday',
  },
  '3': {
    day: 4,
    name: 'Thursday',
  },
  '4': {
    day: 5,
    name: 'Friday',
  },
  '5': {
    day: 6,
    name: 'Saturday',
  },
  '6': {
    day: 0,
    name: 'Sunday',
  },
};

const BookingCapacityPopup: React.FC<Props> = (props) => {
  const { element, defaultValue, startDate } = props;

  const controller = new AdjustmentController();
  const dispatch = useDispatch();
  const [bookingCapacity, setBookingCapacity] = React.useState<number | null>(
    defaultValue
  );
  const graphStartDate = useSelector(selectUiStateStartDate);
  const graphEndDate = useSelector(selectUiStateEndDate);

  const [fromDate, setFromDate] = React.useState<moment.Moment>(startDate);
  const [toDate, setToDate] = React.useState<moment.Moment>(startDate);
  const [selectedWeekdays, setSelectedWeekdays] = React.useState<string[]>(
    Object.keys(WeekdayFilter).map((x) => x)
  );
  const rect = element.getBoundingClientRect();

  const [x, setX] = React.useState<number>(rect.x);
  const [y, setY] = React.useState<number>(rect.y);

  const modalStack = useModalStack();
  const isReverse = x + (WIDTH / 2 + MARGINX) > window.innerWidth;
  return (
    <TransparentPopup>
      <Wrapper
        x={x}
        y={y}
        isReverse={isReverse}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h3 style={{ marginBottom: 10 }}>Adjust booking capacity</h3>
        <Label label="Booking capacity" className={nameClass}>
          <Input
            onKeyUp={(e) => {
              const key = e.key;

              // Check if the key is a number (0-9) or the backspace key
              if (!/^\d$/.test(key) && key !== 'Backspace') {
                e.preventDefault(); // Prevent the input
              }
            }}
            onChange={(e) => {
              var value = e.target.value;
              if (value === '') {
                setBookingCapacity(null);
                return;
              }
              setBookingCapacity(Number(value));
            }}
            value={bookingCapacity ?? ''}
          />
        </Label>
        <CapacitySettingsWrapper>
          <Label
            labelStyle={{ zIndex: 150 }}
            label="From date"
            className={nameClass}
          >
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setFromDate(moment(date));
              }}
              selected={fromDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label
            labelStyle={{ zIndex: 150 }}
            label="To date"
            className={nameClass}
          >
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setToDate(moment(date));
              }}
              selected={toDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label
            labelStyle={{ maxWidth: WIDTH / 2, minWidth: WIDTH / 2 }}
            label={'Weekdays'}
          >
            <MultiSelect
              selectAll={true}
              selectAllLabel={'All weekdays'}
              allSelected={selectedWeekdays.length === 7}
              onSpecialSelect={(sel: any) => {}}
              specialSelected={[]}
              onChange={(selected: any) => {
                setSelectedWeekdays(selected.map((f: any) => f.id));
              }}
              onChangeAll={(all: boolean) => {
                if (!!all) {
                  setSelectedWeekdays(Object.keys(WeekdayFilter).map((x) => x));
                } else {
                  setSelectedWeekdays([]);
                }
              }}
              selected={selectedWeekdays.map((f) => ({
                name: WeekdayFilter[f as keyof typeof WeekdayFilter].name,
                id: f,
                route: '',
              }))}
              available={Object.keys(WeekdayFilter).map((f) => ({
                name: WeekdayFilter[f as keyof typeof WeekdayFilter].name,
                id: f,
                route: '',
              }))}
            />
          </Label>
        </CapacitySettingsWrapper>
        <ButtonWrapper>
          <Button
            onClick={async () => {
              var weekdays = selectedWeekdays.map(
                (f) => WeekdayFilter[f as keyof typeof WeekdayFilter].day
              );
              let currentDate = fromDate.clone();

              const dateList: string[] = [];
              // Iterate through each date
              while (currentDate.isSameOrBefore(toDate)) {
                if (weekdays.includes(currentDate.weekday())) {
                  dateList.push(currentDate.format('YYYY-MM-DD'));
                }
                currentDate.add(1, 'day'); // Move to the next day
              }
              const data = {
                zoneId: props.data.zones[0].id,
                dateList: dateList,
                bookingCapacity: bookingCapacity ?? 0,
                startDate: graphStartDate,
                endDate: moment(graphEndDate)
                  .add(1, 'days')
                  .format('YYYY-MM-DD'),
              };

              var response = await controller.setBookingCapacity(data);

              dispatch(
                UPDATE_BOOKING_CACHE(
                  data.zoneId,
                  response.booking,
                  response.driveup
                )
              );
              setTimeout(() => {
                modalStack.pop();
              }, 0);
            }}
          >
            Create adjustment
          </Button>
          <Button
            color={ButtonColors.secondary}
            onClick={() => modalStack.pop()}
          >
            Close
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </TransparentPopup>
  );
};

export default BookingCapacityPopup;
