import styled from 'styled-components';

const Main = styled.div`
  margin: 0 auto;

  .schedule-header {
    margin-bottom: 10px;
  }
`;

export default Main;
