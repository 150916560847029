import { PriceSchedule, Pricing, Zone } from './reducer';
import { ZONESTORE } from './constants';

export const zoneUpdateSchedule = (
  zoneId: number,
  scheduleGid: string,
  updatedSchedule: PriceSchedule
) => ({
  type: ZONESTORE.ZONE_UPDATE_SCHEDULE,
  zoneId,
  scheduleGid,
  updatedSchedule,
});

export const zoneStoreApply = (zoneId: number, changes: Partial<Zone>) => ({
  type: ZONESTORE.ZONE_APPLY,
  zoneId,
  changes,
});

export const zoneStoreAddPrice = (pricing: Pricing) => ({
  type: ZONESTORE.ZONE_ADD_PRICE,
  pricing,
});

export const zoneStoreModPrice = (pricing: Pricing) => ({
  type: ZONESTORE.ZONE_MOD_PRICE,
  pricing,
});

export const zoneStoreRemPrice = (zoneId: number, pricingId: number) => ({
  type: ZONESTORE.ZONE_REM_PRICE,
  zoneId,
  pricingId,
});

export const changeZones = (zones: Zone[]) => ({
  type: ZONESTORE.ZONE_UPDATE,
  zones,
});

export const updateSchedule = (schedule: PriceSchedule[]) => ({
  type: ZONESTORE.ZONE_UPDATE_SEASONS,
  schedule,
});
