import * as Actions from './action';
import { ActionReturns } from '../../util/tshelpers';

import moment from 'moment';
import { UISTORE } from './constants';
import { durationToMomentDuration } from './uiutil';
import { getSameDayLastYear } from 'util/date';
export interface UIState {
  previousZoneIDsState: number[];
  activeZoneIDs: number[];
  specialSelect: string[];
  activeFacility: string;

  segments: 'all' | 'driveup' | 'booking';

  uiDataUpdateTS: number;
  uiCDataUpdateTS: number;
  allSelected: boolean;

  selectedDate: string; // this is the date the user has picked via fwd/backward

  // Derived fields
  startDate: string; // this one should always be calculated from selectedDate and duration
  endDate: string; // This one should always be calculated from selectedDate and duration
  minStartDate: string; //To keep the user from looking to far into the past
  maxEndDate: string; //To keep the user from looking to far into the future
  enableCompare: boolean;
  compareStartDate?: string;
  compareEndDate?: string;

  duration: 'day' | 'week' | 'month' | 'weeksInYear' | 'monthsInYear';
  //ptype: 'hour' | 'day' | 'week' | 'hour_avg';
  priceTypeViewID: string;
  revview: 'regular' | 'changes' | 'lastYear' | 'lastPeriod' | 'currProp';
  failedToFetch: boolean;
}

const now = moment();

const initialDuration = 'month';
var initialStart = moment();
var initialEndDate = moment(initialStart).add(1, 'month');
var interval = initialEndDate.diff(initialStart, 'days');

var weekday = initialStart.isoWeekday();
var initialCompareDate = getSameDayLastYear(initialStart);
var initialCompareEndDate = moment(initialCompareDate).add(interval, 'days');
var endOfNextYear = now.clone().startOf('year').add(2, 'years').add(-1, 'day');
var startOfLastYear = now
  .clone()
  .startOf('year')
  .add(-1, 'year')
  .add(-1, 'day');

export const initialUIState: UIState = {
  segments: 'driveup', //'all',
  //activeZoneID: null,
  activeFacility: '',
  previousZoneIDsState: [],
  activeZoneIDs: [],
  specialSelect: [],
  allSelected: false,
  selectedDate: now.clone().utc().format('YYYY-MM-DD'),
  startDate: initialStart.format('YYYY-MM-DD'),
  endDate: initialEndDate.format('YYYY-MM-DD'),
  minStartDate: startOfLastYear.format('YYYY-MM-DD'),
  maxEndDate: endOfNextYear.format('YYYY-MM-DD'),
  enableCompare: false,
  compareStartDate: initialCompareDate.format('YYYY-MM-DD'),
  compareEndDate: initialCompareEndDate.format('YYYY-MM-DD'),
  duration: initialDuration,
  uiDataUpdateTS: 0,
  uiCDataUpdateTS: 0,
  //ptype: 'hour_avg',
  priceTypeViewID: '',
  revview: 'regular',
  failedToFetch: false,
};

type Actions = ActionReturns<typeof Actions>;

export function reducer(state = initialUIState, action: Actions): UIState {
  switch (action.type) {
    case UISTORE.CHANGE_DURATION: {
      // Changing duration we also want to adjust the date-range and other parameters to fit the user.
      // now put together a new state from all changes.
      return {
        ...state,
        duration: action.duration,
      };
    }
    case UISTORE.SET_SPECIAL_SELECT: {
      return {
        ...state,
        specialSelect: action.specialSelect,
      };
    }
    case UISTORE.CHANGE_START_DATE: {
      var mmtStartDate = moment(action.startDate);
      var mmtEndDate = moment(state.endDate);
      var dateInterval = Math.abs(mmtStartDate.diff(mmtEndDate, 'days'));

      var compareEndDate = moment(state.compareStartDate)
        .add(dateInterval, 'days')
        .format('YYYY-MM-DD');

      return {
        ...state,
        startDate: action.startDate,
        selectedDate: action.startDate,
        compareEndDate: compareEndDate,
      };
    }
    case UISTORE.CHANGE_END_DATE: {
      var mmtStartDate = moment(state.startDate);
      var mmtEndDate = moment(action.endDate);
      var dateInterval = Math.abs(mmtStartDate.diff(mmtEndDate, 'days'));

      console.log(mmtStartDate, mmtEndDate, dateInterval);
      var compareEndDate = moment(state.compareStartDate)
        .add(dateInterval, 'days')
        .format('YYYY-MM-DD');

      return {
        ...state,
        compareEndDate: compareEndDate,
        endDate: action.endDate,
      };
    }
    case UISTORE.DATEMOVE: {
      console.log('Going from date:' + state.startDate);
      const mdur = durationToMomentDuration(state.duration);
      const startDate = moment(state.startDate)
        .add(action.plusMinus, mdur)
        .format('YYYY-MM-DD');
      console.log(startDate);
      // const startDate = _temp.toISOString();
      // const startDate = _temp.add(action.plusMinus, mdur).toISOString(); //Kolla med Jonas för bästa resultat
      console.log('Going to date:' + startDate);
      const endDate = moment(startDate).add(1, mdur).format('YYYY-MM-DD');
      return { ...state, startDate, endDate, selectedDate: startDate };
    }
    case UISTORE.FAILED_TO_FETCH: {
      return { ...state, failedToFetch: true };
    }
    case UISTORE.RESET_FETCH: {
      return { ...state, failedToFetch: false };
    }
    case UISTORE.CHANGE_SEGMENT: {
      return { ...state, segments: action.segment };
    }
    case UISTORE.CHANGE_ACTIVE_ZONE: {
      console.log('CHANGE', action.zoneIds);
      var previous = state.activeZoneIDs;
      return {
        ...state,
        previousZoneIDsState: previous,
        activeZoneIDs: action.zoneIds,
        allSelected: false,
      };
    }
    case UISTORE.SET_ALL_SELECTED: {
      console.log('ALL', action.allSelected);
      return { ...state, allSelected: action.allSelected };
    }
    case UISTORE.SET_ENABLE_COMPARE: {
      return { ...state, enableCompare: action.enableCompare };
    }
    case UISTORE.SET_COMPARE_DATE: {
      var date = action.startDate;
      var mmtStartDate = moment(state.startDate);
      var mmtEndDate = moment(state.endDate);

      var dateInterval = Math.abs(mmtStartDate.diff(mmtEndDate, 'days'));

      var compareEndDate = moment(date)
        .add(dateInterval, 'days')
        .format('YYYY-MM-DD');
      console.log(date, compareEndDate);

      return {
        ...state,
        compareStartDate: date,
        compareEndDate: compareEndDate,
      };
    }
    case UISTORE.SET_COMPARE_END_DATE: {
      var date = action.endDate;
      var mmtStartDate = moment(state.startDate);
      var mmtEndDate = moment(state.endDate);

      var dateInterval = Math.abs(mmtStartDate.diff(mmtEndDate, 'days'));

      var compareEndDate = moment(date)
        .add(dateInterval, 'days')
        .format('YYYY-MM-DD');

      return {
        ...state,
        compareEndDate: compareEndDate,
      };
    }
    case UISTORE.CLEAR_COMPARE_DATE: {
      return {
        ...state,
        compareStartDate: undefined,
        compareEndDate: undefined,
      };
    }
    case UISTORE.CHANGE_ACTIVE_FACILITY: {
      return {
        ...state,
        allSelected: false,
        specialSelect: [],
        activeFacility: action.facilityId,
        segments: action.viewSegment as 'all' | 'booking' | 'driveup',
      };
    }
    case UISTORE.CHANGE_PVIEW_ID: {
      return { ...state, priceTypeViewID: action.id };
    }
    case UISTORE.CHANGE_REVVIEW:
      return { ...state, revview: action.rtype };
    case UISTORE.UPDATE_TS:
      return { ...state, uiDataUpdateTS: action.ts };
    case UISTORE.UPDATE_COMP_TS:
      return { ...state, uiCDataUpdateTS: action.ts };
  }
  return state;
}
