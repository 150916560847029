import { GeneralStrategy } from 'constants/AIDefaults';
import { PriceV3Api } from 'constants/api-fetch';
import { PricingGroup, PricingRange } from 'store/zone/reducer';

class AIController {
  /**
   *
   */
  apiHandler: PriceV3Api | null = null;

  constructor() {
    this.apiHandler = new PriceV3Api();
  }

  fetchAskAIPrice = async (data: AskAIRequestrModel, signal: AbortSignal) => {
    var result = await this.apiHandler!.post(
      `ai/askAi`,
      JSON.stringify(data),
      {},
      signal
    );
    return result as AskAIResponseModel;
  };
}

export default AIController;

export class AskAIRequestrModel {
  zoneId: number = 0;
  aggression: GeneralStrategy = GeneralStrategy.balanced;
  startDate: string = '';
  endDate: string = '';
}

export class AskAIResponseModel {
  priceRanges: PricingRange[] = [];
  priceRangeGroups: PricingGroup[] = [];
}
