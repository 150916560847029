import moment from 'moment';
import { Price_Resp } from '../../store/calcCache/definitions';
import {
  GraphData,
  GraphDataBundle,
  GraphPoint,
  SegmentGraphStat,
} from './defs';
import { Event } from 'store/event/definitions';
import { CompareDataSet } from 'store/compareCache/definitions';

const possibleSegments = ['driveup', 'booking', 'total'] as const;

const dummyZeroCompareEntry = { revenue: 0 };

let emptyNum = [] as number[];

const dummyStartInFuture = moment.utc(
  `${new Date().getFullYear() + 100}-01-01`
);

const getStartTimeFor = (zones: Price_Resp['zones'], compare: boolean) =>
  zones.reduce((accZones: moment.Moment, cur) => {
    let seriesStartTime = cur.series.reduce((accSeries: moment.Moment, cur) => {
      if ((cur.clientIsForCompare ?? false) !== compare) return accSeries;
      let curSeriesTime = moment.utc(cur.startTime);

      return curSeriesTime.isSameOrBefore(accSeries)
        ? curSeriesTime
        : accSeries;
    }, dummyStartInFuture) as moment.Moment;

    return seriesStartTime.isSameOrBefore(accZones)
      ? seriesStartTime
      : accZones;
  }, dummyStartInFuture);

export const dataZip = (
  zones: Price_Resp['zones'],
  segment: 'all' | 'driveup' | 'booking',
  //priceType: 'hour' | 'day' | 'week' | 'hour_avg',
  selectedPriceViewTypeId: string,
  accumulatedHourCount: number,
  firstStep: number,
  zoneIds: number[],
  comparedTo?: CompareDataSet | null
) => {
  //console.log('SelectedPriceViewTID:', selectedPriceViewTypeId);
  console.log('Zip begin for seg:', segment);

  const usedSegments = possibleSegments.filter(
    (ps) => segment === 'all' || segment === ps
  );

  // The input zones are dynamic zones separated by segment (ie more like a list of zone-segment informations)
  // Filter out non-selected zones and segments. (unless we have all, either byh segment=="all" or zoneId===undefined)
  const actZones = (zones || [])
    .filter((zn) => {
      if (zoneIds.length > 0) {
        if (!zoneIds.find((z) => z === Number(zn.zoneId))) {
          return false;
        }
      }
      if (segment === 'all') return true;
      return segment === zn.kind;
    })
    .map((zn) => ({ ...zn, segmentIndex: usedSegments.indexOf(zn.kind) }));

  // create zone accumulators
  const zoneAccs = actZones.map((zn) => ({
    demAcc: 0,
    odemAcc: 0,
    //    scale: zn.year.estiatedCustomerGrowthPercentage / 100 + 1,
  }));

  const zoneComparesData = !comparedTo
    ? []
    : actZones.map((az) => comparedTo?.dataByZone[az.zoneId]);

  //const realZoneCount = new Set(zones.map((z) => z.zoneid)).size;
  const realZoneCount = 1;

  // Take the max count from all zones (some zones might have less data for various reasons)
  const hourCount = actZones.reduce(
    (acc, cur) =>
      Math.max(
        acc,
        cur.series.reduce(
          (acc, series) =>
            Math.max(
              acc,
              (series.values.length * series.stepInSeconds) / (60 * 60)
            ),
          0
        )
      ),
    0
  );
  // const count = Math.max(
  //   bookings
  //     .map((booking) => booking.hours.length)
  //     .reduce((a, b) => Math.max(a, b), 0),
  //   driveups.map((du) => du.hours.length).reduce((a, b) => Math.max(a, b), 0)
  // );

  const soAccRev = usedSegments.map((s) => 0);
  const snAccRev = usedSegments.map((s) => 0);
  const sdAccRev = usedSegments.map((s) => 0);
  // let boAccRev = 0;
  // let doAccRev = 0;
  let toAccRev = 0;
  // let bnAccRev = 0;
  // let dnAccRev = 0;
  let tnAccRev = 0;
  let tdAccRev = 0;

  let comAccRev = 0;

  type ZoneInfo = Price_Resp['zones'][number];
  type HourEntry = ZoneInfo['hours'][number];

  let demandAcc = 0;
  let oldDemandAcc = 0;

  const data = [] as GraphPoint[];

  let hasBooking = false;
  let hasDriveup = false;

  let span = firstStep;

  let compSkip = 0;
  if (comparedTo && actZones.length > 0 && actZones[0].hours.length > 0) {
    compSkip = moment
      .utc(actZones[0].hours[0].time)
      .diff(moment.utc(comparedTo.viewStartDate), 'hours');
    console.log(
      `Between cache:${comparedTo.viewStartDate} view:${actZones[0].hours[0].time} we need to skip ${compSkip} hours`
    );
  }

  // Make a unique list of series [outID] , zones can have different order and occurences
  //  of series so we build a mapping of existing ones and only accumlate those that exist per-zone to the full
  const seriesIDs = [
    ...actZones
      .flatMap((az) =>
        az.series
          .filter((s) => s.stepInSeconds / 60 / 60 <= accumulatedHourCount)
          .map((s) => s.outID)
      )
      // this last part makes the list unique
      .reduce((a, c) => (a.add(c), a), new Set<string>()),
  ];

  const sourceSeries = seriesIDs.map((sid) =>
    actZones.flatMap((az) => az.series.filter((ser) => ser.outID === sid))
  );

  const npriceIndex = seriesIDs.findIndex(
    (sid) => sid === selectedPriceViewTypeId
  );
  const revenueIndex = seriesIDs.findIndex((sid) => sid === 'revenue');
  const demandIndex = seriesIDs.findIndex((sid) => sid === 'arrivals');
  const hdemandIndex = seriesIDs.findIndex((sid) => sid === 'actualarrivals');

  var duRevIdx = seriesIDs.findIndex((s) => s === 'revenue_driveup');
  var boRevIdex = seriesIDs.findIndex((s) => s === 'revenue_booking');
  var cduRevIdx = seriesIDs.findIndex(
    (s) => s === '__compare__revenue_driveup'
  );
  var cboRevIdex = seriesIDs.findIndex(
    (s) => s === '__compare__revenue_booking'
  );

  let prevOn: GraphPoint | null = null;

  let startOfCurrentDay = moment(new Date()).startOf('day');
  let startTime = getStartTimeFor(actZones, false);
  let compStartTime = getStartTimeFor(actZones, true);

  let compHourOffset = startTime.diff(compStartTime, 'hours');

  let seriesHourOffsets = actZones.map((az, azIdx) => {
    return az.series.map((ser, serIdx) => {
      return {
        offset: moment.utc(ser.startTime).diff(startTime, 'hours', true),
      };
    });
  });

  // BIG NESTED-LOOP AHEAD, sane but needs an intro:
  // - The purpose is to accumulate values for each graph from a series of datapoints that might NOT be in the same time resolution.
  // The loop iterations are as follows:
  // i : main iterations counted in hours done for the number of output ticks, stepping over hours if a tick is more than one hour.
  // j : steps through each hour between the main "i" ticks.
  // k : for each zone (ie we sum from all input zones for each tick)
  // l : for each series, looking up inside the series by offsetting from the main-i/j-loop hour
  // m : auxillary

  // go through data in acc increments
  for (let i = 0; i < hourCount; i += span) {
    const timeValue = startTime.clone().add(i, 'hours');
    //const timeValue = actZones[0].hours[i].time; // (bookings?.hours[i].time || driveups?.hours[i].time)!;
    const outerDate = timeValue.toDate();
    const currentEntryStepSize = span;

    // Only use first step on the first iteration, used to handle not compmlete first week of the year
    if (i >= firstStep) {
      span = accumulatedHourCount;
    }

    const mk0Revenue = () => ({ current: 0, accumulated: 0 });

    const on: GraphPoint = {
      tick: (span >= 24 ? timeValue.clone().startOf('day') : timeValue) //moment(span >= 24 ? timeValue.substring(0, 10) : timeValue)
        //.utc()
        .toISOString(),
      odate: timeValue.toString(),
      date:
        span >= 24
          ? timeValue.toISOString().substring(0, 10)
          : timeValue.toISOString(), // timeValue.substring(0, 10) : timeValue,

      demand: 0,

      segments: usedSegments.map((s) => ({
        demand: 0,
        newRevenue: mk0Revenue(),
        oldRevenue: mk0Revenue(),
        defRevenue: mk0Revenue(),
      })),

      seriesID: seriesIDs,
      series: seriesIDs.map((s) => 0),
      seriesAcc: seriesIDs.map((s) => 0),

      // // demand

      // old revenue
      toRevenue: 0,
      toAccRev: 0,
      // new revenue
      tnRevenue: 0,
      tnAccRev: 0,
      // default revenue
      tdRevenue: 0,
      tdAccRev: 0,

      // compare revenue
      comRevenue: 0,
      comAccRev: 0,

      price: 0,
      isDemandPrediction: false,

      dateMonth: outerDate.getMonth(),
      dateDay: outerDate.getDay(),
    };

    let spanCount = Math.min(currentEntryStepSize, hourCount - i); // we need the span-count to adjust for end-of-year weeks that don't span an entire week.
    // go through all hours within this span
    for (let j = 0; j < spanCount; j++) {
      // and then for each zone.
      for (let k = 0; k < actZones.length; k++) {
        const zone = actZones[k];

        const zoneAcc = zoneAccs[k];

        // don't try to accumulate out-of-span hours.
        if (i + j >= hourCount) continue;
        // var seriesFinalAcc: {
        //   electric: {
        //     [key: string]: number;
        //   };
        // } = {
        //   electric: {},
        // };
        for (let l = 0; l < seriesIDs.length; l++) {
          let seriesData: (typeof zone.series)[0] = null as any;
          let offsetInfo: (typeof seriesHourOffsets)[0][0] = null as any;
          // Find if this datapoint has something matching the "global" seriesID (we could for example have non-electric and electric zones.. only electric zones would have electric surcharges)
          for (let m = 0; m < zone.series.length; m++) {
            if (zone.series[m].outID !== seriesIDs[l]) continue;
            seriesData = zone.series[m];
            offsetInfo = seriesHourOffsets[k][m];
          }
          if (!seriesData) {
            continue;
          }

          // Only sample as many samples as we have available.
          let seriesStepInHours = seriesData.stepInSeconds / 60 / 60;
          if (0 !== (i + j) % seriesStepInHours) {
            continue;
          }

          // The entry in the series we will currently index depends on the step-size
          let seriesIdx =
            (i +
              j -
              offsetInfo.offset - // offset by our starting position
              (seriesData.clientIsForCompare ?? false ? compHourOffset : 0)) / // adjust by the start difference if the series is a compare series
            seriesStepInHours;

          if (seriesData.values[seriesIdx] === null) {
            on.series[l] = null;
            continue;
          }

          if (seriesIdx < 0 || seriesIdx >= seriesData.values.length) continue; // don't read out-of-bounds
          switch (seriesData.compacting) {
            case 'ACCUMULATE':
              {
                on.series[l]! += seriesData.values[seriesIdx];
                on.seriesAcc[l] = (prevOn?.seriesAcc[l] ?? 0) + on.series[l]!;
                // seriesFinalAcc[
                //   seriesData.category as keyof typeof seriesFinalAcc
                // ][seriesData.valueKind] = on.seriesAcc[l];
              }
              break;
            case 'AVERAGE':
              {
                on.series[l]! +=
                  seriesData.values[seriesIdx] /
                  (spanCount / seriesStepInHours);
                on.seriesAcc[l] = (prevOn?.seriesAcc[l] ?? 0) + on.series[l]!;
              }
              break;
            case 'SAMPLEHEAD':
              {
                on.series[l] = seriesData.values[seriesIdx];
                on.seriesAcc[l] = on.series[l]!;
              }
              break;
            default:
              console.log(seriesData.compacting);
              break;
          }
        }

        // // if any hour within the output span is an hour prediction we mark all of them
        const curIsPred = timeValue.isSameOrAfter(startOfCurrentDay);
        on.isDemandPrediction = on.isDemandPrediction || curIsPred; // || hourEntry.isDemandPrediction;

        on.price = on.series[npriceIndex]!;
        if (!!zone.series[0].clientIsForCompare) {
          on.comAccRev = Number(
            (on.seriesAcc[cduRevIdx] + on.seriesAcc[cboRevIdex]).toFixed(0)
          );
        } else {
          on.tdAccRev = Number(
            (on.seriesAcc[duRevIdx] + on.seriesAcc[boRevIdex]).toFixed(0)
          );
        }

        if (zone.kind === 'booking') {
          hasBooking = true;
        } else if (zone.kind === 'driveup') {
          hasDriveup = true;
        }

        // const arrivalCount = demandIndex != -1 ? on.series[demandIndex] : 0;
        // on.segments[zone.segmentIndex].demand += arrivalCount; // hourEntry.parkings;
        // on.demand += arrivalCount;

        // demandAcc += arrivalCount;

        // zoneAcc.demAcc += arrivalCount;

        // const harrivalCount = hdemandIndex != -1 ? on.series[hdemandIndex] : 0;
        // oldDemandAcc += harrivalCount;
        // zoneAcc.odemAcc += harrivalCount;

        // on.segments[zone.segmentIndex].newRevenue.current +=
        //   hourEntry.newRevenue;
        // on.tnRevenue += hourEntry.newRevenue;
        // snAccRev[zone.segmentIndex] += hourEntry.newRevenue;
        // tnAccRev += hourEntry.newRevenue;
        // on.segments[zone.segmentIndex].newRevenue.accumulated = Math.floor(
        //   snAccRev[zone.segmentIndex]
        // );
        // on.tnAccRev = Math.floor(tnAccRev);

        // on.segments[zone.segmentIndex].oldRevenue.current +=
        //   hourEntry.oldRevenue;
        // on.toRevenue += hourEntry.oldRevenue;
        // soAccRev[zone.segmentIndex] += hourEntry.oldRevenue;
        // toAccRev += hourEntry.oldRevenue;
        // on.segments[zone.segmentIndex].oldRevenue.accumulated = Math.floor(
        //   soAccRev[zone.segmentIndex]
        // );
        // on.toAccRev = Math.floor(toAccRev);

        // const defRevenue = hourEntry.isDemandPrediction
        //   ? hourEntry.newRevenue
        //   : hourEntry.oldRevenue;

        // on.segments[zone.segmentIndex].defRevenue.current += defRevenue;
        // on.tdRevenue += defRevenue;
        // sdAccRev[zone.segmentIndex] += defRevenue;
        // tdAccRev += defRevenue;
        // on.segments[zone.segmentIndex].defRevenue.accumulated = Math.floor(
        //   sdAccRev[zone.segmentIndex]
        // );
        // //+ Math.floor(seriesEvTotalRevenue);
        // on.tdAccRev = Math.floor(tdAccRev); // + Math.floor(seriesEvTotalRevenue);

        // on.comRevenue += compEntry.revenue;
        // comAccRev += compEntry.revenue;
        // on.comAccRev = Math.floor(comAccRev);
      }
    }
    on.demand = Math.floor(on.demand);
    prevOn = on;
    data.push(on);
  }

  let oldDiv = zoneAccs.reduce((prev, za) => prev + za.odemAcc, 0);
  if (oldDiv < 0.0000000001) oldDiv = 1;
  const custAccDiff =
    zoneAccs.reduce((prev, za) => prev + za.demAcc, 0) / oldDiv;

  const ov = {
    data,
    bookings: hasBooking,
    driveups: hasDriveup,
    realZoneCount,
    demandAcc,
    oldDemandAcc,
    custAccDiff,
    segments: usedSegments,
    seriesIDs: seriesIDs,
  } as GraphData;

  console.log('Zip result:', ov);

  return ov;
};

export const findGraphLevel = (v: number) => {
  for (let i = 0; i < 2000000; i++) {
    const step = 3;
    const maxDig = [1, 2, 5][i % step];
    const maxExp = Math.pow(10, Math.floor(i / step));
    const tMax = maxExp * maxDig;
    if (v < tMax) return tMax;
  }
  return 1;
};

// this takes the highest digit, increases by one
export const roundUpMax = (v: number) => {
  // start by making a ceiled str of the number
  let str = '' + Math.ceil(v);
  // if it starts with a 9 (like in 90 smth, or 900 smth,etc, we append a 0 so that we've bumped the number of digits)
  if (str.startsWith('9')) str = '0' + str;
  // now split and rebuild the string so that everything but the first digit is cleared and the first one bumped.
  return parseInt(
    str
      .split('')
      .map((v, i) => {
        return i === 0 ? parseInt(v) + 1 + '' : '0';
      })
      .join('')
  );
};

export const firstToUpper = (v: string) => {
  if (v.length < 1) return v;
  return v.substring(0, 1).toUpperCase() + v.substring(1);
};

export function formatNumberWithSpaces(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const numToSpacedString = (v: number) => {
  let out = '';
  if (v >= 1000000) {
    return Math.floor(v / 100000) / 10 + ' M';
  }
  if (v >= 1000) {
    return Math.floor(v / 100) / 10 + ' K';
  }
  return Math.floor(v) + out;
};

export const isSpecialDay = (date: Date, data: GraphDataBundle) => {
  if (!data.events) return { eventExists: false, event: null };
  //console.log('Facility-check');
  var event = data.events.reduce((acc, ev) => {
    let sd = moment.utc(ev.startDate).unix(); //  new Date(ev.startDate);
    let ed = moment.utc(ev.endDate).unix(); //new Date(ev.endDate);
    let odate = moment.utc(date).unix(); //new Date(date);
    // zoneId , routeId in ev
    // if data.zone , then data.zone.id , data.facility needs id
    if (sd <= odate && odate <= ed) {
      if (
        // for viewing a zone there are 2 disrtinct cases
        (data.zones.length > 0 &&
          // Either it's a facility-wide event, in that case it's enough to be in the correct one.
          ((ev.routeData && ev.routeID === data.facility.id) ||
            // .. or if it's a event for specific zones then that zone must be included!
            (ev.zoneData &&
              ev.zoneData.some(
                (zd) => !!data.zones.find((z) => z.id === zd.zoneID)
              )))) ||
        // If we're viewing all zones and the facility ID matches then we're showing this event
        (data.zones.length === 0 && ev.routeID === data.facility.id)
      ) {
        acc.push(ev);
      }
    }
    return acc;
  }, [] as Event[]);
  if (event.length > 0) return { eventExists: true, event };
  return { eventExists: false, event: null };
};
