import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
  padding-bottom: 20px;
`;

interface Props {}

const SubNavBar: React.FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default SubNavBar;
