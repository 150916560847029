import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { FaAngleRight, FaArrowRight } from 'react-icons/fa';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface Props extends NavLinkProps {
  active: boolean;
  secondary?: boolean;
}

const Icon = styled.div`
  margin-bottom: 3px;
`;
const Text = styled.div`
  border-bottom: 1px solid transparent;
`;

const Link = styled(NavLink)<Props>`
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
  transition: scale 0.8 ease-in-out;
  :hover {
    text-decoration: none;
    scale: 1.02;
    ${Text} {
      color: ${(props) => props.theme.palette.primary.bg};
      border-bottom: 1px solid ${(props) => props.theme.palette.secondary.bg};
    }
  }
  :active {
    text-decoration: none;
    ${Text} {
      color: ${(props) => props.theme.palette.primary.bg};
      border-bottom: 1px solid ${(props) => props.theme.palette.secondary.bg};
    }
  }
  ${({ active, theme }) =>
    active &&
    css`
      text-decoration: none;
      ${Text} {
        color: ${theme.palette.primary.bg};
        border-bottom: 1px solid ${theme.palette.secondary.bg};
      }
    `}
`;

const LinkComponent: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Link {...props}>
      <Text>{children}</Text>
      {props.secondary != true && (
        <Icon>
          <AiOutlineRight />
        </Icon>
      )}
    </Link>
  );
};

export default LinkComponent;
