import Main from 'components/Main';
import NavBar from 'components/NavBar';
import Routes from 'constants/Route';
import Revenue from 'pages/Revenue';
import React from 'react';
import { Switch, Route } from 'react-router';
import styled from 'styled-components';

//Generate a functional component that returns a Wrapper with a NavBar as first element and subroutes to Revenue and Demand pages
// Path: src\pages\Facility\index.tsx
// Compare this snippet from src\pages\Facility\index.tsx:
//

const Analysis: React.FC = () => {
  return (
    <Main>
      <NavBar />
    </Main>
  );
};

export default Analysis;
