import Main from 'components/Main';
import NavBar from 'components/NavBar';
import Routes from 'constants/Route';
import Demand from 'pages/Demand';
import Revenue from 'pages/Revenue';
import React from 'react';
import { Switch, Route } from 'react-router';
import styled from 'styled-components';
import ForecastBar from './forecastBar';
import BookingPattern from 'pages/BookingPattern';
import PriceComparison from 'pages/PriceComparison';
import NewBookings from 'pages/NewBookings';
import Dashboard from 'pages/Dashboard';
import Performance from 'pages/Performance';
import Occupancy from 'pages/Occupancy';

//Generate a functional component that returns a Wrapper with a NavBar as first element and subroutes to Revenue and Demand pages
// Path: src\pages\Facility\index.tsx
// Compare this snippet from src\pages\Facility\index.tsx:
//

const Content = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
`;

const ChildContent = styled.div``;

const Forecast: React.FC = () => {
  return (
    <Main>
      <NavBar />
      <Content>
        <ForecastBar />
        <ChildContent>
          <Switch>
            <Route path={Routes.FacilityForecastRevenue} component={Revenue} />
            <Route path={Routes.FacilityForecastDemand} component={Demand} />
            <Route
              path={Routes.FacilityForecastNewBookings}
              component={NewBookings}
            />
            <Route
              path={Routes.FacilityForecastBookingPattern}
              component={BookingPattern}
            />
            <Route
              path={Routes.FacilityForecastPriceComparison}
              component={PriceComparison}
            />
            <Route path={Routes.FacilityOccupancy} component={Occupancy} />
            <Route path={Routes.FacilityDashboard} component={Dashboard} />
            <Route path={Routes.FacilityPerformance} component={Performance} />
          </Switch>
        </ChildContent>
      </Content>
    </Main>
  );
};

export default Forecast;
