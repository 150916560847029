import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { addCharToNum } from 'util/range';
import ForecastProjection from 'components/ForecastProjection';

// TODO: Quick Hack, move out graph data instead of using this

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  line-height: 12px;
  font-size: 16px;
  font-weight: 700;
  min-width: 80px;
`;

const Num = styled.span`
  line-height: 25px;
  margin-top: 10px;
  font-size: 2em;
  font-weight: 300;
`;

function shorterNr(nr: number) {
  // round to display 3 digits in total
  // const r = (n: number) => {
  //   if (n >= 100) {
  //     return Math.round(n);
  //   } else if (n >= 10) {
  //     return Math.round(n * 10) / 10;
  //   }
  //   return Math.round(n * 100) / 100;
  // };

  // if (revenue >= 1000000000000) {
  //   return `${r(revenue / 1000000000000)}T`;
  // } else if (revenue >= 1000000000) {
  //   return `${r(revenue / 1000000000)}B`;
  // } else if (revenue >= 1000000) {
  //   return `${r(revenue / 1000000)}M`;
  // } else if (revenue >= 1000) {
  //   return `${r(revenue / 1000)}K`;
  // }
  return addCharToNum(`${nr}`, ',');
}

interface Props {
  value: number;
  diffValue?: number;
  title: string;
  showDiff?: boolean;
  unit?: string;
}

const Sticker: FC<Props> = ({
  value,
  diffValue,
  title,
  showDiff = false,
  unit = '',
}) => {
  return (
    <Wrapper>
      <span style={{ minWidth: '125px' }}>{title}</span>{' '}
      <Num>
        {shorterNr(value)}
        {unit}
      </Num>
      {diffValue && (
        <ForecastProjection
          unit={unit}
          diffValue={!showDiff ? undefined : value - diffValue}
          value={Math.round(100 * (value / diffValue - 1))}
        />
      )}
    </Wrapper>
  );
};

export default Sticker;
