import Main from 'components/Main';
import React, { useEffect, useState } from 'react';
import AdjustmentController, {
  PivotOverrideRequestModel,
  ProductSpecifierModel,
} from 'api/adjustment';
import { CellDataModel, TableData, formatForTable } from './utils';
import { useSelector } from 'react-redux';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import moment, { Moment } from 'moment';
import { selectCurrentZones } from 'store/zone/selector';

export type TableDataContextType = {
  tableData: TableData;
  setTableData: React.Dispatch<React.SetStateAction<TableData>>;
  makeGetPivotCall: (selectedProduct: string) => void;
  exportDataCall: (selectedProduct: string, callback: () => void) => void;
  generateDataCall: (selectedProduct: string, callback: () => void) => void;
};

type ProductOverrides = {
  [key: string]: CellDataModel[];
};

export type OverrideContextType = {
  overrides: ProductOverrides;
  adjustments: ProductOverrides;
  setOverrides: React.Dispatch<React.SetStateAction<ProductOverrides>>;
  setAdjustments: React.Dispatch<React.SetStateAction<ProductOverrides>>;
};

export type ProductsContextType = {
  availableProducts: ProductSpecifierModel[];
  zoneProducts: { [key: number]: string[] };
  selectedProduct: string;
  setSelectedProduct: React.Dispatch<React.SetStateAction<string>>;
  productView: string;
  setProductView: React.Dispatch<React.SetStateAction<string>>;
  los: number;
  setLos: (los: number) => void;
  nrOfDays: number;
  setNrOfDays: (nrOfDays: number) => void;
};

export const TableDataContext = React.createContext<TableDataContextType>(
  null!
);
export const OverrideContext = React.createContext<OverrideContextType>(null!);
export const ProductsContext = React.createContext<ProductsContextType>(null!);

export const ProductView = {
  difference: 'Difference',
  today: 'Today',
  yesterday: 'Yesterday',
  incrementToday: 'Increment Today',
  incrementYesterday: 'Increment Yesterday',
};

const TableDataProvider: React.FC = ({ children }) => {
  const routeInfo = useSelector(selectHistoryFacilityMatch);
  const [nrOfDays, setNrOfDays] = useState<number>(30);
  const [los, setLos] = useState<number>(30);
  const [date, setDate] = useState<moment.Moment>(moment());
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [productView, setProductView] = useState<string>(
    Object.keys(ProductView)[0]
  );
  const activeZones = useSelector(selectCurrentZones);
  const [availableProducts, setAvailableProducts] =
    useState<ProductSpecifierModel[]>();
  const [zoneProducts, setZoneProducts] = useState<{
    [key: number]: string[];
  }>();
  const [tableData, setTableData] = useState<TableData>(null!);
  const [overrides, setOverrides] = useState<ProductOverrides>(
    selectedProduct ? { [selectedProduct]: [] } : {}
  );
  const [adjustments, setAdjustments] = useState<ProductOverrides>(
    selectedProduct ? { [selectedProduct]: [] } : {}
  );

  const ac = new AdjustmentController();

  const getProductsCall = async () => {
    const facilityId = routeInfo?.params.facilityId;
    const prods = await ac.getAvailableProducts({
      facilityId: facilityId!,
    });
    setAvailableProducts(
      prods.products.sort((a, b) => {
        if (a.friendlyName.endsWith('WKND') && !b.friendlyName.endsWith('WKND'))
          return -1;
        if (!a.friendlyName.endsWith('WKND') && b.friendlyName.endsWith('WKND'))
          return 1;
        return 0;
      })
    );
    setZoneProducts(prods.zoneProducts);
  };

  useEffect(() => {
    setTableData(null!);
    setAvailableProducts(undefined);
    setZoneProducts(undefined);
    setSelectedProduct('');
    setTimeout(() => {
      getProductsCall();
    }, 0);
  }, [routeInfo?.params.facilityId]);

  // useEffect(() => {
  //   if (los !== 0 && nrOfDays !== 0 && selectedProduct !== '') {
  //     makeGetPivotCall(selectedProduct);
  //   }
  // }, [los, nrOfDays]);

  const generateDataCall = async (
    selectedProduct: string,
    callback: () => void
  ) => {
    try {
      console.log(adjustments[selectedProduct]); // TODO: Export all or only selected product?

      const facilityId = routeInfo?.params.facilityId;
      const data = await ac.generatePivotData({
        dateOverridePoints: (!adjustments[selectedProduct]
          ? []
          : adjustments[selectedProduct]
        ).map((overrides) => {
          return {
            CompareDateId: `${overrides.date}:${overrides.day}`,
            Adjustment: overrides.adjustment,
            OverrideValue: overrides.today,
          };
        }),
        productId: selectedProduct,
        facilityId: facilityId,
        startDate: date.format('YYYY-MM-DD'),
        endDate: moment(date).add(nrOfDays, 'days').format('YYYY-MM-DD'),
        LOS: los,
      } as PivotOverrideRequestModel);
      const res = data;
      callback();
      console.log('Data Export Response: ', res);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const exportDataCall = async (
    selectedProduct: string,
    callback: () => void
  ) => {
    try {
      console.log(adjustments[selectedProduct]); // TODO: Export all or only selected product?

      const facilityId = routeInfo?.params.facilityId;
      const data = await ac.overridePivotData({
        dateOverridePoints: (!adjustments[selectedProduct]
          ? []
          : adjustments[selectedProduct]
        ).map((overrides) => {
          return {
            CompareDateId: `${overrides.date}:${overrides.day}`,
            Adjustment: overrides.adjustment,
            OverrideValue: overrides.today,
          };
        }),
        productId: selectedProduct,
        facilityId: facilityId,
        startDate: date.format('YYYY-MM-DD'),
        endDate: moment(date).add(nrOfDays, 'days').format('YYYY-MM-DD'),
        LOS: los,
      } as PivotOverrideRequestModel);
      const res = data;
      callback();
      console.log('Data Export Response: ', res);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const makeGetPivotCall = async (selectedProduct: string) => {
    const startDate = date.format('YYYY-MM-DD');
    const endDate = moment(date).add(nrOfDays, 'days').format('YYYY-MM-DD');
    const facilityId = routeInfo?.params.facilityId;

    const data = await ac.getPivotData({
      ProductId: selectedProduct,
      StartDate: startDate,
      EndDate: endDate,
      FacilityId: facilityId!,
      LOS: los,
    });

    if (!!data) {
      const pivot = formatForTable(data);
      setTableData(pivot);
    }
    const res = data;
    console.log('All data: ', res);
  };

  return (
    <Main>
      <TableDataContext.Provider
        value={{
          tableData,
          setTableData,
          makeGetPivotCall,
          generateDataCall,
          exportDataCall,
        }}
      >
        <ProductsContext.Provider
          value={{
            availableProducts: availableProducts || [],
            zoneProducts: zoneProducts || {},
            selectedProduct,
            setSelectedProduct,
            productView,
            setProductView,
            los,
            setLos,
            nrOfDays,
            setNrOfDays,
          }}
        >
          <OverrideContext.Provider
            value={{ overrides, adjustments, setOverrides, setAdjustments }}
          >
            {children}
          </OverrideContext.Provider>
        </ProductsContext.Provider>
      </TableDataContext.Provider>
    </Main>
  );
};

export default TableDataProvider;
