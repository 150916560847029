import baseInputStyle from 'components/input/baseInputStyle';
import React, { forwardRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { AiOutlineCalendar, AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';

const periodInputClass = 'periodInput';
export const DateContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 37px;
  position: relative;
  ${baseInputStyle}

  .${periodInputClass} {
    margin-bottom: 9px;
  }

  ${({ disabled }) => disabled && `opacity: 0.5;`}
`;

export const DatePickerContainer = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: inline-block;
    border: none;
    background: none;

    input {
      background: transparent;
    }
  }

  .react-datepicker__input-container > input {
    border: none;
    outline: none;
    width: 140px; /* Adjust the width as needed */
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select {
    background: #f0f0f0;
    border: 0;
    border-radius: 4px;
    padding: 5px;
    font-size: 14px;
    outline: none;
    text-align: center;
    width: 100px;
    margin: 3px 0;
  }
`;

const Icon = styled.div`
  position: absolute;
  bottom: 6px;
  right: 5px;
`;

const PTDatePicker = forwardRef<DatePicker, ReactDatePickerProps>(
  (props, ref) => {
    return (
      <DateContainer disabled={props.disabled}>
        <DatePickerContainer>
          <DatePicker
            openToDate={props.selected!}
            {...props}
            calendarStartDay={1}
            showWeekNumbers={true}
            ref={ref}
          />
        </DatePickerContainer>
        <Icon>
          <AiOutlineCalendar />
          {/* <AiOutlineClose /> */}
        </Icon>
      </DateContainer>
    );
  }
);

export default PTDatePicker;
