import Link from 'components/Link';
import SubNavBar from 'components/SubNavBar';
import Routes from 'constants/Route';
import React from 'react';
import { NavLink, generatePath, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { FacilityPageProps } from 'topdefinitions';

const ForecastBar: React.FC = () => {
  const rm = useRouteMatch() as any as FacilityPageProps['match'];
  const LocRoute = {
    Zones: Routes.FacilityScheduleZones,
    Seasons: Routes.FacilityScheduleSeasons,
  } as any;
  for (const k in LocRoute) {
    LocRoute[k] = generatePath(LocRoute[k], rm.params);
  }

  console.log('RM', rm, LocRoute);
  var path = window.location.pathname;
  return (
    <SubNavBar>
      <Link active={path === LocRoute.Zones} secondary to={LocRoute.Zones}>
        Zone schedule
      </Link>
      <Link active={path === LocRoute.Seasons} secondary to={LocRoute.Seasons}>
        Manage seasons
      </Link>
    </SubNavBar>
  );
};

export default ForecastBar;
