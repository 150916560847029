import React, { useContext, useState } from 'react';
import SlideInPopup from './SlideInPopup';

interface ModalStack {
  readonly stack: React.ReactChild[];
  push: (child: React.ReactChild) => void;
  pushSlideIn: (id: string, title: string, child: React.ReactChild) => void;
  pop: (modalStackType?: ModalStackType, id?: string) => void;
}

const ModalStackContext = React.createContext<ModalStack>({
  stack: [],
  pushSlideIn: () => {},
  push: () => {},
  pop: () => {},
});

export enum ModalStackType {
  POPUP = 1,
  SLIDE_IN = 2,
}

export const ModalStaticId = {
  NOTIFICATIONS: 'NOTIFICATIONS',
};

export const ModalProvider: React.FC = ({ children }) => {
  const [stack, setStack] = useState<React.ReactChild[]>([]);
  const [slideInStack, setSlideInStack] = useState<{
    id: string;
    title: string;
    child: React.ReactChild;
  }>();

  const push = (
    component: React.ReactChild,
    modalStackType?: ModalStackType
  ) => {
    if (!modalStackType) {
      setStack((stack) => [...stack, component]);
    } else if (modalStackType === ModalStackType.SLIDE_IN) {
    }
  };

  const pushSlideIn = (id: string, title: string, child: React.ReactChild) => {
    if (!slideInStack || slideInStack.id !== id) {
      setSlideInStack({
        id,
        title,
        child,
      });
    }
  };

  const pop = (modalStackType?: ModalStackType, id?: string) => {
    if (!modalStackType) {
      setStack((stack) => {
        const newStack = [...stack];
        newStack.pop();
        return newStack;
      });
    } else if (modalStackType === ModalStackType.SLIDE_IN) {
      setSlideInStack(undefined);
    }
  };

  return (
    <ModalStackContext.Provider value={{ pushSlideIn, stack, push, pop }}>
      {/* {stack.length > 0 && stack[stack.length - 1]} */}
      {stack.map((modal, i) => (
        <React.Fragment key={i}>{modal}</React.Fragment>
      ))}
      {!!slideInStack && (
        <SlideInPopup id={slideInStack.id} title={slideInStack.title}>
          {slideInStack.child}
        </SlideInPopup>
      )}
      {children}
    </ModalStackContext.Provider>
  );
};

const useModalStack = () => {
  const context = useContext(ModalStackContext);
  return context;
};

export default useModalStack;
