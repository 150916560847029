import { Pricing, Zone } from 'store/zone/reducer';

export const PADDING_AND_BORDER = 7;
export const MARGIN = 4;
export const DAY_TO_PIXEL_MULTIPLIER = 5;
export const MAX_ZOOM = 10;
export const MIN_ZOOM = 3;
// Styled components

export interface DateObject {
  id: string;
  from: Date;
  to: Date;
  priceProduct: Pricing;
  index: number;
}

export interface ZoneSchemaContainer {
  title: string;
  zone: Zone;
  isLive: boolean;
  schemaGID: string;
  liveSchemaGID: string;
  previewSchemaGID: string;
  alternativeSchemas: { name: string; gid: string }[];

  dateList: DateObject[];
}

export interface DateBlockProps {
  blockLists: ZoneSchemaContainer[];
  zones: Zone[];
  multipleEdit: boolean;
  toggleMultipleEdit: () => void;
  onSelectChange: (zoneGid: string, scheduleGid: string) => void;
}

export interface SegmentOptions {
  title: string;
  id: number[];
  groupId?: number[];
}
