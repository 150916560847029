import Main from 'components/Main';
import NavBar from 'components/NavBar';
import Routes from 'constants/Route';
import Demand from 'pages/Demand';
import Revenue from 'pages/Revenue';
import React from 'react';
import { Switch, Route } from 'react-router';
import styled from 'styled-components';
import Zones from 'pages/Schedule';
import PricingBar from 'pages/PriceComparison/pricingBar';
import Season from 'pages/Seasons';

//Generate a functional component that returns a Wrapper with a NavBar as first element and subroutes to Revenue and Demand pages
// Path: src\pages\Facility\index.tsx
// Compare this snippet from src\pages\Facility\index.tsx:
//

const Content = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
`;

const ChildContent = styled.div``;

const Forecast: React.FC = () => {
  return (
    <Main>
      <NavBar />
      <Content>
        <PricingBar />
        <ChildContent>
          <Switch>
            <Route path={Routes.FacilityScheduleZones} component={Zones} />
            <Route path={Routes.FacilityScheduleSeasons} component={Season} />
          </Switch>
        </ChildContent>
      </Content>
    </Main>
  );
};

export default Forecast;
