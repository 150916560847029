import { CCSTORE } from './constants';
import { Price_Resp, CacheEntry } from './definitions';

export const calcCacheUpdate = (entry: CacheEntry) => ({
  type: CCSTORE.UPDATE_CALC_CACHE,
  entry,
});

export const compCalcCacheUpdate = (entry: CacheEntry) => ({
  type: CCSTORE.UPDATE_COMP_CALC_CACHE,
  entry,
});

export const clearCacheUpdate = () => ({
  type: CCSTORE.CLEAR_CACHE,
});

export const UPDATE_BOOKING_CACHE = (
  zoneId: number,
  booking: number[],
  driveup: number[]
) => ({
  type: CCSTORE.UPDATE_BOOKING_CACHE,
  zoneId,
  booking,
  driveup,
});
