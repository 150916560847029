import Button, { ButtonColors } from 'components/input/Button';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  margin-bottom: 40px;
  height: 400px;
  width: 450px;
  margin: auto;
  background: white;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 5px;
  background-color: white;
  z-index: 1000;

  label {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const StatusWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StatusContent = styled.div<{ success: boolean }>`
  text-align: center;
  ${(props) =>
    !props.success &&
    css`
      color: red;
    `}
`;

const StatusMessage = styled.div`
  margin-top: 20px;
`;

const ConfirmRemove: React.FC<{
  onSuccess: () => void;
  onClose: () => void;
  objectTitle: string;
  objectName?: string;
}> = ({ objectTitle, objectName, onSuccess, onClose }) => {
  return (
    <Wrapper>
      <StatusWrapper>
        <StatusContent success={true}>
          <StatusMessage>
            Are you sure you wish to remove the {objectTitle}:
          </StatusMessage>
          {!!objectName && (
            <StatusMessage>
              <b>{objectName}</b>
            </StatusMessage>
          )}
        </StatusContent>
        <ButtonWrapper>
          <Button onClick={() => onSuccess()}>Yes</Button>
          <Button color={ButtonColors.secondary} onClick={() => onClose()}>
            No
          </Button>
        </ButtonWrapper>
      </StatusWrapper>
    </Wrapper>
  );
};

export default ConfirmRemove;
