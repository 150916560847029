import { matchPath } from 'react-router';
import { ApplicationState } from '../definitions';
import { FacilityRoutes } from '../../constants/Route';
import { FacilityPageProps } from '../../topdefinitions';

const numRe = /^[0-9]+$/;
const partRe = /^(?:demand|pricing|revenue)$/i;

export function selectHistoryPath(state: ApplicationState) {
  return state?.router.location.pathname;
}

export const facilityRouteQuery = { path: FacilityRoutes };

export function selectHistoryFacilityId(state: ApplicationState) {
  var route = selectHistoryFacilityMatch(state);
  var facilityId = state.uiSettings.sites.find(
    (f) => f.path === route?.params?.facilityId
  )?.id;
  return facilityId;
}

export function selectHistoryFacilityMatch(state: ApplicationState) {
  if (!state.uiSettings || state.uiSettings.isAPDemo) {
    return {
      url: '',
      params: {
        facilityId: '', //,
        //zoneId: state.uiState.activeZoneID,
      } as FacilityPageProps['match']['params'],
    };
  }
  const path = selectHistoryPath(state);
  const match = matchPath<FacilityPageProps['match']['params']>(
    path,
    facilityRouteQuery
  );
  return match;
  //   const pathParts = path ? path.split('/') : [];
  //   console.log(path, pathParts);
  //   if (
  //     pathParts.length >= 2 &&
  //     (pathParts[0] === 'site' || pathParts[0] === 'facility')
  //   ) {
  //     pathParts.shift();
  //     const facilityName = pathParts.shift();
  //     let zoneId = undefined as number | undefined;
  //     if (pathParts.length > 0 && !partRe.exec(pathParts[0])) {
  //       zoneId = parseInt;
  //     }
  //   }
  //if (path.startsWith()) return;
}
