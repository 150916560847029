import React from 'react';
import styled from 'styled-components';
import useModalStack from './useModalStack';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 201;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props extends React.PropsWithChildren<any> {}

const TransparentPopup: React.FC<Props> = ({ children }) => {
  const modalStack = useModalStack();
  let ref = React.useRef<HTMLDivElement>(null);

  return (
    <Overlay
      onClick={() => {
        modalStack.pop();
      }}
    >
      {children}
    </Overlay>
  );
};

export default TransparentPopup;
