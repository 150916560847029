import Modal from 'components/Modal';
import { PriceV3Api } from 'constants/api-fetch';
import EventEditor, { activeClass } from 'containers/EventEditor';
import useEventEditorState from 'containers/EventEditor/useEventEditorState';
import moment from 'moment';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCacheUpdate } from 'store/calcCache/action';
import { ApplicationState } from 'store/definitions';
import { eventStoreModEvent } from 'store/event/action';
import { Event } from 'store/event/definitions';
import { selectAllEventsFromCurrentFacility } from 'store/event/selector';
import { selectCurrentFacility } from 'store/zone/selector';
import styled from 'styled-components';

const MyModal = styled(Modal)`
  position: relative;

  .${activeClass} {
    position: absolute;
    top: 3rem;
    right: 120px;
  }
`;

const ModEventModal: FC<{ id: number; holiday: boolean }> = ({
  id,
  holiday,
}) => {
  const dispatch = useDispatch();
  //const zone = useSelector(selectCurrentZone);
  const fac = useSelector(selectCurrentFacility);
  const events = useSelector(selectAllEventsFromCurrentFacility);
  //const events = useSelector((root: ApplicationState) => root.eventData);
  const event = events.find((ev: Event) => ev.dateID === id)!;
  const editState = useEventEditorState({ ...event });

  return (
    <MyModal
      title={holiday ? 'Holiday' : 'Special Event'}
      noPadding={true}
      buttons={[
        {
          disabled:
            isNaN(editState.startDate.valueOf()) ||
            isNaN(editState.endDate.valueOf()) ||
            editState.startDate.valueOf() > editState.endDate.valueOf() ||
            editState.name.length === 0,
          label: 'Change',
          onClick: () => {
            var api = new PriceV3Api();
            api
              .post(
                `update/event`,
                JSON.stringify({
                  ...event,
                  ...{ ...editState, rzData: undefined },
                  startDate: moment
                    .utc(editState.startDate)
                    .format('YYYY-MM-DD HH:mm'),
                  endDate: moment
                    .utc(editState.endDate)
                    .format('YYYY-MM-DD HH:mm'),
                  routeData: editState.rzData.routeData,
                  zoneData: editState.rzData.zoneData,
                })
              )
              .then((resp: Event) => {
                dispatch(
                  eventStoreModEvent(fac?.id, {
                    dateID: event.dateID,
                    routeID: event.routeID,
                    //...event,
                    //zoneId: editState.zoneId,
                    //id: event.id,

                    isCustom: resp.isCustom,
                    //active: editState.active,
                    fullDay: editState.fullDay,
                    startDate: resp.startDate, // editState.startDate.toISOString(),
                    endDate: resp.endDate, // editState.endDate.toISOString(),
                    //demand: editState.demand,
                    kind: editState.kind,
                    name: editState.name,
                    timeOfDay: editState.timeOfDay,
                    routeData: editState.rzData.routeData,
                    zoneData: editState.rzData.zoneData,
                    //routeId: editState.routeId,
                  })
                );
              });
          },
        },
      ]}
    >
      <EventEditor
        eventEditorState={editState}
        fixedZones={holiday}
        fixedEvent={holiday}
      />
    </MyModal>
  );
};

export default ModEventModal;
