import React, { FC, useEffect, useState } from 'react';
import Route from 'constants/Route';
import { NavLink, useRouteMatch, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import ZonePicker from 'containers/ZonePicker';
import { FacilityPageProps } from 'topdefinitions';
import { useSelector } from 'react-redux';
import { ZoneFlag } from 'store/zone/reducer';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import { selectAvailableZones } from 'store/zone/selector';
import { ApplicationState } from 'store/definitions';
import FacilityPicker from 'containers/FacilityPicker';

export const NavTabWrapper = styled.nav<{
  justifyContent?: 'flex-start' | 'flex-end';
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};

  border-bottom: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
`;

export const navTabActiveClass = 'tabActive';
export const NavTab = styled(NavLink)`
  &,
  &:active,
  &:hover,
  &:visited {
    padding: 13px 25px;
    margin: 0;

    color: inherit;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: background-color 0.2s;

    &.${navTabActiveClass} {
      font-weight: 700;
      color: inherit;
      box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.palette.primary.bg};
      border-bottom: 0;
    }
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.grey.bg};
    outline: none;
  }

  &:focus {
    font-weight: 700;
  }
`;

const PickerWrapper = styled.div`
  display: flex;
  margin: auto;
  margin-right: 0;
  align-self: flex-end;
  gap: 20px;
`;

const NavBar: FC<{}> = ({}) => {
  const routeInfo = useSelector(selectHistoryFacilityMatch);
  var zones = useSelector(selectAvailableZones);
  const activeFacility = useSelector(
    (state: ApplicationState) => state.uiState.activeFacility
  );
  const [currentFacility, setCurrentFacility] = useState<string>(
    routeInfo?.params.facilityId!
  );

  const [isElectricFacility, setElectricFacility] = useState<boolean>(
    zones
      .filter((f) => f.sitePath === routeInfo?.params.facilityId!)
      .filter((f) => f.zoneFlags?.indexOf(ZoneFlag.Electric) !== -1).length > 0
  );

  useEffect(() => {
    if (currentFacility !== activeFacility) {
      var facilityZones = zones.filter((f) => f.sitePath === activeFacility);

      setElectricFacility(
        facilityZones.filter(
          (f) => f.zoneFlags?.indexOf(ZoneFlag.Electric) !== -1
        ).length > 0
      );
      setCurrentFacility(activeFacility);
    }
  }, [activeFacility]);

  const rm = useRouteMatch() as any as FacilityPageProps['match'];
  const LocRoute = {
    Pricing: Route.FacilityPricing,
    Schedule: Route.FacilityScheduleZones,
    Adjustments: Route.FacilityAdjustments,
    EVPreview: Route.EVPriceGraph,
    Forecast: Route.FacilityForecastRevenue,
    Analysis: Route.FacilityAnalysis,
  } as any;
  for (const k in LocRoute) {
    LocRoute[k] = generatePath(LocRoute[k], rm.params);
  }

  return (
    <NavTabWrapper>
      <NavTab
        isActive={(match, location) => {
          var path = generatePath(Route.FacilityForecast, rm.params);
          return location.pathname.startsWith(path);
        }}
        to={LocRoute.Forecast}
        activeClassName={navTabActiveClass}
      >
        Forecast / Analysis
      </NavTab>
      {/* <NavTab to={LocRoute.Analysis} activeClassName={navTabActiveClass}>
        Analysis
      </NavTab> */}
      <NavTab
        isActive={(match, location) => {
          var path = generatePath(Route.FacilitySchedule, rm.params);
          return location.pathname.startsWith(path);
        }}
        to={LocRoute.Schedule}
        activeClassName={navTabActiveClass}
      >
        Pricing
      </NavTab>
      <NavTab to={LocRoute.Adjustments} activeClassName={navTabActiveClass}>
        Adjustments
      </NavTab>
      {isElectricFacility && (
        <NavTab to={LocRoute.EVPreview} activeClassName={navTabActiveClass}>
          EV Prices
        </NavTab>
      )}
      <PickerWrapper>
        <FacilityPicker />
        <ZonePicker />
      </PickerWrapper>
    </NavTabWrapper>
  );
};

export default NavBar;
