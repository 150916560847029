export const ZONESTORE = {
  ZONE_APPLY: 'zonestore/apply',
  // EVENT_ADD: 'zonestore/addEvent',
  // EVENT_APPLY: 'zonestore/modEvent',
  ZONE_ADD_PRICE: 'zonestore/ADDPRICE',
  ZONE_MOD_PRICE: 'zonestore/MODPRICE',
  ZONE_REM_PRICE: 'zonestore/REMPRICE',
  ZONE_UPDATE: 'zonestore/ZONE_UPDATE',

  ZONE_UPDATE_SCHEDULE: 'zonestore/ZONE_UPDATE_SCHEDULE',
  ZONE_UPDATE_SEASONS: 'zonestore/ZONE_UPDATE_SEASONS',
} as const;

export const ZONEUPDATES = [
  ZONESTORE.ZONE_UPDATE_SCHEDULE,
  ZONESTORE.ZONE_UPDATE_SEASONS,
];

// export const DEMOZONES = [
//   ['short1', 'Short term'],
//   ['long1', 'Long term one'],
//   ['long2', 'Long term two'],
// ];
