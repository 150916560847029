import Button, { RoundButton } from 'components/input/Button';
import React from 'react';
import { FC } from 'react';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

const CloseButton = styled(RoundButton)`
  align-self: flex-start;
  max-width: fit-content;
`;

const Popover = styled.div`
  cursor: default;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 350px; // Fixed for working with alignToLeft
  top: 30px;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.common.lightBorder};
  color: ${({ theme }) => theme.palette.common.black};

  &.alignToRight {
    left: 0px;
  }

  &.alignToLeft {
    right: 0px;
  }

  label {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: flex-end;

    & input:disabled {
      color: ${({ theme }) => theme.palette.common.black};
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 15px;
`;

interface Props {
  headerContent?: React.ReactNode;
  onClose: () => void;
  alignment: 'alignToLeft' | 'alignToRight';
}

const AdjustmentPopOver: FC<Props> = ({
  headerContent,
  onClose,
  alignment,
  children,
}) => {
  return (
    <Popover tabIndex={0} className={alignment}>
      <Header>
        {headerContent}
        <CloseButton
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <IoClose size={26} />
        </CloseButton>
      </Header>
      {children}
    </Popover>
  );
};

export default AdjustmentPopOver;
