import { AlertNotificationType, NotificationModel } from 'api/notifications';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import NotificationItem from './notificationItem';

interface Props {
  notification: NotificationModel;
  onClose: () => void;
  onClick: () => void;
}

const Wrapper = styled.div<{ show: boolean | undefined }>`
  max-height: 0;
  z-index: 201;
  width: 100;
  overflow: hidden;
  display: flex;
  transition: max-height 0.5s ease-in-out;
  width: 400px;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
  ${({ show }) =>
    show &&
    css`
      max-height: 400px;
    `};
`;

const PopupNotification: React.FC<Props> = (props) => {
  const { notification, onClose, onClick } = props;
  const [show, setShow] = useState<boolean>();

  useEffect(() => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 10000);
  }, []);

  return (
    <Wrapper
      onClick={() => {
        onClick();
        setShow(false);
      }}
      onTransitionEnd={(ev) => {
        if (show === false) {
          onClose();
        }
      }}
      show={show}
    >
      <NotificationItem notification={notification} />
    </Wrapper>
  );
};

export default PopupNotification;
