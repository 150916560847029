import * as Actions from './action';
import { ZONESTORE } from './constants';
import { ActionReturns } from '../../util/tshelpers';
import moment from 'moment';
import { PriceProductExport } from 'store/uiSettings/reducer';

export type Zones = Zone[];

export enum ZoneFlag {
  Electric = 1,
  LowData = 2,
  NoAIPrediction = 3,
  ForceNoGraph = 4,
}

export interface ZoneConfig {
  IsElectric: boolean;
}
export interface Zone {
  id: number;
  name: string;
  sitePath?: string;
  gid: string;

  aggressivity: number;

  selectedPricing: number | null;
  pricings: Pricing[];
  schedules: PriceSchedule[];
  exports: PriceProductExport[];
  zoneFlags?: ZoneFlag[];
  config?: ZoneConfig;
  //  events: Event[];
}

export interface PriceSchedule {
  id: number;
  name: string;
  gid: string;
  dZoneID: number;

  previewActivationTimeStamp?: number | null;
  liveActivationTimeStamp?: number | null;
  priceActivations: PriceActivation[];
  pricings: Pricing[];
}

export interface PriceActivation {
  priceID: number;
  fromDate: string;
  toDate: string;
}
export interface Pricing {
  id: number;
  name: string;
  parentZoneId: number;
  selected?: boolean;

  parentScheduleGID?: string;
  parentScheduleID?: number;
  startDate?: string;
  endDate?: null | string;

  // minute min/max pricing levels
  aggressivity: number;

  prices: PricingRange[];
  priceGroups: PricingGroup[];
  priceDiscounts: PricingDiscount[];
  userOpts: PricingOpts;
  sourceSpanGID?: string;
}

export interface PricingScheduleSpan {
  gid: string;
  parentFacilityId: number;
  name: string;
  startDate: string;
  endDate?: string;
  recurringID?: string;
}

export interface PricingOpts {
  occupancy_target?: {
    min?: number;
    max?: number;
  };
  maxCapacity?: number;
  bookingCapacity?: number;
  longStayWeekDiscount?: number;
}

export interface PricingRange {
  id: number;
  segment: 'booking' | 'driveup' | 'surcharge';
  duration: 'hour' | 'day' | 'week';
  /** if true, active flag should be toggleable */
  toggleable: boolean;
  active: boolean;
  min: number;
  max: number;

  groupId?: number | null;
  activationRangeId?: number | null;
}

// Conditions with data ALL need to pass, ie if no data exists for any condition it's an instant pass.
export interface PricingCondition {
  startDays?: number[]; // ordered set of 1-based weekdays when the start time happens (1,2,3,4,5,6,7 are valid values)
  activeDays?: number[]; // ordered set of 1-based weekdays during the timespan where this rule applies (1,2,3,4,5,6,7 are valid values)
  durationDayRange?: [number, number]; // [from-to[ (inkl-excl) range of duration in days
  advanceBookingDayRange?: [number, number]; // [from-to[ (inkl-excl) range of days in advance of booking.
  surchargeGroup?: 'electric'; // surcharge group IDs
}

export interface PricingGroup {
  id: number;
  pricingId: number;
  name: string;
  condition: PricingCondition; // TODO: type this properly later.
}

export interface PricingDiscount {
  id: number;
  pricingId: number;
  name: string;
  segment?: string | null;
  discountPercentage: number;
  condition: PricingCondition; // TODO: type this properly later.
}

const initialState: Zones = [];
//   {
//     schedules: [],
//     id: 1, //'shortprem',
//     name: 'Short Stay Premium',
//     selectedPricing: 2, //'pricing23re',
//     aggressivity: 20,
//     exports: [],
//     pricings: [
//       {
//         name: 'dummy bad',
//         id: 1, //'pricing1badbadbad',
//         parentZoneId: 1, //'shortprem',
//         aggressivity: 0.0,

//         prices: [],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//       {
//         name: 'dummy default',
//         id: 2, //'pricing23re',
//         parentZoneId: 1, //'shortprem',
//         aggressivity: 1.0,
//         prices: [
//           {
//             id: 1, //'pricing23re_dh',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             toggleable: true,
//             min: 10,
//             max: 50,
//           },
//           {
//             id: 2, //'pricing23re_dd',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             toggleable: true,
//             min: 150,
//             max: 300,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//       {
//         name: 'dummy data',
//         id: 3, //'pricingData',
//         parentZoneId: 1, //'shortprem',
//         aggressivity: 0.0,
//         prices: [
//           {
//             id: 4, //'pricing_dh_du',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             toggleable: true,
//             min: 10,
//             max: 50,
//           },
//           {
//             id: 4, //'pricing_dd_du',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             toggleable: true,
//             min: 150,
//             max: 300,
//           },
//           {
//             id: 5, // 'pricing_dw_du',
//             active: true,
//             duration: 'week',
//             segment: 'driveup',
//             toggleable: true,
//             min: 1050,
//             max: 2100,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//     ],
//     // events: [
//     //   {
//     //     id: 'fewfwefe',
//     //     parent: 'dummy',
//     //     active: true,
//     //     demand: 53,
//     //     startDate: moment().format('YYYY-MM-DD'),
//     //     endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
//     //     fullDays: true,
//     //     kind: 'maintenance',
//     //     name: 'maintwindow1',
//     //   },
//     // ],
//   },
//   {
//     schedules: [],
//     id: 2, //'shortstand',
//     name: 'Short Stay Standard',
//     aggressivity: 1.0,
//     exports: [],
//     pricings: [
//       {
//         name: 'dummy default',
//         id: 4, //'pricing23re',
//         parentZoneId: 2, //'shortprem',
//         aggressivity: 1.0,
//         prices: [
//           {
//             id: 6, //'pricing123re_dh',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             min: 10,
//             max: 50,
//             toggleable: true,
//           },
//           {
//             id: 7, //'pricing123re_dd',
//             toggleable: true,
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             min: 150,
//             max: 300,
//           },
//           {
//             id: 8, //'pricing123re_bd',
//             toggleable: true,
//             active: true,
//             duration: 'day',
//             segment: 'booking',
//             min: 120,
//             max: 150,
//             // min: 900,
//             // max: 1500,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//       {
//         name: 'dummy data',
//         id: 5, //'pricingData',
//         parentZoneId: 2, // 'shortstand',
//         aggressivity: 0.0,
//         prices: [
//           {
//             id: 9, //'pricing_dh_du',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             toggleable: true,
//             min: 14,
//             max: 60,
//           },
//           {
//             id: 10, //'pricing_dd_du',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             toggleable: true,
//             min: 170,
//             max: 330,
//           },
//           {
//             id: 11, //'pricing_dw_du',
//             active: true,
//             duration: 'week',
//             segment: 'driveup',
//             toggleable: true,
//             min: 1250,
//             max: 2400,
//           },
//           {
//             id: 12, //'pricing_dh_bo',
//             active: true,
//             duration: 'hour',
//             segment: 'booking',
//             toggleable: true,
//             min: 10,
//             max: 50,
//           },
//           {
//             id: 13, //'pricing_dd_bo',
//             active: true,
//             duration: 'day',
//             segment: 'booking',
//             toggleable: true,
//             min: 150,
//             max: 300,
//           },
//           {
//             id: 14, //'pricing_dw_bo',
//             active: true,
//             duration: 'week',
//             segment: 'booking',
//             toggleable: true,
//             min: 1100,
//             max: 2000,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//     ],
//     //events: [],
//     selectedPricing: null,
//   },
//   {
//     schedules: [],
//     id: 3, //'shortvalet',
//     name: 'Short Stay Valet',
//     aggressivity: 1.0,
//     exports: [],
//     pricings: [
//       {
//         name: 'dummy default',
//         id: 6, //'pricing23re',
//         parentZoneId: 3, //'shortvalet',
//         aggressivity: 1.0,
//         prices: [
//           {
//             id: 15, //'pricing123re_dh',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             min: 10,
//             max: 50,
//             toggleable: true,
//           },
//           {
//             id: 16, //'pricing123re_dd',
//             toggleable: true,
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             min: 150,
//             max: 300,
//           },
//           // {
//           //   id: 'pricing123re_bd',
//           //   toggleable: true,
//           //   active: true,
//           //   duration: 'day',
//           //   segment: 'booking',
//           //   min: 120,
//           //   max: 150,
//           //   // min: 900,
//           //   // max: 1500,
//           // },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//       {
//         name: 'dummy data',
//         id: 7, //'pricingData',
//         parentZoneId: 3, //'shortvalet',
//         aggressivity: 0.0,
//         prices: [
//           {
//             id: 17, // 'pricing_dh_du',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             toggleable: true,
//             min: 20,
//             max: 80,
//           },
//           {
//             id: 18, //'pricing_dd_du',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             toggleable: true,
//             min: 200,
//             max: 440,
//           },
//           {
//             id: 19, //'pricing_dw_du',
//             active: true,
//             duration: 'week',
//             segment: 'driveup',
//             toggleable: true,
//             min: 1600,
//             max: 3000,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//     ],
//     //events: [],
//     selectedPricing: null,
//   },

//   {
//     schedules: [],
//     id: 4, //'longbudget',
//     name: 'Long Stay Budget',
//     aggressivity: 1.0,
//     exports: [],
//     pricings: [
//       {
//         name: 'dummy default',
//         id: 8, //'pricing23re',
//         parentZoneId: 4, //'shortprem',
//         aggressivity: 1.0,
//         prices: [
//           {
//             toggleable: true,
//             id: 20, //'pricing123re_dh',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             min: 10,
//             max: 50,
//           },
//           {
//             toggleable: true,
//             id: 21, //'pricing123re_dd',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             min: 150,
//             max: 300,
//           },
//           {
//             toggleable: true,
//             id: 22, //'pricing123re_bd',
//             active: true,
//             duration: 'day',
//             segment: 'booking',
//             min: 120,
//             max: 150,
//             // min: 900,
//             // max: 1500,
//           },
//           {
//             toggleable: true,
//             active: true,
//             id: 23, //'pricingLTBUDweek',
//             duration: 'week',
//             segment: 'booking',
//             min: 700,
//             max: 1200,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//       {
//         name: 'dummy data',
//         id: 9, //'pricingData',
//         parentZoneId: 4, //'longbudget',
//         aggressivity: 0.0,
//         prices: [
//           {
//             id: 24, // 'pricing_dh_du',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             toggleable: true,
//             min: 18,
//             max: 70,
//           },
//           {
//             id: 25, //'pricing_dd_du',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             toggleable: true,
//             min: 190,
//             max: 400,
//           },
//           {
//             id: 26, //'pricing_dw_du',
//             active: true,
//             duration: 'week',
//             segment: 'driveup',
//             toggleable: true,
//             min: 1500,
//             max: 2800,
//           },
//           {
//             id: 27, // 'pricing_dh_bo',
//             active: true,
//             duration: 'hour',
//             segment: 'booking',
//             toggleable: true,
//             min: 14,
//             max: 60,
//           },
//           {
//             id: 28, //'pricing_dd_bo',
//             active: true,
//             duration: 'day',
//             segment: 'booking',
//             toggleable: true,
//             min: 180,
//             max: 360,
//           },
//           {
//             id: 29, // 'pricing_dw_bo',
//             active: true,
//             duration: 'week',
//             segment: 'booking',
//             toggleable: true,
//             min: 1500,
//             max: 2400,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//     ],
//     // events: [],
//     selectedPricing: null,
//   },
//   {
//     schedules: [],
//     id: 5, //'longstd',
//     name: 'Long Stay Standard',
//     aggressivity: 1.0,
//     exports: [],
//     pricings: [
//       {
//         name: 'dummy default',
//         id: 10, //'pricing23re',
//         parentZoneId: 5, //'longstd',
//         aggressivity: 1.0,
//         prices: [
//           {
//             toggleable: true,
//             id: 30, //'pricing123re_dh',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             min: 10,
//             max: 50,
//           },
//           {
//             toggleable: true,
//             id: 31, //'pricing123re_dd',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             min: 150,
//             max: 300,
//           },
//           {
//             toggleable: true,
//             id: 32, // 'pricing123re_bd',
//             active: true,
//             duration: 'day',
//             segment: 'booking',
//             min: 120,
//             max: 150,
//             // min: 900,
//             // max: 1500,
//           },
//           {
//             toggleable: true,
//             active: true,
//             id: 33, //'pricingLTBUDweek',
//             duration: 'week',
//             segment: 'booking',
//             min: 700,
//             max: 1200,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },

//       {
//         name: 'dummy data',
//         id: 11, //'pricingData',
//         parentZoneId: 5, //'longstd',
//         aggressivity: 0.0,
//         prices: [
//           {
//             id: 34, //'pricing_dh_du',
//             active: true,
//             duration: 'hour',
//             segment: 'driveup',
//             toggleable: true,
//             min: 30,
//             max: 100,
//           },
//           {
//             id: 35, //'pricing_dd_du',
//             active: true,
//             duration: 'day',
//             segment: 'driveup',
//             toggleable: true,
//             min: 250,
//             max: 500,
//           },
//           {
//             id: 36, //'pricing_dw_du',
//             active: true,
//             duration: 'week',
//             segment: 'driveup',
//             toggleable: true,
//             min: 1800,
//             max: 3200,
//           },
//           {
//             id: 37, //'pricing_dh_bo',
//             active: true,
//             duration: 'hour',
//             segment: 'booking',
//             toggleable: true,
//             min: 24,
//             max: 80,
//           },
//           {
//             id: 38, //'pricing_dd_bo',
//             active: true,
//             duration: 'day',
//             segment: 'booking',
//             toggleable: true,
//             min: 250,
//             max: 500,
//           },
//           {
//             id: 39, //'pricing_dw_bo',
//             active: true,
//             duration: 'week',
//             segment: 'booking',
//             toggleable: true,
//             min: 2000,
//             max: 3400,
//           },
//         ],
//         priceDiscounts: [],
//         priceGroups: [],
//         userOpts: {},
//       },
//     ],
//     //events: [],
//     selectedPricing: null,
//   },
// ];

for (let zone of initialState) {
  if (zone.pricings && zone.pricings.length > 0 && !zone.selectedPricing)
    zone.selectedPricing = zone.pricings[0].id;
  for (let pricing of zone.pricings) {
    pricing.parentZoneId = zone.id;
  }
}

type Actions = ActionReturns<typeof Actions>;

export function reducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ZONESTORE.ZONE_UPDATE:
      return action.zones;
    case ZONESTORE.ZONE_UPDATE_SCHEDULE:
      return state.map((zn) =>
        zn.id !== action.zoneId
          ? zn
          : {
              ...zn,
              schedules: zn.schedules.map((sch) =>
                sch.gid !== action.scheduleGid ? sch : action.updatedSchedule
              ),
            }
      );
    case ZONESTORE.ZONE_UPDATE_SEASONS:
      return state.map((zn) => {
        if (action.schedule.filter((f) => f.dZoneID === zn.id).length === 0)
          return zn;
        return {
          ...zn,
          schedules: action.schedule.filter((f) => f.dZoneID === zn.id),
        };
      });
    case ZONESTORE.ZONE_APPLY:
      return state.map((zn) =>
        zn.id === action.zoneId ? { ...zn, ...action.changes } : zn
      );
    case ZONESTORE.ZONE_ADD_PRICE: {
      console.log(state, action.pricing);
      return state.map((zn) =>
        zn.id !== action.pricing.parentZoneId
          ? zn // untouched zone
          : {
              // mutated zone
              ...zn,
              pricings:
                // append the new pricing
                [...zn.pricings, action.pricing],
            }
      );
    }
    case ZONESTORE.ZONE_MOD_PRICE: {
      return state.map((zn) =>
        zn.id !== action.pricing.parentZoneId
          ? zn // untouched zone
          : {
              // mutated zone
              ...zn,
              pricings: zn.pricings.map(
                // return the modified pricing if it's the updated one, otherwise return what exists.
                (pr) => (pr.id === action.pricing.id ? action.pricing : pr)
              ),
            }
      );
    }
    case ZONESTORE.ZONE_REM_PRICE: {
      return state.map((zn) =>
        zn.id !== action.zoneId
          ? zn // untouched zone
          : {
              // mutated zone
              ...zn,
              // filter out the specified pricing id.
              pricings: zn.pricings.filter((pr) => pr.id !== action.pricingId),
            }
      );
    }
  }
  return state;
}
