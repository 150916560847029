import Radio from 'components/input/Radio';
import Modal from 'components/Modal';
import useModalStack from 'components/Modal/useModalStack';
import Spinner from 'components/Spinner';
import usePricingEditorReducer, {
  actions,
} from 'containers/PriceProductEditor/usePricingEditorState';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import AIDefaults, {
  GeneralStrategy,
  generateAiDefault,
  tryGetAIDefaultApplicator,
} from 'constants/AIDefaults';
import { useSelector } from 'react-redux';
import { selectCurrentFacility, selectCurrentZones } from 'store/zone/selector';
import AIController, { AskAIRequestrModel } from 'api/ai';

const generalStategyName = 'generalStrategy';
const segmentStrategyName = 'segmentStrategy';

const MyModal = styled(Modal)`
  width: 500px;
`;

const MySpinner = styled(Spinner)`
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Question = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
`;

interface Props {
  priceProductEditorReducer: ReturnType<typeof usePricingEditorReducer>;
  zoneId: number;
  startDate: string;
  endDate: string;
}

const PriceProductAIModal: FC<Props> = ({
  priceProductEditorReducer,
  zoneId,
  startDate,
  endDate,
}) => {
  const [generalStrategy, setGeneralStrategy] = useState<GeneralStrategy>();
  const [processing, setProcessing] = useState(false);
  const [controller, setController] = useState<AbortController>();
  const curFacility = useSelector(selectCurrentFacility);
  const curZone = useSelector(selectCurrentZones).find((z) => z.id === zoneId);

  const modalStack = useModalStack();

  const aiController = new AIController();

  const handleGeneralStratChange = (
    eve: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGeneralStrategy(Number(eve.target.value) as GeneralStrategy);
  };

  const handleProcessClick = () => {
    setProcessing(true);

    setTimeout(async () => {
      let controller = new AbortController();
      setController(controller);
      const { signal } = controller;

      var result = await aiController.fetchAskAIPrice(
        {
          zoneId,
          aggression: generalStrategy!,
          startDate,
          endDate,
        } as AskAIRequestrModel,
        signal
      );

      // Try getting a "new" applicator
      const applicator = tryGetAIDefaultApplicator(
        zoneId,
        result.priceRanges,
        result.priceRangeGroups
      );
      const [, dispatch] = priceProductEditorReducer;

      if (applicator) {
        // If an applicator was gotten we short-circuit and use it
        dispatch(actions.applyAIDefaultsFN(applicator));
        modalStack.pop();
        return;
      }

      // Otherwise we fall back to the old ...
      // Regular defaults from the AIDefaults.json file
      // var defaults = AIDefaults.filter(
      //   (d) => d.generalStrategy === generalStrategy && d.CliZoneId === zoneId
      // );

      //   // If none was found there, we peek into other data sources (Among Oslo-AI-Pricing.json)
      //   defaults = generateAiDefault(
      //     curFacility,
      //     curZone!,
      //     generalStrategy!,
      //     priceProductEditorReducer
      //   );

      // dispatch(actions.applyOldAIDefaults(defaults));
    }, 4000);
  };

  if (processing) {
    return (
      <MyModal notClosable title="Processing...">
        <MySpinner />
      </MyModal>
    );
  }

  return (
    <MyModal
      title="Ask the AI"
      buttons={[
        {
          label: 'Find optimal price settings!',
          disabled: generalStrategy === undefined,
          dontPopAfterClick: true,
          onClick: handleProcessClick,
        },
      ]}
    >
      <Section>
        <Question>What is your general strategy?</Question>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.high_volume_priority}
          value={GeneralStrategy.high_volume_priority}
          onChange={handleGeneralStratChange}
        >
          Maximize volumne
        </Radio>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.volume_priority}
          value={GeneralStrategy.volume_priority}
          onChange={handleGeneralStratChange}
        >
          Volume based
        </Radio>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.balanced}
          value={GeneralStrategy.balanced}
          onChange={handleGeneralStratChange}
        >
          Balanced strategy
        </Radio>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.aggressive}
          value={GeneralStrategy.aggressive}
          onChange={handleGeneralStratChange}
        >
          High revenue
        </Radio>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.very_aggressive}
          value={GeneralStrategy.very_aggressive}
          onChange={handleGeneralStratChange}
        >
          Maximize revenue
        </Radio>
      </Section>

      {/*<Section>
        <Question>What is your segment strategy?</Question>
        <Radio
          name={segmentStrategyName}
          checked={segmentStrategy === SegmentStrategy.driveUp}
          value={SegmentStrategy.driveUp}
          onChange={handleSegmentStratChange}
        >
          Drive up focused
        </Radio>
         <Radio
          name={segmentStrategyName}
          checked={segmentStrategy === SegmentStrategy.balanced}
          value={SegmentStrategy.balanced}
          onChange={handleSegmentStratChange}
        >
          Balanced
        </Radio>
        <Radio
          name={segmentStrategyName}
          checked={segmentStrategy === SegmentStrategy.booking}
          value={SegmentStrategy.booking}
          onChange={handleSegmentStratChange}
        >
          Booking focused
        </Radio> 
      </Section>*/}
    </MyModal>
  );
};

export default PriceProductAIModal;
