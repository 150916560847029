import React, { useEffect, useState } from 'react';
import SeasonRow from './row';
import Main from 'components/Main';
import { PricingScheduleSpan } from 'store/zone/reducer';
import moment from 'moment';
import SeasonController from 'api/seasons';
import { useSelector } from 'react-redux';
import { selectCurrentFacility } from 'store/zone/selector';
import List from 'components/List';
import styled from 'styled-components';
import DeleteButton from 'components/input/DeleteButton';
import SubHeader from 'components/SubHeader';
import Button, { ButtonColors } from 'components/input/Button';
import useModalStack from 'components/Modal/useModalStack';
import UpsertSeasonModel from './upsertSeasonModal';
import { hasEditRights } from 'store/uiSettings/selectors';

const Link = styled.div`
  color: ${({ theme }) => theme.palette.primary.bg};
  font-weight: bold;
  :hover {
    color: rgb(137, 87, 160);
    -webkit-text-decoration: underline;
    text-decoration: underline;
    -webkit-text-decoration-color: rgb(117, 210, 207);
    text-decoration-color: rgb(117, 210, 207);
    -webkit-text-decoration-thickness: 2px;
    text-decoration-thickness: 2px;
    text-underline-position: under;
    cursor: pointer;
  }
`;

const PricingScheduleList: React.FC = () => {
  var seasonController = new SeasonController();
  var facility = useSelector(selectCurrentFacility);
  const [seasons, setSeasons] = useState<PricingScheduleSpan[]>([]);
  const canEdit = useSelector(hasEditRights);

  const modalStack = useModalStack();

  useEffect(() => {
    fetchFacilitySeasons();
  }, [facility]);

  const fetchFacilitySeasons = async () => {
    var result = await seasonController.getFacilitySeasons(facility.id);
    setSeasons(result);
  };
  const handleDelete = async (id: string) => {
    // Handle delete logic here
    await seasonController.deleteSeason(id);
    fetchFacilitySeasons();
  };

  const handleEdit = (row: PricingScheduleSpan) => {
    // Handle edit logic here

    modalStack.push(
      <UpsertSeasonModel
        model={row}
        seasonList={seasons}
        onComplete={onComplete}
      />
    );
    console.log(`Editing pricing schedule with ID: ${row.gid}`);
  };

  const onComplete = async (callback: () => void) => {
    await fetchFacilitySeasons();
    callback();
  };

  return (
    <Main>
      <SubHeader
        buttons={
          canEdit ? (
            <Button
              color={ButtonColors.add}
              onClick={() => {
                modalStack.push(
                  <UpsertSeasonModel
                    seasonList={seasons}
                    onComplete={onComplete}
                  />
                );
              }}
            >
              Add season
            </Button>
          ) : undefined
        }
      >
        Manage seasons
      </SubHeader>
      <div>
        <List
          rows={seasons}
          columnSettings={[
            {
              header: 'Season name',
              display: ({ row }) => (
                <Link
                  onClick={(evt) => {
                    evt.preventDefault();
                    handleEdit(row);
                  }}
                >
                  {row.name}
                </Link>
              ),
            },
            {
              header: 'Activated from',
              display: ({ row }) => (
                <div>{moment(row.startDate).format('DD MMMM')}</div>
              ),
            },
            {
              header: '',
              flexGrow: 0,
              minWidth: '50px',
              display: ({ row }) => (
                <>
                  {canEdit && seasons.length > 1 && (
                    <DeleteButton
                      onClick={(evt) => {
                        evt.preventDefault();
                        handleDelete(row.recurringID!);
                      }}
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </div>
    </Main>
  );
};

export default PricingScheduleList;
