import moment from 'moment';
import React, { MouseEventHandler } from 'react';
import { Bar, Cell, Line, YAxis } from 'recharts';
import { GTransformer } from './defs';

import {
  findGraphLevel,
  firstToUpper,
  isSpecialDay,
  numToSpacedString,
  roundUpMax,
} from './GraphData';
import { yAxisWidth } from './GraphBase';
import { useTheme } from 'styled-components';

const doWithNonNeg = <R,>(get: () => number, act: (v: number) => R) => {
  const val = get();
  if (val >= 0) return act(val);
  else return null;
};

export const demandTransformer: GTransformer = (
  data,
  zoneSelected,
  _,
  graphView,
  segment,
  onEventClick
) => {
  var demandIndexs = [
    'occupancy',
    'actual_occupancy',
    'combined_capacity_booking',
    'pree_book',
  ];
  var maxOcc = data.data.reduce((pre, cur) => {
    const max = demandIndexs.reduce((p, index) => {
      const seriesIndex = data.seriesIDs.findIndex((sid) => sid === index);
      const c = cur.series[seriesIndex];
      if (!c) return p;
      return Math.max(p!, c!);
    }, 0);
    console.log('max', max, 'pre', pre, 'max!');
    return Math.max(pre, max!);
  }, 0);
  const maxPri = data.data.reduce((pre, cur) => Math.max(pre, cur.price), 0);

  const leftMaxViewValue = maxOcc + maxOcc * 0.2;
  const rightMaxViewValue = findGraphLevel(maxPri);

  if (segment === 'all' && (!data.driveups || !data.bookings)) {
    data.bookings = true;
    data.driveups = true;
  }
  var capacityIndex = data.seriesIDs.findIndex(
    (sid) =>
      (data.bookings &&
        data.driveups &&
        (sid === 'combined_capacity_booking' ||
          sid === 'combined_capacity_driveup')) ||
      (!data.bookings && data.driveups && sid === 'capacity_driveup') ||
      (data.bookings && !data.driveups && sid === 'capacity_booking')
  );

  const chartData = (() => {
    return [
      // doWithNonNeg(
      //   () => data.seriesIDs.findIndex((sid) => sid === 'occupancy_booking'),
      //   (idx) => (
      //     <Line
      //       type="line"
      //       yAxisId="left"
      //       dataKey={`series[${idx}]`}
      //       name="Occupancy Booking"
      //       key="occupancy_booking"
      //       fill={'#509392'}
      //       stroke={'#509392'}
      //     ></Line>
      //   )
      // ),
      // doWithNonNeg(
      //   () =>
      //     data.seriesIDs.findIndex(
      //       (sid) => sid === '__compare__occupancy_booking'
      //     ),
      //   (idx) => (
      //     <Line
      //       type="line"
      //       yAxisId="left"
      //       dataKey={`series[${idx}]`}
      //       name="Compare Occupancy Booking"
      //       key="compare_occupancy_booking"
      //       fill={'#2f438c'}
      //       stroke={'#2f438c'}
      //     ></Line>
      //   )
      // ),
      // doWithNonNeg(
      //   () => data.seriesIDs.findIndex((sid) => sid === 'occupancy_driveup'),
      //   (idx) => (
      //     <Line
      //       type="line"
      //       yAxisId="left"
      //       dataKey={`series[${idx}]`}
      //       name="Occupancy Driveup"
      //       key="occupancy_driveup"
      //       fill={'#8BCFCE'}
      //       stroke={'#8BCFCE'}
      //     ></Line>
      //   )
      // ),
      // doWithNonNeg(
      //   () =>
      //     data.seriesIDs.findIndex(
      //       (sid) => sid === '__compare__occupancy_driveup'
      //     ),
      //   (idx) => (
      //     <Line
      //       type="line"
      //       yAxisId="left"
      //       dataKey={`series[${idx}]`}
      //       name="Comapre Occupancy Driveup"
      //       key="compare_occupancy_driveup"
      //       fill={'#8b9ccf'}
      //       stroke={'#8b9ccf'}
      //     ></Line>
      //   )
      // ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'arrival_booking'),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                key={'booking_arrival'}
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Booking Arrival Volume"
                stackId="a"
                fill="#E3CBEB"
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;
                  // !data.zone
                  //   ? false
                  //   : data.zone.events.find((ev) => {
                  //       let sd = new Date(ev.startDate);
                  //       let ed = new Date(ev.endDate);
                  //       let odate = new Date(ent.odate);
                  //       if (sd <= odate && odate <= ed) return true;
                  //       return false;
                  //     });

                  return (
                    <Cell key={idx} fill={special ? '#CECDF1' : '#E3CBEB'} />
                  );
                })}
              </Bar>
            );
          }
          return null;
          return (
            <Line
              type="line"
              yAxisId="left"
              dataKey={`series[${idx}]`}
              name="Booking Arrival Volume"
              key="booking_arrival"
              fill={'#E3CBEB'}
              stroke={'#E3CBEB'}
            >
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;
                // !data.zone
                //   ? false
                //   : data.zone.events.find((ev) => {
                //       let sd = new Date(ev.startDate);
                //       let ed = new Date(ev.endDate);
                //       let odate = new Date(ent.odate);
                //       if (sd <= odate && odate <= ed) return true;
                //       return false;
                //     });

                return (
                  <Cell key={idx} fill={special ? '#CECDF1' : '#E3CBEB'} />
                );
              })}
            </Line>
          );
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__arrival_booking'
          ),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                key={'compare_booking_arrival'}
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Compare Booking Arrival Volume"
                stackId="ca"
                fill="#E3CBEB"
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;
                  // !data.zone
                  //   ? false
                  //   : data.zone.events.find((ev) => {
                  //       let sd = new Date(ev.startDate);
                  //       let ed = new Date(ev.endDate);
                  //       let odate = new Date(ent.odate);
                  //       if (sd <= odate && odate <= ed) return true;
                  //       return false;
                  //     });

                  return (
                    <Cell key={idx} fill={special ? '#b4b3dd' : '#c7a3d3'} />
                  );
                })}
              </Bar>
            );
          }
          return null;
          return (
            <Line
              type="line"
              yAxisId="left"
              dataKey={`series[${idx}]`}
              name="Compare Booking Arrival Volume"
              key="compare_booking_arrival"
              fill={'#E3CBEB'}
              stroke={'#E3CBEB'}
            >
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;
                // !data.zone
                //   ? false
                //   : data.zone.events.find((ev) => {
                //       let sd = new Date(ev.startDate);
                //       let ed = new Date(ev.endDate);
                //       let odate = new Date(ent.odate);
                //       if (sd <= odate && odate <= ed) return true;
                //       return false;
                //     });

                return (
                  <Cell key={idx} fill={special ? '#b4b3dd' : '#c7a3d3'} />
                );
              })}
            </Line>
          );
        }
      ),

      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'arrival_driveup'),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Driveup Arrival Volume"
                stackId="a"
                key="duDemand"
                fill={'#825B9C'}
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;
                  // const special = !data.zone
                  //   ? false
                  //   : data.zone.events.find((ev) => {
                  //       let sd = new Date(ev.startDate);
                  //       let ed = new Date(ev.endDate);
                  //       let odate = new Date(ent.odate);
                  //       if (sd <= odate && odate <= ed) return true;
                  //       return false;
                  //     });

                  let fill = '#825B9C';
                  if (special) {
                    fill = '#5B67A2';
                  } else if (ent.isDemandPrediction) {
                    fill = '#dabaf0';
                  }

                  return <Cell key={idx} fill={fill} />;
                })}
              </Bar>
            );
          }
          return null;
          return (
            <Line
              type="line"
              yAxisId="left"
              dataKey={`series[${idx}]`}
              name="Driveup Arrival Volume"
              key="duDemand"
              fill={'#825B9C'}
              stroke={'#825B9C'}
            >
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;
                // const special = !data.zone
                //   ? false
                //   : data.zone.events.find((ev) => {
                //       let sd = new Date(ev.startDate);
                //       let ed = new Date(ev.endDate);
                //       let odate = new Date(ent.odate);
                //       if (sd <= odate && odate <= ed) return true;
                //       return false;
                //     });

                let fill = '#825B9C';
                if (special) {
                  fill = '#5B67A2';
                } else if (ent.isDemandPrediction) {
                  fill = '#dabaf0';
                }

                return <Cell key={idx} fill={fill} />;
              })}
            </Line>
          );
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__arrival_driveup'
          ),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Compare Driveup Arrival Volume"
                stackId="ca"
                key="compare_duDemand"
                fill={'#825B9C'}
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;
                  // const special = !data.zone
                  //   ? false
                  //   : data.zone.events.find((ev) => {
                  //       let sd = new Date(ev.startDate);
                  //       let ed = new Date(ev.endDate);
                  //       let odate = new Date(ent.odate);
                  //       if (sd <= odate && odate <= ed) return true;
                  //       return false;
                  //     });

                  let fill = '#674280';
                  if (special) {
                    fill = '#44508d';
                  } else if (ent.isDemandPrediction) {
                    fill = '#c19dda';
                  }

                  return <Cell key={idx} fill={fill} />;
                })}
              </Bar>
            );
          }
          return null;
          return (
            <Line
              type="line"
              yAxisId="left"
              dataKey={`series[${idx}]`}
              name="Compare Driveup Arrival Volume"
              key="compare_duDemand"
              fill={'#825B9C'}
              stroke={'#825B9C'}
            >
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;
                // const special = !data.zone
                //   ? false
                //   : data.zone.events.find((ev) => {
                //       let sd = new Date(ev.startDate);
                //       let ed = new Date(ev.endDate);
                //       let odate = new Date(ent.odate);
                //       if (sd <= odate && odate <= ed) return true;
                //       return false;
                //     });

                let fill = '#674280';
                if (special) {
                  fill = '#44508d';
                } else if (ent.isDemandPrediction) {
                  fill = '#c19dda';
                }

                return <Cell key={idx} fill={fill} />;
              })}
            </Line>
          );
        }
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'capacity_booking'),
        (idx) => {
          if (data.bookings && !data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Capacity booking"
                key={idx}
                fill={'#000'}
                stroke={'#000'}
                strokeWidth={3}
                activeDot={
                  <ActiveCapacityDot
                    nrOfZones={data.zones.length}
                    onDotClick={(e: SVGGElement, elemIndex: number) => {
                      if (onEventClick) onEventClick(e, elemIndex, idx, data);
                    }}
                  />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'capacity_driveup'),
        (idx) => {
          if (!data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Capacity driveup"
                key={idx}
                fill={'#000'}
                stroke={'#000'}
                strokeWidth={3}
                activeDot={
                  <ActiveCapacityDot
                    nrOfZones={data.zones.length}
                    onDotClick={(e: SVGGElement, elemIndex: number) => {
                      if (onEventClick) onEventClick(e, elemIndex, idx, data);
                    }}
                  />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex((sid) => sid === 'capacity_upper_booking'),
        (idx) => {
          if (data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Capacity th upper"
                key={idx}
                fill={'#808080'}
                stroke={'#808080'}
                strokeWidth={1}
              ></Line>
            );
          }
          return null;
        }
      ),

      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex((sid) => sid === 'capacity_lower_booking'),
        (idx) => {
          if (data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Capacity th lower"
                key={idx}
                fill={'#808080'}
                stroke={'#808080'}
                strokeWidth={1}
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) =>
              sid === 'combined_capacity_booking' ||
              sid === 'combined_capacity_driveup'
          ),
        (idx) => {
          if (data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Capacity"
                key={idx}
                fill={'#000'}
                stroke={'#000'}
                strokeWidth={3}
                activeDot={
                  <ActiveCapacityDot
                    nrOfZones={data.zones.length}
                    onDotClick={(e: SVGGElement, elemIndex: number) => {
                      if (onEventClick) onEventClick(e, elemIndex, idx, data);
                    }}
                  />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'occupancy'),
        (idx) => {
          if (data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Forecast"
                key="total_occupancy"
                fill={'#3B4365'}
                stroke={'#3B4365'}
                strokeWidth={3}
                dot={
                  <CustomDot fill={'#3B4365'} capacityIndex={capacityIndex} />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'actual_occupancy'),
        (idx) => {
          if (data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy"
                key="total_actual_occupancy"
                fill={'#3B4365'}
                stroke={'#3B4365'}
                strokeWidth={3}
                dot={
                  <CustomDot fill={'#3B4365'} capacityIndex={capacityIndex} />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__actual_occupancy'
          ),
        (idx) => {
          if (data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy LP"
                key="__compare__occupancy"
                fill={'#adb8e7'}
                stroke={'#adb8e7'}
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'occupancy_booking'),
        (idx) => {
          if (data.bookings && !data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Forecast pre-book"
                key="booking_occupancy"
                fill={'#F7A379'}
                strokeWidth={3}
                stroke={'#F7A379'}
                dot={
                  <CustomDot fill={'#F7A379'} capacityIndex={capacityIndex} />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex((sid) => sid === 'actual_occupancy_booking'),
        (idx) => {
          if (data.bookings && !data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy pre-book"
                key="abooking_occupancy"
                fill={'#F7A379'}
                stroke={'#F7A379'}
                strokeWidth={3}
                dot={
                  <CustomDot fill={'#F7A379'} capacityIndex={capacityIndex} />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__actual_occupancy_booking'
          ),
        (idx) => {
          if (data.bookings && !data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy pre-book LP"
                key="compare_booking_occupancy"
                fill={'#eec8b2'}
                stroke={'#eec8b2'}
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'occupancy_driveup'),
        (idx) => {
          if (!data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Forecast driveup"
                key="driveup_occupancy"
                fill={'#8957A0'}
                stroke={'#8957A0'}
                strokeWidth={3}
                dot={
                  <CustomDot fill={'#8957A0'} capacityIndex={capacityIndex} />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex((sid) => sid === 'actual_occupancy_driveup'),
        (idx) => {
          if (!data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy driveup"
                key="adriveup_occupancy"
                fill={'#8957A0'}
                stroke={'#8957A0'}
                strokeWidth={3}
                dot={
                  <CustomDot fill={'#8957A0'} capacityIndex={capacityIndex} />
                }
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__actual_occupancy_driveup'
          ),
        (idx) => {
          if (!data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy driveup LP"
                key="comapre_driveup_occupancy"
                fill={'#d2a7e6'}
                stroke={'#d2a7e6'}
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__pree_book_booking'
          ),
        (idx) => {
          if (data.bookings && !data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy pre book LP SD"
                key="c_bookings_pree_book"
                fill={'#8ab171'}
                stroke={'#8ab171'}
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__pree_book_driveup'
          ),
        (idx) => {
          if (!data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy driveup LP SD"
                key="c_driveup_pree_book"
                fill={'#8ab171'}
                stroke={'#8ab171'}
              ></Line>
            );
          }
          return null;
        }
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === '__compare__pree_book'),
        (idx) => {
          if (data.bookings && data.driveups) {
            return (
              <Line
                type="line"
                yAxisId={
                  graphView !== 'bars'
                    ? 'left'
                    : !data.data[0].price
                    ? 'right'
                    : 'left_left'
                }
                dataKey={`series[${idx}]`}
                name="Occupancy LP SD"
                key="c_pree_book"
                fill={'#8ab171'}
                stroke={'#8ab171'}
              ></Line>
            );
          }
          return null;
        }
      ),

      !data.driveups !== !data.bookings &&
        zoneSelected &&
        graphView === 'bars' && (
          <Line
            name={firstToUpper(data.priceType + ` prices`)}
            yAxisId="right"
            type="line"
            dataKey="price"
            stroke="#8BD3D0"
            strokeWidth={2}
            dot={{ fill: '#8BD3D0', strokeWidth: 0 }}
            key="price"
          ></Line>
        ),
      // && data.realZoneCount < 2
    ];
  })();

  return { chartData, leftMaxViewValue, rightMaxViewValue };
};

const ActiveCapacityDot = (props: any) => {
  const { cx, cy, stroke, payload, index, value, onDotClick, nrOfZones } =
    props;

  if (!value) return null;

  var fill = props.fill;

  var style = {};
  if (nrOfZones === 1) {
    style = {
      cursor: 'pointer',
    };
  }

  return (
    <g
      style={style}
      onClick={(e: React.MouseEvent<SVGGElement, MouseEvent>) => {
        nrOfZones === 1 && onDotClick(e.target as SVGGElement, index);
      }}
    >
      <circle cx={cx} cy={cy} r={4} stroke={fill} fill={fill} />;
    </g>
  );
};

const CustomDot = (props: any) => {
  const { cx, cy, stroke, payload, value } = props;

  if (!value) return null;

  var capacity = payload.series[props.capacityIndex];
  var fill = props.fill;
  if (value > capacity * 0.75) {
    fill = 'yellow';
  }
  if (value > capacity * 0.9) {
    fill = 'red';
  }

  return <circle cx={cx} cy={cy} r={4} stroke={stroke} fill={fill} />;
};
