import { PivotDataModel, PivotDataResponseModel } from 'api/adjustment';

export type CellDataModel = PivotDataModel & {
  override?: number | null;
  adjustment?: number | null;
};

export type TableData = Map<string, Map<string, CellDataModel>>;

export const formatForTable = (rawData: PivotDataResponseModel) => {
  const formattedTableData = new Map<
    string,
    Map<string, CellDataModel>
  >() as TableData;

  rawData.data.forEach((dataPoint) => {
    if (!formattedTableData.has(dataPoint.date)) {
      const dayMap = new Map();

      formattedTableData.set(
        `${dataPoint.date}`,
        dayMap.set(`${dataPoint.day}`, {
          ...dataPoint,
        })
      );
    } else {
      // We have the date, add day-keyes with values
      const date = formattedTableData.get(`${dataPoint.date}`);

      if (!!date) date.set(`${dataPoint.day}`, { ...dataPoint });
    }
  });

  return formattedTableData;
};

export const highlightColumn = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  x: number,
  y: number
) => {
  var activeElements = document.getElementsByClassName('active');
  const elements = document.querySelectorAll(`.highlight`);

  elements.forEach((element) => {
    (element as HTMLDivElement).classList.remove('highlight');
  });

  if (activeElements.length > 0) {
    var prevElement = activeElements[0] as HTMLDivElement;
    prevElement.classList.remove('active');
  }

  var element = e.target as HTMLDivElement;
  element.classList.add('active');
  var days = document.getElementsByClassName(`row-${x}`);
  for (var i = 0; i < days.length; i++) {
    var day = days[i] as HTMLDivElement;
    if (!day.classList.contains('highlight')) day.classList.add('highlight');
  }

  var dates = document.getElementsByClassName(`column-${y}`);
  for (var i = 0; i < dates.length; i++) {
    var date = dates[i] as HTMLDivElement;
    if (!date.classList.contains('highlight')) date.classList.add('highlight');
  }
};
