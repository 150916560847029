import React, { useContext, useState } from 'react';

interface LocalStorageProvider {
  read: (key: string) => string | null;
  write: (key: string, value: string) => void;
  getGraphViewKey: (userId: string) => string;
}

const LocalStorageContext = React.createContext<LocalStorageProvider>({
  read: () => null,
  write: () => {},
  getGraphViewKey: () => '',
});

export const LocalStorageProvider: React.FC = ({ children }) => {
  const [storageData, setStorageData] = useState<{ [key: string]: string }>({});

  const read = (key: string) => {
    if (!storageData[key]) {
      storageData[key] = localStorage.getItem(key) ?? '';
      setStorageData(storageData);
    }
    return storageData[key];
  };

  const write = (key: string, value: string) => {
    setStorageData({ ...storageData, [key]: value });
    localStorage.setItem(key, value);
  };

  const getGraphViewKey = (userId: string) => {
    return `graphView-${userId}`;
  };

  return (
    <LocalStorageContext.Provider value={{ read, write, getGraphViewKey }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);
  return context;
};

export default useLocalStorage;
