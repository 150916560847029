import { PriceV3Api } from 'constants/api-fetch';

class AdjustmentController {
  apiHandle: PriceV3Api | null = null;
  /**
   *
   */
  constructor() {
    this.apiHandle = new PriceV3Api();
  }

  getAvailableProducts = async (params: PivotProductsRequestModel) => {
    var result = await this.apiHandle?.post(
      'compare/listpivotproducts',
      JSON.stringify(params)
    );

    return result as PivotProductsResponseModel;
  };

  getPivotData = async (params: PivotDataRequestModel) => {
    var result = await this.apiHandle?.post(
      'compare/pivot',
      JSON.stringify(params)
    );

    return result as PivotDataResponseModel;
  };

  overridePivotData = async (params: PivotOverrideRequestModel) => {
    var result = await this.apiHandle?.post(
      'compare/pivot/override',
      JSON.stringify(params)
    );
    return result as PivotDataResponseModel;
  };

  generatePivotData = async (params: PivotOverrideRequestModel) => {
    var result = await this.apiHandle?.post(
      'compare/pivot/exportNoUpload',
      JSON.stringify(params)
    );
    return result as PivotDataResponseModel;
  };

  toggleFacilityExport = async (facilityId: number, exportStatus: boolean) => {
    var result = await this.apiHandle?.put(
      `facility/export/toggle/${facilityId}/${exportStatus}`,
      JSON.stringify({})
    );
    return result as boolean | null;
  };

  getExportStatus = async (facilityId: number) => {
    var result = await this.apiHandle?.get(
      `facility/export/status/${facilityId}`
    );
    return result as boolean;
  };

  setBookingCapacity = async (data: BookingCapacityRequestModel) => {
    var result = await this.apiHandle?.post(
      'booking/setCapacity',
      JSON.stringify(data)
    );
    return result as BookingCapacityResponseModel;
  };
}

export default AdjustmentController;

export class PivotProductsRequestModel {
  facilityId: string = '';
}

export class BookingCapacityRequestModel {
  zoneId: number = 0;
  dateList: string[] = [];
  bookingCapacity: number = 0;
  startDate: string = '';
  endDate: string = '';
}
export class BookingCapacityResponseModel {
  driveup: number[] = [];
  booking: number[] = [];
  zoneId: number = 0;
}
export class PivotProductsResponseModel {
  products: ProductSpecifierModel[] = [];
  zoneProducts: { [key: number]: string[] } = {};
}

export class ProductSpecifierModel {
  friendlyName: string = '';
  productId: string = '';
  productName: string = '';
  zoneName: string = '';
}

export class PivotDataRequestModel {
  ProductId: string = '';
  StartDate: string = '';
  EndDate: string = '';
  FacilityId: string = '';
  LOS: number = 30;
}

export class PivotDataResponseModel {
  data: PivotDataModel[] = [];
  startDate: string = '';
  endDate: string = '';
  productId: string = '';
  targetId: string = '';
  sourceId: string = '';
}

export class PivotDataModel {
  date: string = '';
  day: number = 0;
  difference: number = 0;
  today: number = 0;
  yesterday: number = 0;
  incrementToday: number = 0;
  incrementYesterday: number = 0;
}

export class PivotOverrideRequestModel {
  dateOverridePoints: PivotOverrideCellDataModel[] = [];
  productId: string = '';
  facilityId: string = '';
  startDate: string = '';
  endDate: string = '';
  LOS: number = 30;
}

export class PivotOverrideCellDataModel {
  CompareDateId: string = ''; // '{Date}:{Day}', eg. '2020-11-01:4' ???
  Adjustment: number = 0;
  OverrideValue: number = 0;
}
