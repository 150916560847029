import moment from 'moment';
import React from 'react';
import { Bar, Cell, Legend, Line } from 'recharts';
import styled from 'styled-components';
import { GTransformer } from './defs';

import {
  findGraphLevel,
  firstToUpper,
  isSpecialDay,
  roundUpMax,
} from './GraphData';

const doWithNonNeg = <R,>(get: () => number, act: (v: number) => R) => {
  const val = get();
  if (val >= 0) return act(val);
  else return null;
};

export const revenueTransformer: GTransformer = (
  data,
  zoneSelected,
  ck,
  graphView
) => {
  const maxCurrent = data.data.reduce(
    (pre, cur) =>
      Math.max(
        pre,
        cur.segments.reduce((p, c) => Math.max(p, c.defRevenue.current), 0)
      ),
    0
  );

  const maxTotal = data.data.reduce(
    (pre, cur) =>
      Math.max(
        pre,
        Math.max(
          cur.tdAccRev,
          cur.segments.reduce(
            (p, c) => Math.max(p, c.defRevenue.accumulated),
            0
          )
        )
      ),
    0
  );

  const leftMaxViewValue = findGraphLevel(maxCurrent);
  const rightMaxViewValue = findGraphLevel(maxTotal);

  const chartData = (() => {
    return [
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'revenue_booking'),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                key={'boBar'}
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Booking revenue"
                stackId="a"
                fill="#8bcfce"
                stroke="#66b2b0"
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;

                  return (
                    <Cell
                      key={ent.odate}
                      fill={special ? '#8cbad1' : '#8bcfce'}
                      stroke={'#8bcfce'}
                    />
                  );
                })}
              </Bar>
            );
          }
          return (
            <Line
              key={'boLine'}
              yAxisId="left"
              type="monotone"
              dataKey={`series[${idx}]`}
              name="Booking revenue"
              stroke="#F7A379"
              dot={{ fill: '#F7A379' }}
              strokeWidth={2}
            >
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;

                return (
                  <Cell
                    key={ent.odate}
                    fill={special ? '#f77d79' : '#F7A379'}
                    stroke={'#F7A379'}
                  />
                );
              })}
            </Line>
          );
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__revenue_booking'
          ),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                key={'cboBar'}
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Compare Booking revenue"
                stackId="ca"
                fill="#8ACCC9"
                stroke="#6799AC"
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;

                  return (
                    <Cell
                      key={ent.odate}
                      fill={special ? '#d1e4ee' : '#4c9996'}
                      stroke="#c7e7e7"
                    />
                  );
                })}
              </Bar>
            );
          }
          return (
            <Line
              key={'cboLine'}
              yAxisId="left"
              type="monotone"
              dataKey={`series[${idx}]`}
              name="Compare Booking revenue"
              stroke="#eec8b2"
              dot={{ fill: '#eec8b2' }}
              strokeWidth={2}
            >
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;

                return (
                  <Cell
                    key={ent.odate}
                    fill={special ? '#eeb4b2' : '#eec8b2'}
                    stroke="#eec8b2"
                  />
                );
              })}
            </Line>
          );
        }
      ),

      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'revenue_driveup'),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                key={'duBar'}
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Driveup revenue"
                stackId="a"
                fill="#66b2b0"
                stroke="#66b2b0"
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;

                  let da: any = undefined;

                  let fill = '#66b2b0';
                  let sc = fill;
                  if (special) {
                    sc = fill = '#6594b2';
                  } else if (ent.isDemandPrediction) {
                    fill = '#b5d8d7';
                    da = 5;
                  }

                  return (
                    <Cell
                      key={ent.odate}
                      fill={fill}
                      fillOpacity={ent.isDemandPrediction ? 1.0 : undefined}
                      stroke={'#8bcfce'}
                      // strokeDasharray={da}
                      // strokeOpacity={0.7}
                      // stroke={sc}
                    />
                  );
                })}
              </Bar>
            );
          }
          return (
            <Line
              key={'duLine'}
              yAxisId="left"
              type="monotone"
              dataKey={`series[${idx}]`}
              name="Driveup revenue"
              stroke="#8957A0"
              dot={{ fill: '#8957A0' }}
              strokeWidth={2}
            >
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;

                let da: any = undefined;

                let fill = '#8957A0';
                let sc = fill;
                if (special) {
                  sc = fill = '#a05794';
                } else if (ent.isDemandPrediction) {
                  fill = '#8957A0';
                  da = 5;
                }

                return (
                  <Cell
                    key={ent.odate}
                    fill={fill}
                    fillOpacity={ent.isDemandPrediction ? 1.0 : undefined}
                    stroke={'#8957A0'}
                    // strokeDasharray={da}
                    // strokeOpacity={0.7}
                    // stroke={sc}
                  />
                );
              })}
            </Line>
          );
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__revenue_driveup'
          ),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                key={'cduBar'}
                yAxisId="left"
                dataKey={`series[${idx}]`}
                name="Comapare driveup revenue"
                stackId="ca"
                fill="#8ACCC9"
                stroke="#6799AC"
              >
                {data.data.map((ent, idx) => {
                  const special = isSpecialDay(
                    moment.utc(ent.odate).toDate(),
                    data
                  ).eventExists;

                  let da: any = undefined;

                  let fill = '#c7e7e7';
                  let sc = fill;
                  if (special) {
                    sc = fill = '#497a99';
                  } else if (ent.isDemandPrediction) {
                    fill = '#8fb8b6';
                    da = 5;
                  }

                  return (
                    <Cell
                      key={ent.odate}
                      fill={fill}
                      fillOpacity={ent.isDemandPrediction ? 1.0 : undefined}
                      stroke="#c7e7e7"
                      // strokeDasharray={da}
                      // strokeOpacity={0.7}
                      // stroke={sc}
                    />
                  );
                })}
              </Bar>
            );
          }
          return (
            <Line
              key={'cduLine'}
              yAxisId="left"
              type="monotone"
              dataKey={`series[${idx}]`}
              name="Compare driveup revenue"
              stroke="#d2a7e6"
              dot={{ fill: '#d2a7e6' }}
              strokeWidth={2}
            >
              {' '}
              {data.data.map((ent, idx) => {
                const special = isSpecialDay(
                  moment.utc(ent.odate).toDate(),
                  data
                ).eventExists;

                let da: any = undefined;

                let fill = '#d2a7e6';
                let sc = fill;
                if (special) {
                  sc = fill = '#e6a7ce';
                } else if (ent.isDemandPrediction) {
                  fill = '#d2a7e6';
                  da = 5;
                }

                return (
                  <Cell
                    key={ent.odate}
                    fill={fill}
                    fillOpacity={ent.isDemandPrediction ? 1.0 : undefined}
                    stroke="#d2a7e6"
                    // strokeDasharray={da}
                    // strokeOpacity={0.7}
                    // stroke={sc}
                  />
                );
              })}
            </Line>
          );
        }
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex((sid) => sid === 'electric_revenue_driveup'),
        (idx) => {
          if (graphView === 'bars') {
            return (
              <Bar
                key={'duBarElectric'}
                yAxisId="left"
                dataKey={`series[${data.seriesIDs.findIndex(
                  (sid) => sid === 'electric_revenue_driveup'
                )}]`}
                name="Driveup electric revenue"
                stackId={'a'}
                fill="rgb(201, 224, 136)"
                stroke="rgb(201, 224, 136)"
              ></Bar>
            );
          }
          return (
            <Line
              key={'duLineElectric'}
              yAxisId="left"
              type="monotone"
              dataKey={`series[${data.seriesIDs.findIndex(
                (sid) => sid === 'electric_revenue_driveup'
              )}]`}
              name="Driveup electric revenue"
              stroke="rgb(201, 224, 136)"
              dot={{ fill: 'rgb(201, 224, 136)' }}
              strokeWidth={2}
            ></Line>
          );
        }
      ),
      // data.seriesIDs.find((sid) => sid === 'electric_revenue_driveup') && (
      //   <Line
      //     name={'Driveup acc electric revenue'}
      //     key={'duAccLineElec'}
      //     yAxisId="right"
      //     type="monotone"
      //     dataKey={`seriesAcc[${data.seriesIDs.findIndex(
      //       (sid) => sid === 'electric_revenue_driveup'
      //     )}]`}
      //     dot={{ fill: '#ff00ff' }}
      //     stroke="#ff00ff"
      //     strokeWidth={2}
      //   ></Line>
      // ),

      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'revenue_driveup'),
        (idx) => (
          <Line
            name={`Driveup acc revenue`}
            key={'duAccLine'}
            yAxisId="right"
            type="monotone"
            dataKey={`seriesAcc[${idx}]`}
            stroke="#4E4898"
            dot={{ fill: '#4E4898' }}
            strokeWidth={2}
          ></Line>
        )
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__revenue_driveup'
          ),
        (idx) => (
          <Line
            key={'cdriveupAccLine'}
            name={`Compare Driveup acc revenue`}
            yAxisId="right"
            type="monotone"
            dataKey={`seriesAcc[${idx}]`}
            // dataKey={`segments[${data.segments.indexOf(
            //   'booking'
            // )}].defRevenue.accumulated`}
            stroke="#ada7e9"
            dot={{ fill: '#ada7e9' }}
            strokeWidth={2}
          ></Line>
        )
      ),
      doWithNonNeg(
        () => data.seriesIDs.findIndex((sid) => sid === 'revenue_booking'),
        (idx) => (
          <Line
            key={'bookingAccLine'}
            name={`Booking acc revenue`}
            yAxisId="right"
            type="monotone"
            dataKey={`seriesAcc[${idx}]`}
            // dataKey={`segments[${data.segments.indexOf(
            //   'booking'
            // )}].defRevenue.accumulated`}
            stroke="#E17140"
            dot={{ fill: '#E17140' }}
            strokeWidth={2}
          ></Line>
        )
      ),
      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__revenue_booking'
          ),
        (idx) => (
          <Line
            key={'cbookingAccLine'}
            name={`Compare Booking acc revenue`}
            yAxisId="right"
            type="monotone"
            dataKey={`seriesAcc[${idx}]`}
            // dataKey={`segments[${data.segments.indexOf(
            //   'booking'
            // )}].defRevenue.accumulated`}
            stroke="#e2bcab"
            dot={{ fill: '#e2bcab' }}
            strokeWidth={2}
          ></Line>
        )
      ),

      data.driveups && data.bookings && (
        <Line
          key={'totalAccLine'}
          name={`Total acc revenue`}
          yAxisId="right"
          type="monotone"
          dataKey=""
          stroke="#3c4564"
          dot={{ fill: '#3c4564' }}
          strokeWidth={2}
        ></Line>
      ),

      doWithNonNeg(
        () =>
          data.seriesIDs.findIndex(
            (sid) => sid === '__compare__revenue_driveup'
          ),
        (idx) => {
          if (data.driveups && data.bookings) {
            return (
              <Line
                key={'totalOldAccLine'}
                name={`Compare Total acc revenue`}
                yAxisId="right"
                type="monotone"
                dataKey="comAccRev"
                stroke="#3c4564"
                dot={{ fill: '#3c4564' }}
                strokeWidth={2}
              ></Line>
            );
          }
          return null;
        }
      ),
    ];
  })() as (false | JSX.Element | undefined)[];

  return { chartData, leftMaxViewValue, rightMaxViewValue };
};
