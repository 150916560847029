export const UISTORE = {
  CHANGE_ACTIVE_ZONE: 'uistore/CHANGE_ACTIVE_ZONE',
  SET_ALL_SELECTED: 'uistore/SET_ALL_SELECTED',
  DATEMOVE: 'uistore/DATEMOVE',
  CHANGE_START_DATE: 'uistore/CHANGE_START_DATE',
  CHANGE_END_DATE: 'uistore/CHANGE_END_DATE',
  CHANGE_DURATION: 'uistore/CHANGE_DURATION',
  CHANGE_SEGMENT: 'uistore/CHANGE_SEGMENT',
  CHANGE_PTYPE: 'uistore/CHANGE_PTYPE',
  CHANGE_PVIEW_ID: 'uistore/CHANGE_PVIEW_ID',
  SET_SPECIAL_SELECT: 'uistore/SET_SPECIAL_SELECT',
  CHANGE_REVVIEW: 'uistore/CHANGE_REVVIEW',
  SET_ENABLE_COMPARE: 'uistore/SET_ENABLE_COMPARE',
  SET_COMPARE_DATE: 'uistore/SET_COMPARE_DATE',
  SET_COMPARE_END_DATE: 'uistore/SET_COMPARE_END_DATE',
  CLEAR_COMPARE_DATE: 'uistore/CLEAR_COMPARE_DATE',
  UPDATE_TS: 'uistore/UPDATE_TS',
  UPDATE_COMP_TS: 'uistore/UPDATE_COMP_TS',
  CHANGE_ACTIVE_FACILITY: 'uistore/CHANGE_ACTIVE_FACILITY',
  CHANGE_ACTIVE_FACILITY_COMPLETE: 'uistore/CHANGE_ACTIVE_FACILITY_COMPLETE',
  FAILED_TO_FETCH: 'uistore/FAILED_TO_FETCH',
  RESET_FETCH: 'uistore/RESET_FETCH',
} as const;
