import Modal from 'components/Modal';
import { CardType } from 'components/Seasons';
import { PriceV3Api } from 'constants/api-fetch';
import PriceProductEditor from 'containers/PriceProductEditor';
import usePricingEditorReducer from 'containers/PriceProductEditor/usePricingEditorState';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { zoneStoreApply } from 'store/zone/action';
import { PriceSchedule, Pricing, PricingRange, Zone } from 'store/zone/reducer';
import { selectCurrentZones } from 'store/zone/selector';
import { v4 as uuidv4 } from 'uuid';

const makePricingRange = (
  id: number,
  duration: PricingRange['duration'],
  segment: PricingRange['segment']
): PricingRange => ({
  id,
  active: false,
  toggleable: true,
  duration,
  max: 0,
  min: 0,
  segment,
  activationRangeId: null,
});

interface Props {
  zone: Zone;
  parentSchedule: PriceSchedule;
  onAddPricing(pricing: Pricing): void;
  onAddSchedule(schedule: PriceSchedule): void;
}

const AddPriceProductModal: FC<Props> = ({
  zone,
  parentSchedule,
  onAddPricing,
  onAddSchedule,
}) => {
  // assumes that this modal can't be opened if a zone isn't selected
  // const zones = useSelector(selectCurrentZones)!;
  // if (zones.length !== 1) throw new Error('More than one zone selected');
  // const zone = zones[0];

  let templatePricing: Pricing;
  if (parentSchedule) {
    templatePricing = [...parentSchedule.pricings]
      .sort((a, b) => {
        var ad = a.startDate ?? '1970-02-02';
        var bd = b.startDate ?? '1970-02-02';
        return bd > ad ? 1 : bd < ad ? -1 : 0;
      })
      .slice(-1)[0];
  } else {
    templatePricing =
      zone.pricings.find((zp) => zp.id === zone.selectedPricing) ??
      zone.pricings[0];
  }

  // remap will try to make negative-index id's that we don't expect.
  const remap = <T extends unknown>(id: T) =>
    id === null || id === undefined ? id : -1 - Math.abs(id as number);

  const priceProductEditorReducer = usePricingEditorReducer({
    name: 'Copy of ' + templatePricing.name,
    aggressivity: 3,
    id: 0,
    parentZoneId: zone.id,
    selected: zone.pricings.length === 0,
    parentScheduleGID: parentSchedule.gid,
    startDate: templatePricing.startDate,
    endDate: templatePricing.endDate,
    prices: templatePricing.prices.map((tp) => ({
      ...tp,
      id: remap(tp.id),
      groupId: tp.groupId ? remap(tp.groupId) : tp.groupId,
    })),
    userOpts: templatePricing.userOpts,
    // prices: [
    //   makePricingRange(-1, 'day', 'driveup'),
    //   makePricingRange(-2, 'hour', 'driveup'),
    //   makePricingRange(-3, 'week', 'driveup'),
    //   makePricingRange(-4, 'day', 'booking'),
    //   makePricingRange(-5, 'hour', 'booking'),
    //   makePricingRange(-6, 'week', 'booking'),
    // ],
    priceGroups: templatePricing.priceGroups.map((tp) => ({
      ...tp,
      id: remap(tp.id),
      pricingId: 0,
    })),
    priceDiscounts: templatePricing.priceDiscounts.map((tp) => ({
      ...tp,
      id: remap(tp.id),
      pricingId: 0,
    })),
  });

  const dispatch = useDispatch();

  return (
    <Modal
      title="New price product"
      buttons={[
        {
          disabled: !(
            priceProductEditorReducer[0].name.length > 0 &&
            priceProductEditorReducer[0].prices.find(
              (pricing) => pricing.active
            )
          ),
          label: 'Add',
          onClick: () => {
            var api = new PriceV3Api();
            if (parentSchedule) {
              api
                .post(
                  'create/scheduledpricing',
                  JSON.stringify(priceProductEditorReducer[0])
                )
                .then((schedule: PriceSchedule) => {
                  onAddSchedule(schedule);
                });
            } else {
              api
                .post(
                  'create/pricing',
                  JSON.stringify(priceProductEditorReducer[0])
                )
                .then((pricing: Pricing) => {
                  onAddPricing(pricing);

                  if (pricing.selected) {
                    dispatch(
                      zoneStoreApply(zone.id, {
                        selectedPricing: pricing.id,
                      })
                    );
                  }
                });
            }
          },
        },
      ]}
    >
      <PriceProductEditor
        zone={zone}
        priceProductEditorReducer={priceProductEditorReducer}
        cardType={CardType.OVERRIDE}
      />
    </Modal>
  );
};

export default AddPriceProductModal;
