import { AlertNotificationType, NotificationModel } from 'api/notifications';
import moment from 'moment';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  notification: NotificationModel;
}

const Wrapper = styled.div`
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  position: relative;
  background: white;
  width: 100%;
  font-size: 12px;
`;

const AlterTypeIndicatior = styled.div<{ type: AlertNotificationType }>`
  width: 5px;
  padding: 3px;
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 5px;

  ${({ type }) => {
    if (type === AlertNotificationType.PRICE_ALERT) {
      return css`
        background-color: ${({ theme }) => theme.palette.common.orange};
      `;
    }
    if (type === AlertNotificationType.ERROR) {
      return css`
        background-color: red;
      `;
    }
    return css`
      background-color: #2ca09c;
    `;
  }}
`;

const NotificationContent = styled.div`
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const NotificationTitle = styled.div`
  font-weight: bold;
`;

const NotificationMessage = styled.div`
  margin: 5px 0;
`;

const NotificationDate = styled.div`
  align-self: flex-end;
  font-style: italic;
  font-size: 10px;
`;

const DateWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const NewNotification = styled.span`
  font-style: italic;
  color: red;
  font-weight: bold;
`;

const NotificationItem: React.FC<Props> = (props) => {
  const { notification } = props;
  const [read, _] = useState<Date | undefined>(notification.read);

  return (
    <Wrapper>
      <AlterTypeIndicatior type={notification.notificationType} />
      <NotificationContent>
        <DateWrapper>
          <NotificationDate>
            {`${moment(notification.created).format('yyyy-MM-DD HH:mm:ss')}`}{' '}
          </NotificationDate>
        </DateWrapper>
        <NotificationTitle>
          {notification.title}
          {!read && <NewNotification> - New</NewNotification>}
        </NotificationTitle>
        <NotificationMessage>{notification.message}</NotificationMessage>
      </NotificationContent>
    </Wrapper>
  );
};

export default NotificationItem;
