import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import MultiSelect from '../../../components/input/MultiSelect';
import MultiGridSelect from '../../../components/input/MultiGridSelect';
import { Facilities, Role, User, UserAdminContext } from './userAdminContext';
import Input from '../../../components/input/Input';
import Label from '../../../components/input/Label';
import Button, { ButtonColors } from '../../../components/input/Button';
import Select from 'components/input/Select';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import {
  selectUISettingSites,
  selectUISettings,
} from 'store/uiSettings/selectors';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  margin-bottom: 40px;
  height: 400px;
  width: 450px;
  margin: auto;
  background: white;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 5px;

  label {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const StatusWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StatusContent = styled.div<{ success: boolean }>`
  text-align: center;
  ${(props) =>
    !props.success &&
    css`
      color: red;
    `}
`;

const SuccessWrapper = styled.div`
  height: 60px;
  width: 60px;
  color: white;
  font-size: 30px;
  border-radius: 50%;
  background: ${(props) => props.theme.palette.primary.bg};
  color: ${(props) => props.theme.palette.primary.fg};
  margin: auto;
  line-height: 1.7;
`;

const ErrorWrapper = styled(SuccessWrapper)`
  background: red;
  font-size: 40px;
  line-height: 1.2;
`;

const StatusMessage = styled.div`
  margin-top: 20px;
`;

interface Props {
  isEdit: boolean;
  roles: Role[];
  facilities: Facilities[];
  user?: User;
  onClose: () => void;
  onUpdate?: (user: User, callback: (resp: any) => void) => void;
  onCreate?: (user: Omit<User, 'id'>, callback: (resp: any) => void) => void;
}

type AddProps = Omit<Props, 'isEdit' | 'user'>;
interface EditProps extends Omit<Props, 'isEdit'> {
  user: User;
}

const UserPopup: React.FC<Props> = (props) => {
  if (props.isEdit) {
    return (
      <EditUserPopup
        user={props.user!}
        roles={props.roles}
        facilities={props.facilities}
        onClose={props.onClose}
        onUpdate={props.onUpdate}
      />
    );
  }

  return (
    <AddUserPopup
      roles={props.roles}
      facilities={props.facilities}
      onClose={props.onClose}
      onCreate={props.onCreate!}
    />
  );
};

export default UserPopup;

const gridSelectKeyRE = /(?<acc>\S+)\s+(?<site>\d+)/;

const facilitiesToSelectKeys = (facilities: Facilities[]) =>
  facilities.map(
    (uf) =>
      (uf.access === 'site/2park' ? 'site/write' : uf.access) + ' ' + uf.id
  );
const facilitiesToAvailable = (
  facilities: Facilities[],
  isAdmin: (id: number) => boolean,
  isVisible: (id: number) => boolean
) =>
  facilities
    //    .filter((fc) => isAdmin(fc.id))
    .filter((fc) => isVisible(fc.id))
    .map(
      (fc) => (
        console.log(fc),
        {
          key: fc.id.toString(),
          text: fc.name,
          disabled: !isAdmin(fc.id),
          options: [
            {
              key: 'site/read ' + fc.id,
              shortCode: 'R',
              title: 'Read',
              hintText: 'Read access',
              //disabled: !isAdmin(fc.id),
            },
            {
              key: 'site/write ' + fc.id,
              shortCode: 'W',
              title: 'Write',
              hintText: 'Write access',
              //disabled: !isAdmin(fc.id),
            },
            {
              key: 'site/admin ' + fc.id,
              shortCode: 'A',
              title: 'Admin',
              hintText: 'Admin access',
              //disabled: !isAdmin(fc.id),
            },
          ],
        }
      )
    );
const toggleFacilities = (key: string, facilities: Facilities[]) => {
  console.log('Toggle code!');
  var reRES = gridSelectKeyRE.exec(key)!;
  var access = reRES.groups!.acc;
  var site = parseInt(reRES.groups!.site);

  var existing = facilities.find((fac) => fac.id === site);
  return (
    // Remove case
    existing && access === existing!.access
      ? facilities.filter((euf) => euf !== existing)
      : // modify case
      existing
      ? facilities.map((euf) =>
          euf === existing ? { ...existing, access } : euf
        )
      : // add case
        [
          ...facilities,
          {
            ...facilities.find((f) => f.id === site)!,
            id: site,
            access,
          },
        ]
  );
};
const EditUserPopup: React.FC<EditProps> = ({
  user,
  facilities,
  roles,
  onClose,
  onUpdate,
}) => {
  const [editUser, setEditUser] = useState<User>(user);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [message, setMessage] = useState<string>('');
  const uiSettings = useSelector(selectUISettings);

  return (
    <Wrapper>
      {message === '' && success === undefined && (
        <>
          <h3>Edit user</h3>
          <Label label={'Email'}>
            <Input value={user.email} />
          </Label>
          <Label label={'Facilities'}>
            <MultiGridSelect
              selected={facilitiesToSelectKeys(editUser.facilities)}
              onToggle={(key) => {
                setEditUser({
                  ...editUser,
                  facilities: toggleFacilities(key, editUser.facilities),
                });
              }}
              available={facilitiesToAvailable(
                facilities,
                (id) =>
                  uiSettings.isDev ||
                  !!uiSettings.siteAdmin.find((saId) => {
                    console.log(`${saId} : ${id}`);
                    return saId === id;
                  }),
                (id) =>
                  uiSettings.isDev ||
                  !!uiSettings.user.facilities.find((f) => f.id === id)
              )}
            />
          </Label>
          <Label label={'Organization'}>
            <Select
              disabled={!uiSettings?.isDev}
              onChange={(e) => {
                setEditUser({
                  ...editUser,
                  roles: [
                    ...user.roles.filter((r) => !r.name.startsWith('ORG/')),
                    roles.find((f) => f.id === e.target.value) as Role,
                  ],
                });
              }}
            >
              {roles
                .filter((f) => f.name.startsWith('ORG/'))
                .map((f) => (
                  <option
                    selected={!!user.roles.find((x) => x.id === f.id)}
                    value={f.id}
                  >
                    {f.name.substring(4)}
                  </option>
                ))}
            </Select>
          </Label>
          <ButtonWrapper>
            <Button
              onClick={() => {
                onUpdate!(editUser, (resp) => {
                  setSuccess(resp.success);
                  setMessage(resp.message);
                });
              }}
            >
              Update user
            </Button>
            <Button color={ButtonColors.secondary} onClick={() => onClose()}>
              Close
            </Button>
          </ButtonWrapper>
        </>
      )}
      {message !== '' && success !== undefined && (
        <>
          <StatusWrapper>
            <StatusContent success={success}>
              {success && (
                <SuccessWrapper>
                  <FaCheck />
                </SuccessWrapper>
              )}
              {!success && (
                <ErrorWrapper>
                  <IoClose />
                </ErrorWrapper>
              )}
              <StatusMessage>{message}</StatusMessage>
            </StatusContent>
            <ButtonWrapper>
              <Button color={ButtonColors.secondary} onClick={() => onClose()}>
                Close
              </Button>
            </ButtonWrapper>
          </StatusWrapper>
        </>
      )}
    </Wrapper>
  );
};

const AddUserPopup: React.FC<AddProps> = ({
  facilities,
  roles,
  onClose,
  onCreate,
}) => {
  const uiSettings = useSelector(selectUISettings);
  const [user, setUser] = useState<User>(
    new User({ roles: [roles.find((tr) => tr.name === uiSettings.userOrg)] })
  );
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [message, setMessage] = useState<string>('');

  return (
    <Wrapper>
      {message === '' && success == undefined && (
        <>
          <h3>Add user</h3>
          <Label label={'Email'}>
            <Input
              value={user.email}
              onChange={(e: any) => {
                setUser({
                  ...user,
                  email: e.target.value,
                });
              }}
            />
          </Label>
          <Label label={'Facilities'}>
            <MultiGridSelect
              selected={facilitiesToSelectKeys(user.facilities)}
              onToggle={(key) => {
                setUser({
                  ...user,
                  facilities: toggleFacilities(key, user.facilities),
                });
              }}
              available={facilitiesToAvailable(
                facilities,
                (id) =>
                  uiSettings.isDev ||
                  !!uiSettings.siteAdmin.find((saId) => saId === id),
                (id) =>
                  uiSettings.isDev ||
                  !!uiSettings.user.facilities.find((f) => f.id === id)
              )}
            />
          </Label>
          <Label label={'Role'}>
            <Select
              disabled={!uiSettings?.isDev}
              onChange={(e) => {
                setUser({
                  ...user,
                  roles: [
                    ...user.roles.filter((r) => !r.name.startsWith('ORG/')),
                    roles.find((f) => f.id === e.target.value) as Role,
                  ],
                });

                // var role = roles.find((f) => f.id === e.target.value)!;
                // setUser({
                //   ...user,
                //   roles: !!role ? [role] : [],
                // });
              }}
            >
              {user.roles.length == 0 && (
                <option selected={true} value={0}>
                  Choose role...
                </option>
              )}
              {
                roles
                  .filter((f) => f.name.startsWith('ORG/'))
                  .map((f) => (
                    <option
                      selected={!!user.roles.find((x) => x.id === f.id)}
                      value={f.id}
                    >
                      {f.name.substring(4)}
                    </option>
                  ))
                // roles.map((f) => (
                //   <option
                //     selected={!!user.roles.find((x) => x.id === f.id)}
                //     value={f.id}
                //   >
                //     {f.name}
                //   </option>
                // ))
              }
            </Select>
          </Label>
          <ButtonWrapper>
            <Button
              onClick={() => {
                if (user.roles.length > 0) {
                  onCreate!(user, (resp) => {
                    setSuccess(resp.success);
                    setMessage(resp.message);
                  });
                } else {
                  alert('You must selected a role');
                }
              }}
            >
              Create user
            </Button>
            <Button color={ButtonColors.secondary} onClick={() => onClose()}>
              Close
            </Button>
          </ButtonWrapper>
        </>
      )}
      {message !== '' && success !== undefined && (
        <>
          <StatusWrapper>
            <StatusContent success={success}>
              {success && (
                <SuccessWrapper>
                  <FaCheck />
                </SuccessWrapper>
              )}
              {!success && (
                <ErrorWrapper>
                  <IoClose />
                </ErrorWrapper>
              )}
              <StatusMessage>{message}</StatusMessage>
            </StatusContent>
            <ButtonWrapper>
              <Button color={ButtonColors.secondary} onClick={() => onClose()}>
                Close
              </Button>
            </ButtonWrapper>
          </StatusWrapper>
        </>
      )}
    </Wrapper>
  );
};

export const SuccessPopup: React.FC<{
  message: string;
  onClose: () => void;
}> = ({ message, onClose }) => {
  return (
    <Wrapper>
      <StatusWrapper>
        <StatusContent success={true}>
          <SuccessWrapper>
            <FaCheck />
          </SuccessWrapper>
          <StatusMessage>{message}</StatusMessage>
        </StatusContent>
        <ButtonWrapper>
          <Button color={ButtonColors.secondary} onClick={() => onClose()}>
            Close
          </Button>
        </ButtonWrapper>
      </StatusWrapper>
    </Wrapper>
  );
};

export const ErrorPopup: React.FC<{ message: string; onClose: () => void }> = ({
  message,
  onClose,
}) => {
  return (
    <Wrapper>
      <StatusWrapper>
        <StatusContent success={false}>
          <ErrorWrapper>
            <IoClose />
          </ErrorWrapper>
          <StatusMessage>{message}</StatusMessage>
        </StatusContent>
        <ButtonWrapper>
          <Button color={ButtonColors.secondary} onClick={() => onClose()}>
            Close
          </Button>
        </ButtonWrapper>
      </StatusWrapper>
    </Wrapper>
  );
};

export const ConfirmRemoveUser: React.FC<{
  email: string;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ email, onSuccess, onClose }) => {
  return (
    <Wrapper>
      <StatusWrapper>
        <StatusContent success={true}>
          <StatusMessage>
            Are you sure you wish to remove the user:
          </StatusMessage>
          <StatusMessage>
            <b>{email}</b>
          </StatusMessage>
        </StatusContent>
        <ButtonWrapper>
          <Button onClick={() => onSuccess()}>Yes</Button>
          <Button color={ButtonColors.secondary} onClick={() => onClose()}>
            No
          </Button>
        </ButtonWrapper>
      </StatusWrapper>
    </Wrapper>
  );
};
