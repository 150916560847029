import SeasonController, { CreateScheduleModel } from 'api/seasons';
import Modal from 'components/Modal';
import useModalStack from 'components/Modal/useModalStack';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import Select from 'components/input/Select';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule } from 'store/zone/action';
import {
  selectAvailableFacilityZones,
  selectCurrentZones,
} from 'store/zone/selector';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  width: 100%;
  gap: 20px;
`;

interface Props {}

const AddZoneScheduleModal: React.FC<Props> = ({}) => {
  const [selectedSchedule, setSelectedSchedule] = useState<string>('');
  const [nameInput, setNameInput] = useState<string>('');
  const zones = useSelector(selectAvailableFacilityZones);
  const selZones = useSelector(selectCurrentZones);
  var dispatch = useDispatch();
  var modalStack = useModalStack();

  var _seasonController = new SeasonController();

  const handleCopyClick = async () => {
    const requestOptions = {
      zoneId: selZones[0].id,
      scheduleGid: selectedSchedule,
      name: nameInput,
    } as CreateScheduleModel;
    var result = await _seasonController.createSchedule(requestOptions);

    dispatch(updateSchedule(result));
    modalStack.pop();
    console.log('Send BE-request!');
    console.log('With: ', requestOptions);
  };

  useEffect(() => {
    if (selectedSchedule !== '') {
      let defaultName = '';
      zones.map((zone) => {
        if (!defaultName) {
          const found = zone.schedules.find(
            (schedule) => schedule.gid === selectedSchedule
          )?.name;
          if (found) defaultName = found;
        }
      });
      setNameInput(`(Copy of ${defaultName ? defaultName : ''})`);
    }
  }, [selectedSchedule]);

  return (
    <Modal
      title={'Add Schedule'}
      buttons={[
        {
          label: 'Add',
          onClick: handleCopyClick,
          disabled: selectedSchedule === '',
        },
      ]}
    >
      <Content>
        <Label label="Copy a zone schedule from:">
          <Select
            onChange={(e) => {
              setSelectedSchedule(e.target.value);
            }}
            value={selectedSchedule}
          >
            <option value={''}>Select schedule...</option>
            {zones.map((zone) =>
              zone.schedules.map((schedule) => (
                <option key={`schedule-${schedule.gid}`} value={schedule.gid}>
                  {schedule.name}
                </option>
              ))
            )}
          </Select>
        </Label>
        {selectedSchedule !== '' && (
          <Label label="Name:">
            <Input
              type="text"
              value={nameInput}
              onChange={(e) => {
                setNameInput(e.target.value);
              }}
            />
          </Label>
        )}
      </Content>
    </Modal>
  );
};

export default AddZoneScheduleModal;
