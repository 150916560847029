import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatNumberWithSpaces } from './Graph/GraphData';

interface Props {
  value: number;
  diffValue?: number;
  unit?: string;
}

const Wrapper = styled.div<{ color: string }>`
  display: flex;
  margin-top: 10px;
  color: ${(props) => props.color};

  > span {
    padding-right: 5px;

    :after {
      content: '/';
      margin-left: 5px;
    }
  }
`;

const Num = styled.div`
  margin-left: 5px;
`;

const NegtiveConvertionIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 10px solid #e17141;
  margin-top: 1px;
`;
const PositiveConvertionIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 10px solid #2ca09c;
`;

const calculateColor = (value: number) => {};

const ForecastProjection: React.FC<Props> = (props) => {
  const { unit = '' } = props;
  const diffValue = props.diffValue;
  const [value, setValue] = useState(Math.abs(props.value));
  const [isIncrease, setIsIncrease] = useState(props.value > 0);

  useEffect(() => {
    setValue(Math.abs(props.value));
    setIsIncrease(props.value >= 0);
  }, [props.value]);

  return (
    <Wrapper
      color={
        props.value == 0 ? '#000000' : props.value >= 0 ? '#2ca09c ' : '#e17141'
      }
    >
      {!!diffValue && (
        <span>
          {isIncrease ? '+' : '-'}
          {formatNumberWithSpaces(Number(diffValue))}
          {unit}
        </span>
      )}
      {(props.value === 0 || isIncrease) && <PositiveConvertionIcon />}
      {(props.value === 0 || !isIncrease) && <NegtiveConvertionIcon />}
      <Num>{value}%</Num>
    </Wrapper>
  );
};

export default ForecastProjection;
