import React from 'react';
import { DataPoint } from 'components/Graph/GenericLineCharts';
import { FaFileExcel, FaDownload } from 'react-icons/fa';
import { utils, writeFile } from 'xlsx';
import _Button from 'components/input/Button';
import styled from 'styled-components';
import moment from 'moment';

export interface ExcelData {
  data: DataPoint[];
  extra?: { [key: string]: any };
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled(_Button)`
  position: relative;
  padding: 15px 17px;

  .down-arrow {
    position: absolute;
    right: 22px;
    bottom: 10px;
  }
`;

const exportToExcel = (name: string, data: ExcelData) => {
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(data.data);
  utils.book_append_sheet(workbook, worksheet, 'Data');
  if (!!data.extra && Object.keys(data.extra!).length !== 0) {
    const extraWorksheet = utils.json_to_sheet([data.extra]);
    utils.book_append_sheet(workbook, extraWorksheet, 'Extra data');
  }

  writeFile(workbook, `${name} ${moment().format('YYYY-MM-DD HH:mm')}.xlsx`);
};

const ExportButton: React.FC<{
  name: string;
  disabled?: boolean;
  data: ExcelData;
}> = ({ name, data, disabled = false }) => {
  return (
    <Wrapper>
      <Button
        disabled={disabled}
        onClick={() => {
          exportToExcel(name, data);
        }}
        title="Export to excel"
      >
        <FaDownload className="icon" size={20} />
      </Button>
    </Wrapper>
  );
};

export default ExportButton;
