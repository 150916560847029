import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import useModalStack from '../components/Modal/useModalStack';
import HiddenSaveModal from '../components/HiddenSaveModal';
import { addCharToNum } from 'util/range';
import { useDemandContext } from '../components/Graph/DemandContext';
import ForecastProjection from '../components/ForecastProjection';

// TODO: Quick Hack, move out graph data instead of using this
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 25px;
  text-align: center;
  line-height: 12px;
  font-size: 16px;
  font-weight: 700;
  min-width: 100px;
`;

const Num = styled.span`
  line-height: 25px;
  margin-top: 10px;
  font-size: 2em;
  font-weight: 300;
`;

function shortenRev(revenue: number) {
  // round to display 3 digits in total
  // const r = (n: number) => {
  //   if (n >= 100) {
  //     return Math.round(n);
  //   } else if (n >= 10) {
  //     return Math.round(n * 10) / 10;
  //   }
  //   return Math.round(n * 100) / 100;
  // };

  // if (revenue >= 1000000000000) {
  //   return `${r(revenue / 1000000000000)}T`;
  // } else if (revenue >= 1000000000) {
  //   return `${r(revenue / 1000000000)}B`;
  // } else if (revenue >= 1000000) {
  //   return `${r(revenue / 1000000)}M`;
  // } else if (revenue >= 1000) {
  //   return `${r(revenue / 1000)}K`;
  // }
  return addCharToNum(`${revenue}`, ',');
}

interface Props {}

const AccDemandSticker: FC<Props> = ({}) => {
  const modalStack = useModalStack();
  const accDemandContext = useDemandContext();
  console.log('Rendering AccDemandSticker');

  if (accDemandContext.demand.currentAcc <= 0) return null;
  //console.log(accDemandContext);
  return (
    <Wrapper
      onClick={(evt) => {
        if (evt.shiftKey) {
          modalStack.push(<HiddenSaveModal />);
        }
      }}
    >
      <span style={{ minWidth: '125px' }}>Demand forecast</span>
      <Num>{shortenRev(Math.floor(accDemandContext.demand.currentAcc))}</Num>
      {/* <ForecastProjection
        value={Math.round((accDemandContext.demand.diff - 1) * 100)}
      /> */}
    </Wrapper>
  );
};

export default AccDemandSticker;
