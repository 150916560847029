import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useModalStack from '../components/Modal/useModalStack';
import HiddenSaveModal from '../components/HiddenSaveModal';
import { addCharToNum } from 'util/range';
import ForecastProjection from 'components/ForecastProjection';
import { useRouteMatch } from 'react-router';
import { FacilityPageProps } from 'topdefinitions';
import { DataPoint } from 'components/Graph/GenericLineCharts';
import { GraphData } from 'components/Graph/defs';
import { useSelector } from 'react-redux';
import { selectCurrentFacility } from 'store/zone/selector';

// TODO: Quick Hack, move out graph data instead of using this
const AccRevContext = React.createContext({
  visibleDiff: false,
  oldAccRev: -1,
  accRev: -1,
  graphData: [] as DataPoint[],
  setVisibleDiff: ((v: boolean) => {}) as React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setOldAccRev: ((v: number) => {}) as React.Dispatch<
    React.SetStateAction<number>
  >,
  setAccRev: ((v: number) => {}) as React.Dispatch<
    React.SetStateAction<number>
  >,
  setGraphData: (v: GraphData) => {},
});
export const AccRevContextProvider: FC = ({ children }) => {
  const [accRev, setAccRev] = useState(-1);
  const [oldAccRev, setOldAccRev] = useState(-1);
  const [visibleDiff, setVisibleDiff] = useState(false);
  const match = useRouteMatch<FacilityPageProps['match']['params']>();
  const facilityId = match?.params?.facilityId;
  const [graphData, insetGraphData] = useState<DataPoint[]>([]);
  const setGraphData = (data: GraphData) => {
    var dataPoints = data.data.map((f) => f as any as DataPoint);
    data.seriesIDs.forEach((curr, index) => {
      data.data.forEach((x, dIndex) => {
        dataPoints[dIndex][curr] = x.series[index]!;
      });
    });
    insetGraphData(dataPoints);
  };

  useEffect(() => {
    setVisibleDiff(false);
    setAccRev(-1);
    setOldAccRev(-1);
  }, [facilityId]);

  return (
    <AccRevContext.Provider
      value={{
        accRev,
        setAccRev,
        oldAccRev,
        setOldAccRev,
        visibleDiff,
        setVisibleDiff,
        graphData,
        setGraphData,
      }}
    >
      {children}
    </AccRevContext.Provider>
  );
};
export const useAccRevContext = () => useContext(AccRevContext);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 25px;
  text-align: center;
  line-height: 12px;
  font-size: 16px;
  font-weight: 700;
  min-width: 80px;
`;

const Num = styled.span`
  line-height: 25px;
  margin-top: 10px;
  font-size: 2em;
  font-weight: 300;
`;

const PrevValue = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  margin-top: 10px;
  > div {
    padding-bottom: 3px;
  }

  ${Num} {
    font-size: 12px;
    font-style: italic;
    line-height: normal;
    font-weight: bold;
  }
`;

function shortenRev(revenue: number) {
  // round to display 3 digits in total
  // const r = (n: number) => {
  //   if (n >= 100) {
  //     return Math.round(n);
  //   } else if (n >= 10) {
  //     return Math.round(n * 10) / 10;
  //   }
  //   return Math.round(n * 100) / 100;
  // };

  // if (revenue >= 1000000000000) {
  //   return `${r(revenue / 1000000000000)}T`;
  // } else if (revenue >= 1000000000) {
  //   return `${r(revenue / 1000000000)}B`;
  // } else if (revenue >= 1000000) {
  //   return `${r(revenue / 1000000)}M`;
  // } else if (revenue >= 1000) {
  //   return `${r(revenue / 1000)}K`;
  // }
  return addCharToNum(`${revenue}`, ',');
}

interface Props {}

const AccRevenueSticker: FC<Props> = ({}) => {
  const modalStack = useModalStack();
  const site = useSelector(selectCurrentFacility);
  const { accRev, oldAccRev, visibleDiff } = useAccRevContext();

  const vatRate = site?.config?.grossIncludedTaxRatePercentage ?? -1000;

  if (accRev <= 0) return null;
  return (
    <Wrapper
      onClick={(evt) => {
        if (evt.shiftKey) {
          modalStack.push(<HiddenSaveModal />);
        }
      }}
    >
      <span style={{ minWidth: '125px' }}>Gross Revenue</span>{' '}
      <Num>{shortenRev(accRev)}</Num>
      {visibleDiff && (
        <ForecastProjection
          value={Math.round(100 * (accRev / oldAccRev - 1))}
        />
      )}
      {vatRate > -1000 && (
        <PrevValue>
          <div>Net revenue</div>
          <Num>{shortenRev(accRev / ((100 + vatRate) / 100))}</Num>
        </PrevValue>
      )}
    </Wrapper>
  );
};

export default AccRevenueSticker;
