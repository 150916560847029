import React from 'react';
import styled, { css } from 'styled-components';
import Card from './card';
import { Pricing, PricingRange, Zone } from 'store/zone/reducer';
import { DateObject } from 'components/ZoneSchema/contants';
import { BlockMode } from 'util/schedule';

export interface Schedule {
  id: string;
  from: Date;
  priceProduct: Pricing;
}

const CardsWrapper = styled.div<{ maxWidth?: number }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 100%;
  padding: 10px 0px;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
      width: ${maxWidth}px;
    `}
`;

const DateRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const CardList = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 19px;
  flex: 1;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 0px 10px;
  overflow: hidden;
`;

const PlaceholderCard = styled.div`
  border-radius: 13px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 250px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.common.lightBorder};
`;

export interface SeasonCard extends DateObject {
  mode: BlockMode;
  selected: boolean;
  zone: Zone;
  priceId: number;
  gid: string;
  sourceGid?: string;
}

export enum CardType {
  SEASON,
  OVERRIDE,
  MULTIPLE,
}

const SeasonCardList: React.FC<{
  maxWidth?: number;
  seasonCards: SeasonCard[];
  segment: PricingRange['segment'];
  handleBlockClick: (id: string) => void;
}> = ({ seasonCards, maxWidth, handleBlockClick, segment }) => {
  return (
    <>
      <CardsWrapper maxWidth={maxWidth}>
        <CardList>
          {seasonCards.length === 2 && seasonCards[0].selected && (
            <DateRowWrapper>
              <PlaceholderCard></PlaceholderCard>
            </DateRowWrapper>
          )}
          {seasonCards.map((item, index) => (
            <DateRowWrapper key={`${index}-${item.priceProduct.id}`}>
              <Card
                type={!!item.sourceGid ? CardType.SEASON : CardType.OVERRIDE}
                segment={segment}
                card={item}
                handleBlockClick={handleBlockClick}
              />
            </DateRowWrapper>
          ))}
          {seasonCards.length === 2 && seasonCards[1].selected && (
            <DateRowWrapper>
              <PlaceholderCard></PlaceholderCard>
            </DateRowWrapper>
          )}
        </CardList>
      </CardsWrapper>
    </>
  );
};

export default SeasonCardList;
