import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CellDataModel } from './utils';
import {
  OverrideContext,
  ProductsContext,
  TableDataContext,
} from './TableDataProvider';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/definitions';
import { PriceSchedule } from 'store/zone/reducer';

const useAdjustmentsData = () => {
  const activeZone = useRef<number | null>(null);
  const tableDataContext = useContext(TableDataContext);
  const overridesContext = useContext(OverrideContext);
  const productsContext = useContext(ProductsContext);
  const pricingProducts = useRef<string[]>([]);
  const schedule = useSelector(
    (state: ApplicationState) => state.uiSettings.schedules
  );

  if (activeZone.current !== null && activeZone.current !== 0) {
    console.log(schedule, activeZone);
    var scheduleData = schedule.filter((s) => s.dZoneID === activeZone.current);
    var liveSchedule = scheduleData.reduce((acc, curr) => {
      if (!curr.liveActivationTimeStamp) return acc;
      if (
        !acc.liveActivationTimeStamp ||
        curr.liveActivationTimeStamp > acc.liveActivationTimeStamp
      ) {
        acc = curr;
      }
      return acc;
    }, {} as PriceSchedule);
    console.log(
      'LIVE',
      liveSchedule!.priceActivations.map((f) => moment(f.fromDate).date())
    );
    pricingProducts.current = liveSchedule!.priceActivations.map((f) =>
      moment(f.fromDate).toISOString()
    );
  }

  const setActiveZone = (zone: number | null) => {
    activeZone.current = zone;
  };
  // Add new override to the list if it doesn't exist, else update the existing override
  const addOverride = (cellData: CellDataModel, overrideVal: number) => {
    const newDiff = overrideVal - cellData.today;
    console.log('nya diffen ', newDiff);

    let previousOverrides = Array.from(
      overridesContext.overrides[productsContext.selectedProduct] || []
    );

    let overridesToUpdate = previousOverrides?.filter(
      (override) =>
        override.date === cellData.date && override.day >= cellData.day
    );

    let adjustment =
      overridesContext.adjustments &&
      overridesContext.adjustments[productsContext.selectedProduct] &&
      overridesContext.adjustments[productsContext.selectedProduct].find(
        (f) => f.date == cellData.date && f.day == cellData.day
      );

    const _newAdjustmentValue = cellData.today + newDiff;
    if (adjustment) {
      adjustment.today = _newAdjustmentValue;
      adjustment.difference = _newAdjustmentValue - cellData.yesterday;
      adjustment.adjustment = newDiff;
    } else {
      adjustment = {
        ...cellData,
        today: _newAdjustmentValue,
        difference: _newAdjustmentValue - cellData.yesterday,
        adjustment: newDiff,
      };
    }
    var prevAdjustments = Array.from(
      overridesContext.adjustments[productsContext.selectedProduct] || []
    );

    overridesContext.setAdjustments((adjustments) => {
      const o = (overridesContext.adjustments[productsContext.selectedProduct] =
        [...prevAdjustments!, adjustment!]);

      const all = { ...adjustments, [productsContext.selectedProduct]: o };
      return all;
    });

    if (overridesToUpdate) {
      // Remove existing override
      previousOverrides = previousOverrides.filter(
        (override) =>
          !(override.date === cellData.date && override.day >= cellData.day)
      );
      // Update existing override
      overridesToUpdate = overridesToUpdate.map((cell) => {
        var tableCell = tableDataContext.tableData
          .get(cell.date)!
          .get(cell.day.toString())!;
        var cellDiff = cell.difference + newDiff - tableCell.difference;
        const newToday = tableCell.today + cellDiff;
        console.log(cell.today, tableCell.today, newToday, cellDiff, newDiff);
        return {
          ...cell,
          today: newToday,
          difference: newToday - cell.yesterday,
          adjustment: cellDiff,
        };
      });
    }

    // Build new overrides
    const newOverrides = [] as CellDataModel[];
    const dateRow = tableDataContext.tableData.get(cellData.date);
    if (dateRow) {
      dateRow.forEach((cell) => {
        if (cell.day >= cellData.day) {
          const override = overridesToUpdate?.find(
            (override) =>
              override.day === cell.day && override.date === cell.date
          );

          if (!!override) {
            console.log(override);
            newOverrides.push(override);
          } else {
            const newToday = cell.today + newDiff;
            newOverrides.push({
              ...cell,
              today: newToday,
              difference: newToday - cell.yesterday,
              adjustment: newDiff,
            });
          }
        }
      });
    }

    // Add new overrides to the list
    overridesContext.setOverrides((overrides) => {
      const o = (overrides[productsContext.selectedProduct] = [
        ...previousOverrides,
        ...newOverrides,
      ]);

      const all = { ...overrides, [productsContext.selectedProduct]: o };
      return all;
    });
  };

  const removeOverride = (cellData: CellDataModel) => {
    const overrideToReset = overridesContext.overrides[
      productsContext.selectedProduct
    ]?.find(
      (override) =>
        override.date === cellData.date && override.day === cellData.day
    );

    var adjustment = overridesContext.adjustments[
      productsContext.selectedProduct
    ]?.find((f) => f.date == cellData.date && f.day == cellData.day);

    if (adjustment) {
      overridesContext.setAdjustments((adjustments) => {
        const newAdjustments = { ...adjustments };
        newAdjustments[productsContext.selectedProduct] = newAdjustments[
          productsContext.selectedProduct
        ]?.filter(
          (override) =>
            !(override.date === cellData.date && override.day === cellData.day)
        );

        return newAdjustments;
      });
    }

    if (overrideToReset) {
      overridesContext.setOverrides((overrides) => {
        const newOverrides = { ...overrides };
        newOverrides[productsContext.selectedProduct] = newOverrides[
          productsContext.selectedProduct
        ]?.filter(
          (override) =>
            !(override.date === cellData.date && override.day === cellData.day)
        );

        return newOverrides;
      });
    }
  };

  const hasOverrides = () => {
    if (
      !!overridesContext.overrides[productsContext.selectedProduct] &&
      overridesContext.overrides[productsContext.selectedProduct].length
    )
      return true;
    else return false;
  };

  const resetOverrides = () => {
    overridesContext.setOverrides((overrides) => {
      const newOverrides = {
        ...overrides,
        [productsContext.selectedProduct]: [],
      };
      return newOverrides;
    });

    overridesContext.setAdjustments((adjustments) => {
      const newAdjustments = {
        ...adjustments,
        [productsContext.selectedProduct]: [],
      };
      return newAdjustments;
    });
    // Keep? Not sure if this is needed // TODO: Refactor to use overridesContext instead
    tableDataContext.setTableData((table) => {
      table.forEach((date) => {
        date.forEach((cell) => {
          cell.override = null;
        });
      });
      return table;
    });
  };

  return {
    tableData: tableDataContext,
    addOverride,
    removeOverride,
    hasOverrides,
    overrides: overridesContext,
    resetOverrides,
    setActiveZone,
    pricingProducts: pricingProducts.current,
  };
};

export default useAdjustmentsData;
