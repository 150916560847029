import { PriceV3Api } from 'constants/api-fetch';

export class GraphController {
  apiHandle: PriceV3Api | null = null;

  constructor() {
    this.apiHandle = new PriceV3Api();
  }

  public async getOccupancyAnalysis(data: PerformanceRequest) {
    var result = await this.apiHandle?.post(
      'graph/occupancy/analysis',
      JSON.stringify(data)
    );

    return result as OccupancyResponse;
  }

  public async getOccupancyForecase(data: PerformanceRequest) {
    var result = await this.apiHandle?.post(
      'graph/occupancy/forecast',
      JSON.stringify(data)
    );

    return result as OccupancyResponse;
  }

  public async getBookingPattern(data: BookingPatternRequest) {
    var result = await this.apiHandle?.post(
      'graph/bookingpattern',
      JSON.stringify(data)
    );

    return result as BookingPatternResponse;
  }

  public async getPredictionPerformance(
    data: PerformanceRequest,
    signal: AbortSignal
  ) {
    var result = await this.apiHandle?.post(
      'graph/performance',
      JSON.stringify(data),
      {},
      signal
    );

    return result as PerformanceResponse;
  }

  public async getPriceComparision(data: PriceComparisonRequest) {
    var result = await this.apiHandle?.post(
      'graph/pricecomparison',
      JSON.stringify(data)
    );

    return result as PriceComparisionResponse;
  }

  public async getPriceComparisionD2D(data: PriceComparisonRequest) {
    var result = await this.apiHandle?.post(
      'graph/pc-day-to-day',
      JSON.stringify(data)
    );

    return result as PriceComparisionResponse;
  }

  public async getBookingPeriod(data: BookingPeriodRequest) {
    var result = await this.apiHandle?.post(
      'graph/bookingperiod',
      JSON.stringify(data)
    );
    return result as BookingPatternResponse;
  }
}

export interface PerformanceRequest {
  ZoneIds: number[];
  StartDate: string;
  EndDate: string;
  CapacityDate: string;
  CapacityEndDate: string;
  Segments: number[];
}

export interface OccupancyResponse {
  data: {
    [key: string]: {
      [key: number]: number;
    };
  };
}

export interface PerformanceResponse {
  analysis: PerformanceEntityResponse;
  forecast: PerformanceEntityResponse;
  capacity: PerformanceDataResponse;
}

export interface PerformanceEntityResponse {
  revenue: PerformanceDataResponse;
  occupancy: PerformanceDataResponse;
}
export interface PerformanceDataResponse {
  accTotal: number;
  accDriveup: number;
  accBooking: number;
  total: { [key: number]: number };
  driveup: { [key: number]: number };
  booking: { [key: number]: number };
}
export interface BookingPatternRequest {
  ZoneIds: number[];
  StartDate: string;
  LOS: number;
}

export interface BookingPeriodRequest {
  ZoneIds: number[];
  StartDate: string;
  EndDate: string;
  VisualSpan: {
    min: number;
    max: number;
  };
  CallId: string;
}

export interface PriceComparisonRequest {
  ZoneIds: number[];
  StartDate: string;
  EndDate: string;
  LOS: number;
  FacilityId: string;
}
export interface BookingPatternResponse {
  data: {
    [guid: string]: { [key: string]: number };
  };
  total: number;
  callId: string;
}

export interface PriceComparisionResponse {
  data: {
    [guid: string]: {
      result: { [key: string]: number };
    };
  };
}
