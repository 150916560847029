import Modal from 'components/Modal';
import EventEditor, { activeClass } from 'containers/EventEditor';
import useEventEditorState from 'containers/EventEditor/useEventEditorState';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventStoreAddEvent } from '../../store/event/action';
import { selectCurrentFacility, selectCurrentZones } from 'store/zone/selector';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { PriceV3Api } from '../../constants/api-fetch';
import { Event } from '../../store/event/definitions';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import { getUser } from 'store/uiSettings/selectors';
import moment from 'moment';

const MyModal = styled(Modal)`
  position: relative;

  .${activeClass} {
    position: absolute;
    top: 3rem;
    right: 120px;
  }
`;

const AddEventModal: FC = () => {
  const dispatch = useDispatch();
  const path = useSelector(selectHistoryFacilityMatch) as any;
  const fac = useSelector(selectCurrentFacility);
  const zones = useSelector(selectCurrentZones);
  if (zones.length !== 1) throw new Error('Not 1 selected zone');
  const zone = zones[0];
  var user = useSelector(getUser);
  var routeId = user.facilities.find(
    (f) => f.route === path.params.facilityId
  )?.id;
  const editState = useEventEditorState({
    endDate: '',
    startDate: '',
    fullDay: true,
    kind: '',
    name: '',
    routeID: routeId!,
    timeOfDay: 'fullDay',
    isCustom: true,
    routeData: zone ? undefined : { active: true, zoneID: -1, customDemand: 0 },
    zoneData: zone
      ? [{ active: true, zoneID: zone.id, customDemand: 0 }]
      : undefined,
  });

  var api = new PriceV3Api();
  return (
    <MyModal
      title="Special Event"
      noPadding={true}
      buttons={[
        {
          disabled:
            isNaN(editState.startDate.valueOf()) ||
            isNaN(editState.endDate.valueOf()) ||
            editState.startDate.valueOf() > editState.endDate.valueOf() ||
            !editState.kind ||
            editState.name.length === 0,
          label: 'Add',
          onClick: () => {
            api
              .post(
                'create/event',
                JSON.stringify({
                  ...{ ...editState, rzData: undefined },
                  startDate: moment
                    .utc(editState.startDate)
                    .format('YYYY-MM-DD HH:mm'),
                  endDate: moment
                    .utc(editState.endDate)
                    .format('YYYY-MM-DD HH:mm'),
                  isCustom: true,
                  routeID: fac.id,
                  routeData: editState.rzData.routeData,
                  zoneData: editState.rzData.zoneData,
                } as Event)
              )
              .then((res: Event) => {
                dispatch(
                  eventStoreAddEvent(fac.id, {
                    //zoneId: res.zoneId,
                    //id: res.id,
                    //active: res.active,
                    dateID: res.dateID,
                    fullDay: editState.fullDay,
                    startDate: res.startDate, // editState.startDate.toISOString(),
                    endDate: res.endDate, //editState.endDate.toISOString(),
                    //demand: res.demand,
                    kind: res.kind,
                    name: res.name,
                    timeOfDay: res.timeOfDay,
                    isCustom: true,
                    routeID: res.routeID,
                    routeData: editState.rzData.routeData,
                    zoneData: editState.rzData.zoneData,
                  } as Event)
                );
              });
          },
        },
      ]}
    >
      <EventEditor
        eventEditorState={editState}
        fixedZones={false}
        fixedEvent={false}
      />
    </MyModal>
  );
};

export default AddEventModal;
