import React, { useEffect } from 'react';

//Using generic line charts component to display the data
import LineChart, {
  DataPoint,
  GraphTop,
  LeftContent,
  RenderCustomerXTick,
  RightContent,
} from 'components/Graph/GenericLineCharts';

import { GraphController, PriceComparisionResponse } from 'api/graph';
import Main from 'components/Main';
import styled from 'styled-components';
import Label from 'components/input/Label';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import moment from 'moment';
import Input from 'components/input/Input';
import {
  selectAvailableFacilityZones,
  selectAvailableZones,
  selectCurrentZones,
} from 'store/zone/selector';
import { useSelector } from 'react-redux';
import Select from 'components/input/Select';
import GraphLoader from 'components/Loader/graphLoader';
import Loader from 'components/Loader';
import { COMPARE_TYPE } from 'store/ui/uiutil';
import { start } from 'repl';
import { getDaysBetweenDates, getSameDayLastYear } from 'util/date';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import { Payload } from 'components/Graph/GraphBase';
import ExportButton from 'util/excel';
import { getColorHash } from 'pages/Occupancy';

const PriceComparison: React.FC = () => {
  var today = moment();
  var graphController = new GraphController();
  const routeInfo = useSelector(selectHistoryFacilityMatch);
  const [current, setCurrent] = React.useState<PriceComparisionResponse>();
  const [previous, setPrevious] = React.useState<PriceComparisionResponse>();
  const [startDate, setStartDate] = React.useState<moment.Moment>(today);
  const [endDate, setEndDate] = React.useState<moment.Moment>(
    moment(startDate).add(1, 'month')
  );
  const [view, setView] = React.useState<COMPARE_TYPE>(COMPARE_TYPE.PERIOD);
  const [compareDate, setCompareDate] = React.useState<moment.Moment>(
    getSameDayLastYear(startDate)
  );
  const activeZones = useSelector(selectCurrentZones);

  const [los, setLos] = React.useState<number>(8);
  const [zoneIds, setZoneIds] = React.useState<number[]>([]);
  const [showCompare, setShowCompare] = React.useState<boolean>(
    activeZones.length === 1
  );

  const zones = useSelector(selectAvailableZones);

  useEffect(() => {
    setZoneIds(activeZones.map((f) => f.id));
    setCurrent(undefined);
    setPrevious(undefined);
    if (activeZones.length === 1) {
      setShowCompare(true);
    } else {
      setShowCompare(false);
    }
  }, [activeZones]);

  useEffect(() => {
    if (zoneIds.length > 0) {
      fetchData(startDate, endDate, (result) => {
        setCurrent(result);
      });
    }
  }, [startDate, endDate, zoneIds, los, view]);

  useEffect(() => {
    if (zoneIds.length > 0 && showCompare) {
      var diffDays = getDaysBetweenDates(startDate.toDate(), endDate.toDate());
      fetchData(
        compareDate,
        moment(compareDate).add(diffDays, 'days'),
        (result) => {
          setPrevious(result);
        }
      );
    }
  }, [compareDate, endDate, zoneIds, los, view]);

  const fetchData = async (
    date: moment.Moment,
    endDate: moment.Moment,
    callback: (result: PriceComparisionResponse) => void
  ) => {
    const facilityId = routeInfo?.params.facilityId;
    if (view == COMPARE_TYPE.DAY_TO_DAY) {
      var result = await graphController.getPriceComparisionD2D({
        StartDate: date.format('YYYY-MM-DD'),
        EndDate: endDate.format('YYYY-MM-DD'),
        ZoneIds: zoneIds,
        LOS: los,
        FacilityId: facilityId!,
      });
      callback(result);
    } else {
      var result = await graphController.getPriceComparision({
        StartDate: date.format('YYYY-MM-DD'),
        EndDate: endDate.format('YYYY-MM-DD'),
        ZoneIds: zoneIds,
        LOS: los,
        FacilityId: facilityId!,
      });
      callback(result);
    }
  };

  useEffect(() => {
    setCompareDate(getSameDayLastYear(startDate));
  }, [startDate]);

  var maxPrice = 0;
  var graphData = [] as DataPoint[];
  zoneIds.forEach((zoneId) => {
    if (!!current && !!current.data[zoneId]) {
      var data = current!.data[zoneId];
      Object.keys(data.result).forEach((curr) => {
        var graphPoint = graphData.find((x) => x.LOS === curr);
        maxPrice = Math.max(data.result[curr], maxPrice);
        if (zoneIds.length > 1) {
          var key = `Current_${zoneId}` as keyof DataPoint;
          if (!graphPoint) {
            graphData.push({ LOS: curr, [key]: data.result[curr] });
          } else {
            graphPoint[key] = data.result[curr];
          }
        } else {
          if (!graphPoint) {
            graphData.push({ LOS: curr, Current: data.result[curr] });
          } else {
            graphPoint.Current = data.result[curr];
          }
        }
      });
    }

    if (!!previous && !!previous.data[zoneId] && showCompare) {
      var prevData = previous!.data[zoneId];

      Object.keys(prevData.result).forEach((curr, index) => {
        var graphPoint = graphData.find((x) => x.LOS === curr);
        maxPrice = Math.max(prevData.result[curr], maxPrice);

        if (zoneIds.length > 1) {
          var key = `Previous_${zoneId}` as keyof DataPoint;
          if (!graphPoint) {
            graphData.push({ LOS: curr, [key]: prevData.result[curr] });
          } else {
            graphPoint[key] = prevData.result[curr];
          }
        } else {
          if (!graphPoint) {
            graphData.push({ LOS: curr, Previous: prevData.result[curr] });
          } else {
            graphPoint.Previous = prevData.result[curr];
          }
        }
      });
    }
  });

  graphData = graphData
    .sort((a, b) => {
      return Number(a.LOS) - Number(b.LOS);
    })
    .map((x) => {
      return {
        ...x,
        LOS: x.LOS,
      };
    });

  maxPrice = Math.ceil(maxPrice / 100) * 100;

  var toolTip = {
    Current: {
      title: 'Price',
      color: 'green',
      formatter: (value: any) => {
        return `${value} kr`;
      },
    },
    Previous: {
      title: 'Price prev. period',
      color: 'red',
      formatter: (value: any) => {
        return `${value} kr`;
      },
    },
  } as {
    [key: string]: {
      title: string;
      color: string;
      formatter: (value: any) => any;
    };
  };

  var lines = [
    {
      key: 'Current',
      name: 'Price',
      color: 'green',
    },
    {
      key: 'Previous',
      name: 'Price prev. period',
      color: 'red',
    },
  ];

  if (zoneIds.length > 1 && graphData!!.length > 0) {
    toolTip = {};
    lines = [];
    Object.keys(graphData[0]).forEach((curr) => {
      if (curr != 'LOS') {
        var zoneSplit = curr.split('_');
        var zoneId = zoneSplit[1];
        var version = zoneSplit[0];
        var zone = zones.find((x) => x.id == Number(zoneId));
        toolTip[curr] = {
          title:
            version === 'Current'
              ? `Price ${zone?.name}`
              : `Price prev. period ${zone?.name}`,
          color: getColorHash(`${curr.split('_')[0]}_${zone?.gid}`),
          formatter: (value: any) => {
            return `${value} kr`;
          },
        };
        lines.push({
          key: curr,
          name:
            version === 'Current'
              ? `Price ${zone?.name}`
              : `Price prev. period ${zone?.name}`,
          color: getColorHash(`${curr.split('_')[0]}_${zone?.gid}`),
        });
      }
    });
  }

  return (
    <Main>
      <GraphTop>
        <LeftContent>
          <Label label="View">
            <Select
              onChange={(eve) => {
                var value = eve.target.value as any as COMPARE_TYPE;
                setView(value);
              }}
              value={view}
            >
              <option value={COMPARE_TYPE.PERIOD}>Period</option>
              <option value={COMPARE_TYPE.DAY_TO_DAY}>Day-to-Day</option>
            </Select>
          </Label>
          <Label label="From date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                console.log(date);
                var days = endDate.diff(startDate, 'days');
                setStartDate(moment(date));
                setEndDate(moment(date).add(days, 'days'));
              }}
              selected={startDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          {view != COMPARE_TYPE.DAY_TO_DAY && (
            <Label label="To date">
              <PTDatePicker
                dateFormat="yyyy-MM-dd"
                onChange={(date: Date) => {
                  setEndDate(moment(date));
                }}
                selected={endDate.toDate()}
                dropdownMode="select"
                selectsStart
                showMonthDropdown
                showYearDropdown
              />
            </Label>
          )}
          <input
            style={{ marginRight: '-7px', marginTop: '9px' }}
            type="checkbox"
            checked={showCompare}
            onChange={(evt) => {
              setShowCompare(!showCompare);
            }}
          />
          <Label label="Compare from date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              disabled={!showCompare}
              onChange={(date: Date) => {
                setCompareDate(moment(date));
              }}
              selected={compareDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          {view != COMPARE_TYPE.DAY_TO_DAY && (
            <Label label="Compare to date">
              <PTDatePicker
                dateFormat="yyyy-MM-dd"
                onChange={(date: Date) => {}}
                selected={moment(compareDate)
                  .add(
                    getDaysBetweenDates(startDate.toDate(), endDate.toDate()),
                    'days'
                  )
                  .toDate()}
                disabled={true}
                selectsStart
                showMonthDropdown
                showYearDropdown
              />
            </Label>
          )}
          <Label label="Length of Stay">
            <Input
              type="number"
              onChange={(eve) => {
                var value = Number(eve.target.value);
                setLos(value);
              }}
              value={los}
            />
          </Label>
        </LeftContent>
        <RightContent>
          <ExportButton
            name={`Price comparison`}
            data={{
              extra: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate:
                  view === COMPARE_TYPE.DAY_TO_DAY
                    ? ''
                    : endDate.format('YYYY-MM-DD'),
                compareStartDate: !showCompare
                  ? ''
                  : compareDate.format('YYYY-MM-DD'),
                compareEndDate:
                  !showCompare || view === COMPARE_TYPE.DAY_TO_DAY
                    ? ''
                    : moment(compareDate)
                        .add(
                          getDaysBetweenDates(
                            startDate.toDate(),
                            endDate.toDate()
                          ),
                          'days'
                        )
                        .format('YYYY-MM-DD'),
                ...zoneIds.map((f) => {
                  var zone = zones.find((x) => x.id == f);
                  return zone!.name;
                }),
              },
              data: graphData.map((x) => {
                if (zoneIds.length > 1) {
                  var obj = {} as any;
                  Object.keys(x).forEach((key) => {
                    if (key === 'LOS') {
                      if (view == COMPARE_TYPE.PERIOD) {
                        obj['Date'] = moment(startDate)
                          .add(x[key], 'days')
                          .format('YYYY-MM-DD');
                        if (showCompare) {
                          obj['CompareDate'] = moment(compareDate)
                            .add(x[key], 'days')
                            .format('YYYY-MM-DD');
                        }
                        obj['LOS'] = los;
                      } else {
                        obj['Date'] = moment(startDate).format('YYYY-MM-DD');
                        if (showCompare) {
                          obj['CompareDate'] =
                            moment(compareDate).format('YYYY-MM-DD');
                        }
                        obj['LOS'] = x[key];
                      }
                    } else {
                      var zoneSplit = key.split('_');
                      var zoneId = zoneSplit[1];
                      var version = zoneSplit[0];
                      var zone = zones.find((z) => z.id == Number(zoneId));
                      var currentKey = `${zone?.name}_${version}` as string;
                      obj[currentKey] = x[key];
                    }
                  });
                  return obj;
                } else {
                  var zone = zones.find((z) => z.id == zoneIds[0]);
                  var currentKey = `${zone?.name}_current` as string;
                  var prviousKey = `${zone?.name}_previous` as string;
                  var _data = {} as any;
                  if (view == COMPARE_TYPE.PERIOD) {
                    _data.Date = moment(startDate)
                      .add(x.LOS, 'days')
                      .format('YYYY-MM-DD');
                    if (showCompare) {
                      _data.CompareDate = moment(compareDate)
                        .add(x.LOS, 'days')
                        .format('YYYY-MM-DD');
                    }
                    _data.LOS = los;
                  } else if (!!obj) {
                    obj['Date'] = moment(startDate).format('YYYY-MM-DD');
                    if (showCompare) {
                      obj['CompareDate'] =
                        moment(compareDate).format('YYYY-MM-DD');
                    }
                    _data.LOS = x.LOS;
                  }
                  _data[currentKey] = x.Current;
                  _data[prviousKey] = x.Previous;
                  return _data;
                }
              }) as { [key: string]: string | number }[],
            }}
          />
        </RightContent>
      </GraphTop>
      <div style={{ position: 'relative' }}>
        <LineChart
          startDate={startDate}
          customerXTick={
            COMPARE_TYPE.DAY_TO_DAY === view
              ? undefined
              : (props) => {
                  return (
                    <RenderCustomerXTick
                      date={moment(startDate)
                        .startOf('days')
                        .add(Number(props.payload.value), 'days')}
                      props={props}
                    />
                  );
                }
          }
          data={graphData}
          maxYValue={maxPrice + 100}
          xTickFormatter={(value: any) => {
            var days = Number(value);
            if (view == COMPARE_TYPE.DAY_TO_DAY) {
              return `${days}`;
            }
            return moment(startDate).add(days, 'days').format('DD/MM');
          }}
          tooltip={{
            LOS: {
              title:
                view == COMPARE_TYPE.DAY_TO_DAY
                  ? 'Length of stay'
                  : 'Days from date',
              color: 'black',
              formatter: (value: any) => {
                if (view == COMPARE_TYPE.DAY_TO_DAY) {
                  return `${value} days`;
                }
                return [
                  `${value} days`,

                  <Payload color={'black'}>
                    Date:{' '}
                    {moment(startDate).add(value, 'days').format('YYYY-MM-DD')}
                  </Payload>,
                  <Payload color={'black'}>
                    Compare date:{' '}
                    {moment(compareDate)
                      .add(value, 'days')
                      .format('YYYY-MM-DD')}
                  </Payload>,
                ];
              },
            },
            ...toolTip,
          }}
          lines={lines}
          xDataKey="LOS"
        />
        {(!current || !current.data || graphData.length === 0) && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#ffffff',
              opacity: 0.35,
            }}
          >
            <Loader
              title={'Could not find data'}
              forceError={
                !!current && !!current.data && !!previous && !!previous.data
              }
            />
          </div>
        )}
      </div>
    </Main>
  );
};

export default PriceComparison;
