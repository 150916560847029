import { DateObject } from 'components/ZoneSchema/contants';

export enum BlockMode {
  FUTURE,
  PAST,
  PRESENT,
}

export const getBlockMode = (dateObj: DateObject): BlockMode => {
  var date = new Date();

  var isPast = date > dateObj.to;
  var isFuture = date < dateObj.from;
  var isPresent = false;
  if (!isPast && !isFuture) {
    isPresent = true;
  }

  const mode = isPresent
    ? BlockMode.PRESENT
    : isPast
    ? BlockMode.PAST
    : BlockMode.FUTURE;

  return mode;
};
