import { PriceV3Api } from 'constants/api-fetch';

class NotificationController {
  /**
   *
   */
  apiHandler: PriceV3Api | null = null;

  constructor() {
    this.apiHandler = new PriceV3Api();
  }

  popupCompleteNotifications = async (id: string, userId: string) => {
    var result = await this.apiHandler!.put(
      `notifications/popup/${userId}/${id}`,
      ''
    );
    return result as NotificationResponseModel;
  };

  readNotifications = async (id: string) => {
    var result = await this.apiHandler!.get(`notifications/${id}`);
    return result as NotificationResponseModel;
  };

  markAsRead = async (id: string) => {
    return (await this.apiHandler?.put(
      `notifications/read/${id}`,
      ''
    )) as boolean;
  };
}

export default NotificationController;

export enum AlertNotificationType {
  ERROR = 0,
  PRICE_ALERT = 1,
}

export class NotificationResponseModel {
  notifications: NotificationModel[] = [];
}

export class NotificationModel {
  id: string = '';
  title: string = '';
  message: string = '';
  created: Date = new Date();
  read?: Date;
  notificationType: AlertNotificationType = AlertNotificationType.PRICE_ALERT;
  userId: string = '';
  shouldPopup: boolean = false;
}
