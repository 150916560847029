import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { addCharToNum } from 'util/range';
import ForecastProjection from 'components/ForecastProjection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 12px;
  font-size: 20px;
  font-weight: 700;
  min-width: 200px;
`;

const Num = styled.span`
  line-height: 30px;
  margin-top: 20px;
  font-size: 2.5em;
  font-weight: 500;
`;

const PrevValue = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  margin-top: 20px;

  ${Num} {
    font-size: 12px;
    font-style: italic;
    line-height: normal;
    font-weight: bold;
  }
`;

function shorterNr(nr: number) {
  return addCharToNum(`${nr}`, ',');
}

export interface DashboardStickerProps {
  value: number;
  diffValue?: number;
  title: string;
  suffix?: string;
  className?: string;
  diffOverride?: number;
}

const DashboardSticker: FC<DashboardStickerProps> = ({
  value,
  diffValue,
  title,
  diffOverride,
  suffix = '',
}) => {
  return (
    <Wrapper>
      <span style={{ marginBottom: '15px' }}>{title}</span>
      <Num>
        {shorterNr(value)}
        {suffix}
      </Num>
      {diffValue != undefined && (
        <>
          <PrevValue>
            Compare period:{' '}
            <Num>
              {shorterNr(diffValue)}
              {suffix}
            </Num>
          </PrevValue>
          <DashboardForecastProjection
            value={
              !!diffOverride
                ? diffOverride
                : Number((100 * (value / diffValue - 1)).toFixed(1))
            }
          />
        </>
      )}
    </Wrapper>
  );
};

const NegtiveConvertionIcon = styled.div<{ color?: string }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 10px solid ${({ color }) => (!color ? '#e17141' : color)};
  margin-top: 1px;
`;
const PositiveConvertionIcon = styled.div<{ color?: string }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 10px solid ${({ color }) => (!color ? '#2ca09c' : color)};
`;

const calculateColor = (value: number) => {};

const PercentWrapper = styled.div<{ color: string }>`
  display: flex;
  margin-top: 20px;
  color: ${(props) => props.color};
`;

const PercentNum = styled.div`
  margin-left: 5px;
`;

const DashboardForecastProjection: React.FC<{ value: number }> = (props) => {
  const [value, setValue] = useState(Math.abs(props.value));
  const [isIncrease, setIsIncrease] = useState(props.value > 0);

  useEffect(() => {
    setValue(Math.abs(props.value));
    setIsIncrease(props.value >= 0);
  }, [props.value]);

  return (
    <PercentWrapper
      color={props.value == 0 ? '#000000' : props.value >= 0 ? 'green ' : 'red'}
    >
      {(props.value === 0 || isIncrease) && (
        <PositiveConvertionIcon color={'green'} />
      )}
      {(props.value === 0 || !isIncrease) && (
        <NegtiveConvertionIcon color={'red'} />
      )}
      <PercentNum>{value.toFixed(1)}%</PercentNum>
    </PercentWrapper>
  );
};

export default DashboardSticker;
