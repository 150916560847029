import { PriceV3Api } from 'constants/api-fetch';
import { PricingScheduleUpsertModel } from 'pages/Seasons/upsertSeasonModal';
import { PriceSchedule, PricingScheduleSpan } from 'store/zone/reducer';

class SeasonController {
  /**
   *
   */
  apiHandler: PriceV3Api | null = null;

  constructor() {
    this.apiHandler = new PriceV3Api();
  }

  getFacilitySeasons = async (facilitId: number) => {
    var result = await this.apiHandler!.get(`seasons/facility/${facilitId}`);
    return result as PricingScheduleSpan[];
  };

  createFacilitySeason = async (model: CreateSeasonModel) => {
    var result = await this.apiHandler!.post(
      `seasons/create`,
      JSON.stringify(model)
    );
    return result as PriceSchedule[];
  };

  updateFacilitySeason = async (season: PricingScheduleSpan) => {
    var result = await this.apiHandler!.post(
      `season/update`,
      JSON.stringify(season)
    );
    return result as PriceSchedule[];
  };

  deleteSeason = async (recurringId: string) => {
    var result = await this.apiHandler!.delete(`season/delete/${recurringId}`);
    return result as PriceSchedule[];
  };

  createSchedule = async (model: CreateScheduleModel) => {
    var result = await this.apiHandler!.post(
      `season/createSchedule`,
      JSON.stringify(model)
    );
    return result as PriceSchedule[];
  };
}

export interface CreateScheduleModel {
  zoneId: number;
  scheduleGid: string;
  name: string;
}
export interface CreateSeasonModel {
  facilityId: number;
  copySeasonRecurringGuid: string;
  season: Partial<PricingScheduleSpan>;
}

export default SeasonController;
