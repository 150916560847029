import React, { useEffect, useState } from 'react';
import { PriceSchedule, Zone } from 'store/zone/reducer';
import SubHeader from 'components/SubHeader';
import Main from 'components/Main';
import DateBlock from 'components/ZoneSchema';
import {
  DateObject,
  ZoneSchemaContainer,
} from 'components/ZoneSchema/contants';
import { useSelector } from 'react-redux';
import { selectCurrentZones } from 'store/zone/selector';
import Button, { ButtonColors } from 'components/input/Button';
import useModalStack from 'components/Modal/useModalStack';
import AddZoneScheduleModal from './AddZoneScheduleModal';
import { hasEditRights } from 'store/uiSettings/selectors';
import Toggle from 'components/input/Toggle';
import styled from 'styled-components';
import Label from 'components/input/Label';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const ToggleWrapper = styled.div`
  padding-top: 24px;
  gap: 10px;
  align-items: center;
  display: flex;
`;
const ToggleTitle = styled.div``;

const ScheduleComponent: React.FC = () => {
  const selZones = useSelector(selectCurrentZones);
  const [zones, setZones] = useState<Zone[]>(selZones);
  const [loading, setLoading] = useState(false);
  const canEdit = useSelector(hasEditRights);
  const modal = useModalStack();
  const [multipleEdit, setMultipleEdit] = useState(false);

  const [selectedSelectBlock, setSelectedSelectBlock] = useState<{
    [key: string]: string;
  }>(
    zones.reduce((acc, curr) => {
      var lsch = curr.schedules.reduce((_acc, _curr) => {
        if (!_curr.liveActivationTimeStamp) return _acc;
        console.log(curr);
        if (
          !_acc.liveActivationTimeStamp ||
          _curr.liveActivationTimeStamp > _acc.liveActivationTimeStamp
        ) {
          _acc = _curr;
        }
        return _acc;
      }, {} as PriceSchedule);
      acc[curr.gid] = lsch.gid;
      return acc;
    }, {} as { [key: string]: string })
  );

  let zoneSchedules = zones.map((zn) => {
    const maxPrevTime = zn.schedules.reduce(
      (cur, sch) => Math.max(cur, sch.previewActivationTimeStamp ?? 0),
      -1
    );
    const maxLiveTime = zn.schedules.reduce(
      (cur, sch) =>
        !sch.liveActivationTimeStamp
          ? cur
          : Math.max(cur, sch.liveActivationTimeStamp ?? 0),
      -1
    );

    const liveSchedule = zn.schedules.reduce((acc, curr) => {
      if (!curr.liveActivationTimeStamp) return acc;
      console.log(curr);
      if (
        !acc.liveActivationTimeStamp ||
        curr.liveActivationTimeStamp > acc.liveActivationTimeStamp
      ) {
        acc = curr;
      }
      return acc;
    }, {} as PriceSchedule);

    const previewSchedule = zn.schedules.reduce((acc, curr) => {
      if (!curr.previewActivationTimeStamp) return acc;
      if (
        !acc.previewActivationTimeStamp ||
        curr.previewActivationTimeStamp > acc.previewActivationTimeStamp
      ) {
        acc = curr;
      }
      return acc;
    }, {} as PriceSchedule);

    var activeSchedule =
      zn.schedules.find((f) => f.gid === selectedSelectBlock[zn.gid]) ??
      liveSchedule;

    const activations = activeSchedule?.priceActivations.map((pa, idx) => {
      const pri = activeSchedule.pricings.find((pri) => pri.id === pa.priceID)!;
      return {
        id: `${pri.id}_${idx}`,
        from: new Date(pa.fromDate),
        to: new Date(pa.toDate),
        priceProduct: pri,
        index: idx,
      } as DateObject;
    });

    return {
      title: zn.name,
      zone: zn,
      isLive: activeSchedule.gid === liveSchedule.gid,
      schemaGID: activeSchedule.gid,
      previewSchemaGID: previewSchedule.gid,
      liveSchemaGID: liveSchedule.gid,
      alternativeSchemas: zn.schedules.map((sch) => ({
        name: sch.name,
        gid: sch.gid,
      })),
      dateList: activations ?? [],
    } as ZoneSchemaContainer;
  });

  //useSelector((root:ApplicationState)=>root.zoneData[0].schedules

  if (selZones.length === 1) {
    var scheduleItem = selZones[0];
    var previewScheduel = scheduleItem.schedules.reduce((acc, curr) => {
      if (!curr.previewActivationTimeStamp) return acc;
      if (
        !acc.previewActivationTimeStamp ||
        curr.previewActivationTimeStamp > acc.previewActivationTimeStamp
      ) {
        acc = curr;
      }
      return acc;
    }, {} as PriceSchedule);

    var liveSchedule = scheduleItem.schedules.reduce((acc, curr) => {
      if (!curr.liveActivationTimeStamp) return acc;
      console.log(curr);
      if (
        !acc.liveActivationTimeStamp ||
        curr.liveActivationTimeStamp > acc.liveActivationTimeStamp
      ) {
        acc = curr;
      }
      return acc;
    }, {} as PriceSchedule);

    zoneSchedules = scheduleItem.schedules.reduce((acc, curr) => {
      const activation = curr.priceActivations.map((pa, idx) => {
        const pri = curr.pricings.find((pri) => pri.id === pa.priceID)!;
        return {
          id: `${pri.id}_${idx}`,
          from: new Date(pa.fromDate),
          to: new Date(pa.toDate),
          priceProduct: pri,
          index: idx,
        } as DateObject;
      });

      acc.push({
        ...zoneSchedules[0],
        title: curr.name,
        isLive: liveSchedule.gid === curr.gid,
        schemaGID: curr.gid,
        previewSchemaGID: previewScheduel.gid === curr.gid ? curr.gid : '',
        liveSchemaGID: liveSchedule.gid,
        dateList: activation ?? [],
      });
      return acc;
    }, [] as ZoneSchemaContainer[]);
  }

  let title = 'Schedule';
  if (selZones.length === 1) {
    title = `Schedule - ${selZones[0].name}`;
  }

  useEffect(() => {
    setLoading(true);
    setZones(selZones);
    if(selZones.length > 1 && multipleEdit){
      setMultipleEdit(false)
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, [selZones]);

  return (
    <Main>
      <SubHeader
        className="schedule-header"
        buttons={
          !canEdit || zones.length !== 1 ? undefined : (
            <ButtonWrapper>
              <ToggleWrapper>
                <ToggleTitle>Edit multiple pricings:</ToggleTitle>
                <Toggle
                  checked={multipleEdit}
                  onChange={(value) => setMultipleEdit(!multipleEdit)}
                />
              </ToggleWrapper>
              <Button
                color={ButtonColors.add}
                onClick={() => {
                  modal.push(<AddZoneScheduleModal />);
                }}
              >
                Add zone schedule
              </Button>
            </ButtonWrapper>
          )
        }
      >
        {title}
      </SubHeader>
      <DateBlock
        multipleEdit={multipleEdit}
        toggleMultipleEdit={() => setMultipleEdit(!multipleEdit)}
        onSelectChange={(zoneGid, scheduleGid) => {
          setSelectedSelectBlock({
            ...selectedSelectBlock,
            [zoneGid]: scheduleGid,
          });
        }}
        blockLists={loading ? [] : zoneSchedules}
        zones={zones}
      />
    </Main>
  );
};

export default ScheduleComponent;
