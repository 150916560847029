import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HiOutlineBell } from 'react-icons/hi';
import NotificationController, { NotificationModel } from 'api/notifications';
import { useSelector } from 'react-redux';
import { getUser } from 'store/uiSettings/selectors';
import PopupNotification from './popupNotification';
import useModalStack, {
  ModalStackType,
  ModalStaticId,
} from 'components/Modal/useModalStack';
import NotificationList from './notificationList';

const Wrapper = styled.div`
  position: relative;
  z-index: 201;
`;

const AlertWrapper = styled.div`
  position: relative;
`;

const PopupWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 500;

  > div {
    margin-bottom: 2px;
  }
`;

const UnreadBall = styled.div`
  background: red;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
`;

let timer: NodeJS.Timeout | null = null;

const Notification: React.FC = () => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [popupNotification, setPopupNotifications] = useState<
    NotificationModel[]
  >([]);

  var user = useSelector(getUser);

  const notificationController = new NotificationController();
  const modalStack = useModalStack();

  useEffect(() => {
    pollNotification();
  }, []);

  const pollNotification = async () => {
    if (!!timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      readNotifications(() => {
        pollNotification();
      });
    }, 5000);
  };

  const readNotifications = async (callback: () => void) => {
    var result = await notificationController.readNotifications(user.id!);
    setNotifications(result.notifications);
    setPopupNotifications(result.notifications.filter((f) => f.shouldPopup));
    callback();
  };

  const showNotifications = async () => {
    var unread = notifications.filter((f) => !f.read);

    unread.forEach((not) => {
      markAsRead(not.id);
    });
    modalStack.pushSlideIn(
      ModalStaticId.NOTIFICATIONS,
      'Notifications',
      <NotificationList notifications={notifications} />
    );
  };

  const markAsRead = async (id: string) => {
    var result = await notificationController.markAsRead(id);
    if (!!result) {
      setNotifications(
        notifications.map((f) => {
          if (f.id === id) {
            f.read = new Date();
          }
          return f;
        })
      );
    }
  };

  const handleOnPopupClose = async (id: string) => {
    if (!!timer) {
      clearTimeout(timer);
    }
    var result = await notificationController.popupCompleteNotifications(
      id,
      user.id!
    );
    setNotifications(result.notifications);
    setPopupNotifications(result.notifications.filter((f) => f.shouldPopup));
    pollNotification();
  };

  return (
    <>
      <Wrapper>
        <AlertWrapper onClick={showNotifications}>
          {notifications.filter((f) => !f.read).length > 0 && <UnreadBall />}
          <HiOutlineBell size={26} />
        </AlertWrapper>
      </Wrapper>
      {popupNotification.length > 0 && (
        <PopupWrapper>
          {popupNotification.map((x) => (
            <PopupNotification
              key={x.id}
              notification={x}
              onClick={() => {
                markAsRead(x.id);
              }}
              onClose={() => {
                setPopupNotifications(
                  popupNotification.filter((f) => f.id !== x.id)
                );
                handleOnPopupClose(x.id);
              }}
            />
          ))}
        </PopupWrapper>
      )}
    </>
  );
};

export default Notification;
