import styled, { css } from 'styled-components';
import { BlockMode } from 'util/schedule';
import { MARGIN } from './contants';

export const Wrapper = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  position: relative;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
`;

export const WrapperBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  left: 0;
  bottom: 0;
  display: flex;
  padding-top: 40px;
`;

export const BackgroundSpan = styled.span<{ width: number }>`
  display: inline-block;
  width: ${({ width }) => width}px;
  border-left: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
  border-right: 1px solid white;
`;

export const Row = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  transition: max-height 0.5s ease-in-out;
`;

export const SeasonRow = styled(Row)<{ expanded?: boolean }>`
  max-height: 1000px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  overflow: hidden;
  height: 335px;
  width: 100%;
  background-color: white;
  ${({ expanded }) =>
    !expanded &&
    css`
      max-height: 0;
      overflow: hidden;
    `};
`;

export const BlockRow = styled(Row)`
  height: 110px;
  padding: 10px 0;
  z-index: 1;
`;

export const TitleRow = styled(BlockRow)`
  position: relative;
`;

export const BlockContent = styled.div`
  padding-left: 5px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Block = styled.div<{
  width: number;
  marginLeft: number;
  mode: BlockMode;
  selected: boolean;
}>`
  cursor: pointer;
  display: inline-block;
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;

  margin-left: ${({ marginLeft }) => marginLeft}px;
  ${BlockContent} {
    min-width: ${({ width }) => width - 2}px;
    max-width: ${({ width }) => width - 2}px;
    background-color: #f7f7f7;
    height: 40px;
    border: 1px solid black;
    align-items: center;
    display: flex;
    border-radius: 5px;
  }

  ${({ mode, theme }) =>
    mode === BlockMode.PAST &&
    css`
      ${BlockContent} {
        color: ${({ theme }) => theme.palette.common.lightBorder};
        border: 1px solid ${theme.palette.common.primaryGray};
      }
    `};
  ${({ mode }) =>
    mode === BlockMode.PRESENT &&
    css`
      ${BlockContent} {
        background-color: rgb(
          117,
          210,
          207,
          0.5
        ); /* 50% opacity of theme.palette.secondary.bg  */
      }
    `};

  ${({ selected, theme }) =>
    selected &&
    css`
      ${BlockContent} {
        border: 3px solid ${theme.palette.primary.bg};
      }
    `};
`;

export const Title = styled.div`
  width: 240px;
  min-width: 240px;
  max-width: 240px; /* Adjust the width as desired */
  font-weight: bold;
  position: sticky;
  left: 0;
  background-color: white;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const MonthHeader = styled.div<{ width: number }>`
  display: inline-block;
  width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  font-weight: bold;
  padding-bottom: 5px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;

  ::-webkit-scrollbar {
    height: 8px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.palette.primary.bg}; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners of the thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the scrollbar track */
  }

  /* Works in Firefox */
  /* Note: Firefox provides limited support for scrollbar styling */
  * {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: ${({ theme }) => theme.palette.primary.bg} #f1f1f1; /* Color of the scrollbar thumb and track */
  }
`;

export const DateBlockWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 40px;
  margin-top: 30px;
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 2;
`;

export const ZoomButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
  margin: 0;
`;
export const TitleBlock = styled.div``;
export const Content = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
`;
export const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const IndicatorWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 20px;
`;

export const Indicator = styled.div<{ color: string }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin: 5px;
`;
