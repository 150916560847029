import React, { useEffect, useState } from 'react';
import {
  FaClock,
  FaCog,
  FaEye,
  FaEyeSlash,
  FaLeaf,
  FaTrash,
  FaTrashAlt,
} from 'react-icons/fa';
import { getFormattedDateWithYear } from 'store/ui/uiutil';
import styled, { css } from 'styled-components';
import { CardType, SeasonCard } from '.';
import { BlockMode } from 'util/schedule';
import useModalStack from 'components/Modal/useModalStack';
import { useDispatch, useSelector } from 'react-redux';
import ModPriceProductModal from 'pages/Pricing/ModPriceProductModal';
import {
  updateSchedule,
  zoneStoreAddPrice,
  zoneStoreModPrice,
  zoneStoreRemPrice,
} from 'store/zone/action';
import { firstToUpper } from 'components/Graph/GraphData';
import { PriceSchedule, Pricing, PricingRange, Zone } from 'store/zone/reducer';
import { RoundButton } from 'components/input/Button';
import { IoCopyOutline, IoDuplicate } from 'react-icons/io5';
import CopyPriceProductModal from 'components/CopyPriceProductModal';
import { AiOutlineSwap } from 'react-icons/ai';
import { hasEditRights } from 'store/uiSettings/selectors';
import { getPriceActivation, selectCurrentZones } from 'store/zone/selector';
import ConfirmRemove from 'components/Modal/confirmRemove';
import { PriceV3Api } from 'constants/api-fetch';
import moment from 'moment';
import { ApplicationState } from 'store/definitions';

const CardWrapper = styled.div<{
  selected?: boolean;
  present?: boolean;
  past?: boolean;
}>`
  position: relative;
  border-radius: 13px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  min-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(props) => props.theme.palette.common.primaryGray};

  ${(props) =>
    props.present &&
    css`
      border: 2px solid ${props.theme.palette.secondary.bg};
    `}

  ${(props) =>
    props.past &&
    css`
      color: ${({ theme }) => theme.palette.common.lightBorder};
      border: none;

      ${PropertyName} {
        color: ${({ theme }) => theme.palette.common.lightBorder};
      }
    `}

     ${(props) =>
    props.past &&
    props.selected &&
    css`
      color: ${({ theme }) => theme.palette.common.primaryGray};
      border: none;

      ${PropertyName} {
        color: ${({ theme }) => theme.palette.common.primaryGray};
      }
    `}

  ${(props) =>
    props.selected &&
    css`
      scroll-snap-align: center;
      border-top: 5px solid ${props.theme.palette.primary.bg};
      border-bottom: 5px solid ${props.theme.palette.primary.bg};
      min-width: 535px;
    `}
`;

const Content = styled.div`
  margin-top: 5px;
  padding: 0 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const CardFooter = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 5px;
`;

const Column = styled.div`
  flex: 1 0 auto;
`;

const ColumnContent = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 180px;
  flex-wrap: wrap;
`;

const Header = styled.div`
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateTimeTitle = styled.h1`
  font-size: 16px;
  font-weight: bold;
`;

const ProductName = styled.h2`
  display: flex;
  font-size: 16px;
  margin: 0;
  align-items: center;
  gap: 5px;
`;

const PropertyRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
`;

const PropertyName = styled.p`
  color: ${({ theme }) => theme.palette.common.primaryGray};
  font-size: 12px;
  margin: 0;
`;

const PropertyValue = styled.p`
  font-size: 16px;
  margin: 0;
`;

interface CardProps {
  type: CardType;
  card: SeasonCard;
  handleBlockClick: (id: string) => void;
  segment: PricingRange['segment'];
}

const Card: React.FC<CardProps> = ({
  card,
  handleBlockClick,
  segment,
  type,
}) => {
  const { from, to, priceProduct: _priceProduct, index } = card;
  const modalStack = useModalStack();
  const dispatch = useDispatch();

  const [priceProduct, setPriceProduct] = useState<Pricing>(_priceProduct);
  const [zone, setZone] = useState<Zone | undefined>(undefined);

  const priceActivation = useSelector((state: ApplicationState) =>
    getPriceActivation(
      state,
      _priceProduct.parentZoneId,
      _priceProduct.parentScheduleID!,
      _priceProduct.id,
      index
    )
  );
  const [dateTo, setDateTo] = useState<Date>(
    moment(priceActivation?.toDate).subtract(1, 'day').toDate()
  );
  const [dateFrom, setDateFrom] = useState<Date>(
    moment(priceActivation?.fromDate).toDate()
  );
  useEffect(() => {
    setDateTo(moment(priceActivation?.toDate).subtract(1, 'day').toDate());
    setDateFrom(moment(priceActivation?.fromDate).toDate());
  }, [priceActivation]);

  const zones = useSelector(selectCurrentZones);
  useEffect(() => {
    var zone = zones.find((f) => f.id === priceProduct.parentZoneId);
    setZone(zone);
    if (!!zone) {
      console.log(zone, priceProduct);
      var schedule = zone.schedules.find(
        (f) => f.id === priceProduct.parentScheduleID
      );
      if (!!schedule) {
        var price = schedule.pricings.find((f) => f.id === priceProduct.id);
        if (!!price) {
          setPriceProduct(price);
        }
      }
    }
  }, [zones]);

  const canEdit = useSelector(hasEditRights);

  const onEditClick = () => {
    modalStack.push(
      <ModPriceProductModal
        priceId={priceProduct.id}
        zone={zone ?? card.zone}
        onModPricing={(pricing) => {
          dispatch(zoneStoreModPrice(pricing));
        }}
        cardType={type}
      />
    );
  };

  const onRemoveClick = () => {
    modalStack.push(
      <ConfirmRemove
        objectTitle="Pricing"
        onSuccess={async () => {
          var api = new PriceV3Api();
          api
            .delete(`remove/pricing/${priceProduct.id}`)
            .then((result: PriceSchedule[]) => {
              modalStack.pop();
              dispatch(updateSchedule(result));
            });
        }}
        onClose={() => {
          modalStack.pop();
        }}
      />
    );
  };

  const onViewClick = () => {
    modalStack.push(
      <ModPriceProductModal
        priceId={priceProduct.id}
        zone={zone ?? card.zone}
        onModPricing={(pricing) => {
          dispatch(zoneStoreModPrice(pricing));
        }}
        cardType={type}
        readOnly
      />
    );
  };

  const onDuplicateClick = () => {
    modalStack.push(
      <ModPriceProductModal
        priceId={priceProduct.id}
        zone={zone ?? card.zone}
        onModPricing={(pricing) => {
          dispatch(zoneStoreAddPrice(pricing));
        }}
        cardType={type}
        duplicate
      />
    );
  };

  const onCopyClick = () => {
    modalStack.push(<CopyPriceProductModal cardPricing={priceProduct} />);
  };

  var segments = priceProduct?.prices.reduce((acc, curr) => {
    if (acc.find((f) => f === curr.segment)) {
      return acc;
    }
    return [...acc, curr.segment];
  }, [] as string[]);

  return (
    <CardWrapper
      id={`card_${card.gid}`}
      selected={card.selected}
      present={card.mode === BlockMode.PRESENT}
      past={card.mode === BlockMode.PAST}
    >
      <Header>
        <TitleWrapper>
          <DateTimeTitle>
            {getFormattedDateWithYear(dateFrom!)}-
            {dateTo && getFormattedDateWithYear(dateTo)}
          </DateTimeTitle>
          {type === CardType.SEASON && <FaLeaf size={20} />}
          {type === CardType.OVERRIDE && <FaClock size={20} />}
        </TitleWrapper>
        <ProductName>{priceProduct!.name}</ProductName>
      </Header>
      <Content>
        {priceProduct &&
          (card.selected ? segments : [segment]).map((s, index) => {
            var products = priceProduct.prices.filter((f) => f.segment === s);
            var flex =
              products.length > 3 || index !== 0 || !card.selected ? 1 : 0;
            return (
              <Column key={`${s}-${index}`} style={{ flex: flex }}>
                <ProductName style={{ fontWeight: 700 }}>
                  {firstToUpper(s)}
                </ProductName>
                <ColumnContent>
                  {products
                    .sort((a, b) => {
                      if (!a.groupId && b.groupId) {
                        return -1;
                      } else if (a.groupId && !b.groupId) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((x) => {
                      if (x.segment !== s) return null;
                      var groupId = x.groupId;
                      if (!!groupId) {
                        var group = priceProduct.priceGroups.find(
                          (g) => g.id === groupId
                        );
                        if (!!group) {
                          return (
                            <PropertyRow key={`${x.id}-${index}`}>
                              <PropertyName>
                                {firstToUpper(group.name)} (Min - Max)
                              </PropertyName>
                              <PropertyValue>
                                {x.min} - {x.max}
                              </PropertyValue>
                            </PropertyRow>
                          );
                        }
                      }
                      return (
                        <PropertyRow key={`${x.id}-${index}`}>
                          <PropertyName>
                            {firstToUpper(x.duration)} (Min - Max)
                          </PropertyName>
                          <PropertyValue>
                            {x.min} - {x.max}
                          </PropertyValue>
                        </PropertyRow>
                      );
                    })}
                </ColumnContent>
              </Column>
            );
          })}
      </Content>
      <CardFooter>
        {card.selected && (
          <>
            {canEdit && card.mode !== BlockMode.PAST && (
              <RoundButton>
                <FaCog
                  size={25}
                  style={{ cursor: 'pointer' }}
                  title="Edit Pricing"
                  onClick={onEditClick}
                />
              </RoundButton>
            )}
            {!canEdit ||
              (card.mode === BlockMode.PAST && (
                <RoundButton>
                  <FaEye
                    size={25}
                    style={{ cursor: 'pointer' }}
                    title="View Pricing"
                    onClick={onViewClick}
                  />
                </RoundButton>
              ))}
            {canEdit && type !== CardType.SEASON && (
              <RoundButton>
                <IoDuplicate
                  size={25}
                  style={{ cursor: 'pointer' }}
                  title="Copy forward - To next year"
                  onClick={onDuplicateClick}
                />
              </RoundButton>
            )}
            {false && canEdit && (
              <RoundButton onClick={onCopyClick} title="Copy Pricing">
                <IoCopyOutline size={25} />
              </RoundButton>
            )}
            {canEdit && type !== CardType.SEASON && (
              <RoundButton onClick={onRemoveClick} title="Removed pricing">
                <FaTrashAlt size={25} />
              </RoundButton>
            )}
          </>
        )}
        {!card.selected && (
          <RoundButton style={{ backgroundColor: '#75d2cf' }}>
            <AiOutlineSwap
              size={25}
              style={{ cursor: 'pointer' }}
              onClick={() => handleBlockClick(card.gid)}
            />
          </RoundButton>
        )}
      </CardFooter>
    </CardWrapper>
  );
};

export default Card;
