import React from 'react';
import Route from '../constants/Route';
import { NavTabWrapper, NavTab, navTabActiveClass } from './NavBar';

const AdminNavBar: React.FC<{}> = () => {
  return (
    <NavTabWrapper>
      <NavTab
        replace={true}
        to={Route.AdminUsers}
        activeClassName={navTabActiveClass}
      >
        Users
      </NavTab>
      <NavTab
        replace={true}
        to={Route.AdminDates}
        activeClassName={navTabActiveClass}
      >
        Holidays
      </NavTab>
    </NavTabWrapper>
  );
};

export default AdminNavBar;
