import React, { FC, useState } from 'react';
import Label from '../../components/input/Label';
import Select from '../../components/input/Select';
import styled from 'styled-components';
import { ApplicationState } from '../../store/definitions';
import { useSelector } from 'react-redux';
import { getDurationTitle } from 'util/pricing';
import { selectCurrentFacility } from 'store/zone/selector';

const segmentClass = 'pricingSegment';
const showPricesPerClass = 'pricingShowPricesPer';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  margin: 20px 0;

  & > * {
    min-width: 150px;
  }

  .${segmentClass} {
    grid-area: 1/1/2/2;
  }
  .${showPricesPerClass} {
    grid-area: 1/6/2/7;
  }
`;

interface Props {
  onChangeSegment: (segment: string) => void;
  onChangeDuration: (prices: string) => void;
  hasZone: boolean;
  durationParams?: string[];
}

const PricingParams: FC<Props> = (props) => {
  //   const curDuration = useSelector(
  //     (root: ApplicationState) => root.uiState.duration
  //   );
  const [selectedSegment, setSelectedSegment] = useState<string>('driveup');
  const curSegment = useSelector(
    (root: ApplicationState) => root.uiState.segments
  );
  const facility = useSelector(selectCurrentFacility);
  //   const curPT = useSelector((root: ApplicationState) => root.uiState.ptype);

  var dutationOptions = !props.durationParams
    ? [
        <option value="hour">Hour</option>,
        <option value="day">Day</option>,
        <option value="week">Week</option>,
      ]
    : props.durationParams.map((f) => (
        <option value={f}>{getDurationTitle(f)}</option>
      ));

  return (
    <Wrapper>
      <Label label="Segment" className={segmentClass}>
        <Select
          value={selectedSegment}
          onChange={(e) => {
            setSelectedSegment(e.target.value);
            props.onChangeSegment(e.target.value);
          }}
        >
          {facility.segments.indexOf('driveup') !== -1 ? (
            <option value="driveup">Driveup</option>
          ) : null}
          {facility.segments.indexOf('booking') !== -1 ? (
            <option value="booking">Booking</option>
          ) : null}
        </Select>
      </Label>
      {props.hasZone === false && (
        <Label label="Show prices per" className={showPricesPerClass}>
          <Select
            onChange={(e) => {
              props.onChangeDuration(e.target.value);
            }}
          >
            {dutationOptions}
          </Select>
        </Label>
      )}
    </Wrapper>
  );
};

export default PricingParams;
