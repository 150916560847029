import moment, { Moment } from 'moment';

export function getMonthsBetweenDates(date1: Date, date2: Date) {
  var months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months;
}

//generate getDaysBetweenDates
export function getDaysBetweenDates(date1: Date, date2: Date) {
  var days;
  days = (date2.getFullYear() - date1.getFullYear()) * 365;
  days -= date1.getTime();
  days += date2.getTime();
  return days / (1000 * 3600 * 24);
}

export function getStartOfYear() {
  var date = new Date();
  return new Date(date.getFullYear(), 0, 1);
}

export function getEndOfYear() {
  var date = new Date();
  return new Date(date.getFullYear(), 11, 31);
}

export function getStartOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getSameDayLastYear(date: Moment) {
  var weekDay = date.isoWeekday();
  var _date = moment(date).subtract(1, 'year');

  var newWeekDay = _date.isoWeekday();
  while (weekDay !== newWeekDay) {
    _date = _date.add(1, 'day');
    newWeekDay = _date.isoWeekday();
  }
  return _date;
}

export function getSameDayInAYear(date: Moment, year: number) {
  var week = date.isoWeek();
  var weekDay = date.isoWeekday();
  var _date = moment(date).year(year).isoWeekday(weekDay).isoWeek(week);

  if (date.diff(_date, 'days') < 360) {
    return moment(date)
      .year(year)
      .isoWeekday(weekDay)
      .isoWeek(week - 1);
  }

  return _date;
}

export function getSameDayNextYear(date: Moment) {
  var week = date.week();
  var weekDay = date.isoWeekday();
  return moment(date).add(1, 'year').isoWeek(week).isoWeekday(weekDay);
}
