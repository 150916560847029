import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Facilities, Role } from '../../pages/admin/Users/userAdminContext';
import { AiOutlineCheck } from 'react-icons/ai';
import baseInputStyle from './baseInputStyle';
import { FacilitySpecialZonePicker } from 'store/ui/uiutil';
import { setSpecialSelect } from 'store/ui/action';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { FacilityPageProps } from 'topdefinitions';

export interface MultiSelectOption {
  id: number | string;
  name: string;
  route: string;
}

interface Props {
  selected: MultiSelectOption[];
  available: MultiSelectOption[];
  onChange: (list: MultiSelectOption[]) => void;
  facilityID?: string;
  allSelected?: boolean;
  onChangeAll?: (allSelected: boolean) => void;
  onSpecialSelect: (sel: FacilitySpecialZonePicker) => void;
  selectAll?: boolean;
  specialSelected: string[];
  specialSelectOptions?: FacilitySpecialZonePicker[];
  selectAllLabel?: string;
  extended?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  flex: 1;
  width: 100%;
`;

const SelectedWrapper = styled.div`
  ${baseInputStyle}
  padding: 6px 10px;
  overflow: hidden;
  white-space: nowrap;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const MultiSelectWindow = styled.div<{ extended: boolean }>`
  position: absolute;
  top: 42px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 5px;
  width: 100%;
  background: white;
  max-height: 300px;
  overflow-y: auto;
  z-index: 5;

  ${({ extended }) =>
    extended &&
    css`
      max-height: 800px;
    `}
`;

const SelectOption = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.palette.common.lightBorder};
  display: flex;
  align-items: center;
`;

const SelectName = styled.div``;

const ToggleSelect = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MultiSelect: React.FC<Props> = ({
  selected,
  available,
  onChange,
  onSpecialSelect,
  onChangeAll,
  facilityID,
  allSelected = false,
  selectAll = false,
  selectAllLabel = 'Select All',
  specialSelected = [],
  specialSelectOptions = [],
  extended = false,
}) => {
  const wrapperRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentlySelected, setCurrentlySelected] =
    useState<MultiSelectOption[]>(selected);
  const [all, setAll] = useState<boolean>(allSelected);
  const dispatch = useDispatch();
  const match = useRouteMatch<FacilityPageProps['match']['params']>();

  const openSelected = () => {
    setIsOpen(!isOpen);
  };

  const toggleSelected = (sel: MultiSelectOption) => {
    if (!!currentlySelected.find((f) => f.id === sel.id)) {
      var newList = currentlySelected.filter((f) => f.id !== sel.id);
      setCurrentlySelected(newList);
      onChange(newList);
    } else {
      var newList = [...currentlySelected, sel];
      setCurrentlySelected(newList);
      onChange(newList);
    }
  };

  useEffect(() => {
    setCurrentlySelected(selected);
  }, [facilityID]);

  useEffect(() => {
    setAll(allSelected);
  }, [allSelected]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current!.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleAll = () => {
    if (available.length === currentlySelected.length) {
      setCurrentlySelected([]);
      onChange([]);
      setAll(false);
      if (!!onChangeAll) onChangeAll(false);
    } else {
      setCurrentlySelected(available);
      onChange(available);
      setAll(true);
      if (!!onChangeAll) onChangeAll(true);
    }
  };

  console.log(selectAll, all, available.length, currentlySelected.length);
  return (
    <Wrapper ref={wrapperRef}>
      <SelectedWrapper onClick={() => openSelected()}>
        <div>
          {selectAll &&
            (available.length === currentlySelected.length || all) &&
            selectAllLabel}
          {!all &&
            available.length !== currentlySelected.length &&
            currentlySelected &&
            (currentlySelected
              .map((f: MultiSelectOption) => f.name)
              .join(', ') as string)}
          {currentlySelected.length === 0 && 'Choose option'}
        </div>
      </SelectedWrapper>
      {isOpen && (
        <MultiSelectWindow extended={extended}>
          {selectAll && (
            <SelectOption onClick={toggleAll}>
              <SelectName>{selectAllLabel ?? 'Select all'}</SelectName>
              <ToggleSelect>
                {(all || available.length === currentlySelected.length) && (
                  <AiOutlineCheck />
                )}
              </ToggleSelect>
            </SelectOption>
          )}
          {specialSelectOptions.map((f) => (
            <SelectOption
              key={f.id}
              onClick={() => {
                const sel = f;
                var zones = available.filter((f) =>
                  sel.zoneIds.includes(f.id.toString())
                );
                var selected = specialSelected.includes(sel.id);
                if (!selected) {
                  setCurrentlySelected(zones);
                  onChange(zones);
                } else {
                  setCurrentlySelected(
                    currentlySelected.filter(
                      (f) => !sel.zoneIds.includes(f.id.toString())
                    )
                  );
                  onChange(
                    currentlySelected.filter(
                      (f) => !sel.zoneIds.includes(f.id.toString())
                    )
                  );
                }
                onSpecialSelect(f);
              }}
            >
              <SelectName>{f.name}</SelectName>
              <ToggleSelect>
                {specialSelected.includes(f.id) && <AiOutlineCheck />}
              </ToggleSelect>
            </SelectOption>
          ))}
          {available.map((f: MultiSelectOption) => (
            <SelectOption key={f.id} onClick={() => toggleSelected(f)}>
              <SelectName>{f.name}</SelectName>
              <ToggleSelect>
                {(all || currentlySelected.find((x) => x.id === f.id)) && (
                  <AiOutlineCheck />
                )}
              </ToggleSelect>
            </SelectOption>
          ))}
        </MultiSelectWindow>
      )}
    </Wrapper>
  );
};

export default MultiSelect;
