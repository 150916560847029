import { useDemandContext } from 'components/Graph/DemandContext';
import { DataPoint } from 'components/Graph/GenericLineCharts';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ExportButton from 'util/excel';
import { useAccRevContext } from './AccRevenueSticker';
import { useSelector } from 'react-redux';
import { getUser } from 'store/uiSettings/selectors';
import {
  selectUIStateEnableCompare,
  selectUiStateEndCompareDate,
  selectUiStateEndDate,
  selectUiStateSegment,
  selectUiStateStartCompareDate,
  selectUiStateStartDate,
} from 'store/ui/selector';
import useLocalStorage from 'provider/useLocalStorage';
import { selectCurrentFacility, selectCurrentZones } from 'store/zone/selector';
import moment from 'moment';

const ExportWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const indexes = [
  'revenue_booking',
  '__compare__revenue_booking',
  'revenue_driveup',
  '__compare__revenue_driveup',
  'electric_revenue_driveup',
  'acc_revenue_driveup',
  'acc___compare__revenue_driveup',
  'acc_revenue_booking',
  'acc___compare__revenue_booking',
  'tdAccRev',
  'comAccRev',
];

const RevenueExport = () => {
  const accRevContext = useAccRevContext();

  const user = useSelector(getUser);
  const curSegment = useSelector(selectUiStateSegment);
  const { read, getGraphViewKey } = useLocalStorage();
  var view =
    read(getGraphViewKey(user.id!)) === ''
      ? 'bars'
      : read(getGraphViewKey(user.id!))!;

  const [exportData, setExportData] = useState<DataPoint[]>([]);
  const curZone = useSelector(selectCurrentZones);
  const startDate = useSelector(selectUiStateStartDate);
  const endDate = useSelector(selectUiStateEndDate);
  const compareStartDate = useSelector(selectUiStateStartCompareDate);
  const compareEndDate = useSelector(selectUiStateEndCompareDate);
  const enableCompare = useSelector(selectUIStateEnableCompare);

  const site = useSelector(selectCurrentFacility);
  const vatRate = site?.config?.grossIncludedTaxRatePercentage ?? -1000;

  useEffect(() => {
    let exportIndexes = [...indexes];

    if (curSegment === 'booking') {
      exportIndexes = exportIndexes.filter((f) => f.endsWith('_booking'));
    }
    if (curSegment === 'driveup') {
      exportIndexes = exportIndexes.filter((f) => f.endsWith('_driveup'));
    }

    var combinedValues = {} as { [key: string]: number };
    var data = accRevContext.graphData.map((f, idx) => {
      var obj = {} as any;
      var date = moment(startDate).add(idx, 'days');
      var compDate = moment(compareStartDate).add(idx, 'days');
      obj['date'] = date.format('YYYY-MM-DD');
      if (enableCompare) {
        obj['compare_date'] = compDate.format('YYYY-MM-DD');
      }
      exportIndexes.forEach((x) => {
        var key = x;
        var value = f[x];
        if (x.startsWith('acc_')) {
          key = x.replace('acc_', '');
          if (!combinedValues[x]) combinedValues[x] = 0;
          combinedValues[x] += Number(f[key]);
          value = combinedValues[x];
        }
        if (!!value) {
          obj[x] = value;
        }
      });
      return obj;
    });
    setExportData(data);
  }, [curSegment, accRevContext.graphData]);
  console.log('Revenue EXPORT', accRevContext);
  return (
    <ExportWrapper>
      <ExportButton
        name={`Revenue`}
        disabled={accRevContext.graphData.length === 0}
        data={{
          extra: {
            startDate: startDate,
            endDate: endDate,
            compareStartDate: enableCompare ? compareStartDate : '',
            compareEndDate: enableCompare ? compareEndDate : '',
            segment: curSegment,
            grossRevenue: accRevContext.accRev,
            netRevenue: accRevContext.accRev / ((100 + vatRate) / 100),
            ...curZone.map((f) => f.name),
          },
          data: exportData,
        }}
      />
    </ExportWrapper>
  );
};

export default RevenueExport;
