import { useDemandContext } from 'components/Graph/DemandContext';
import { DataPoint } from 'components/Graph/GenericLineCharts';
import moment from 'moment';
import useLocalStorage from 'provider/useLocalStorage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectUIStateEnableCompare,
  selectUiStateEndCompareDate,
  selectUiStateEndDate,
  selectUiStateSegment,
  selectUiStateStartCompareDate,
  selectUiStateStartDate,
} from 'store/ui/selector';
import { getUser } from 'store/uiSettings/selectors';
import { selectCurrentZones } from 'store/zone/selector';
import styled from 'styled-components';
import { getDaysBetweenDates } from 'util/date';
import ExportButton from 'util/excel';

const ExportWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

var barsIndex = [
  'arrival_booking',
  '__compare__arrival_booking',
  'arrival_driveup',
  '__compare__arrival_driveup',
];

const indexes = [
  ...barsIndex,
  'capacity_booking',
  'capacity_driveup',
  'capacity_upper_booking',
  'capacity_lower_booking',
  'combined_capacity_booking',
  'occupancy',
  'actual_occupancy',
  '__compare__actual_occupancy',
  'occupancy_booking',
  'actual_occupancy_booking',
  '__compare__actual_occupancy_booking',
  'occupancy_driveup',
  'actual_occupancy_driveup',
  '__compare__actual_occupancy_driveup',
  '__compare__pree_book_booking',
  '__compare__pree_book_driveup',
  '__compare__pree_book',
];

const DemandExport = () => {
  const accDemandContext = useDemandContext();
  const user = useSelector(getUser);
  const curSegment = useSelector(selectUiStateSegment);
  const { read, getGraphViewKey } = useLocalStorage();
  var view =
    read(getGraphViewKey(user.id!)) === ''
      ? 'bars'
      : read(getGraphViewKey(user.id!))!;
  console.log('DEMAND EXPORT', accDemandContext);
  const curZone = useSelector(selectCurrentZones);
  const startDate = useSelector(selectUiStateStartDate);
  const endDate = useSelector(selectUiStateEndDate);
  const compareStartDate = useSelector(selectUiStateStartCompareDate);
  const compareEndDate = useSelector(selectUiStateEndCompareDate);
  const enableCompare = useSelector(selectUIStateEnableCompare);

  const [exportData, setExportData] = useState<DataPoint[]>([]);

  useEffect(() => {
    let exportIndexes = [...indexes];
    if (view !== 'bars') {
      exportIndexes = exportIndexes.filter((f) => !barsIndex.includes(f));
    }

    if (curSegment === 'all') {
      exportIndexes = exportIndexes.filter(
        (f) =>
          f === 'combined_capacity_booking' ||
          f === 'capacity_upper_booking' ||
          f === 'capacity_lower_booking' ||
          (!f.endsWith('_booking') && !f.endsWith('_driveup'))
      );
    }
    if (curSegment === 'booking') {
      exportIndexes = exportIndexes.filter(
        (f) =>
          f.endsWith('_booking') &&
          f !== 'combined_capacity_booking' &&
          f !== 'capacity_upper_booking' &&
          f !== 'capacity_lower_booking'
      );
    }
    if (curSegment === 'driveup') {
      exportIndexes = exportIndexes.filter((f) => f.endsWith('_driveup'));
    }

    var data = accDemandContext.graphData.map((f, idx) => {
      var obj = {} as any;
      var date = moment(startDate).add(idx, 'days');
      var compDate = moment(compareStartDate).add(idx, 'days');
      obj['date'] = date.format('YYYY-MM-DD');
      if (enableCompare) {
        obj['compare_date'] = compDate.format('YYYY-MM-DD');
      }
      exportIndexes.forEach((x) => {
        if (!!f[x]) {
          if (x === 'combined_capacity_booking') {
            obj['capacity'] = f[x];
          } else if (x === 'acutal_occupancy') {
            obj['pre_book'] = f[x];
          } else {
            obj[x] = f[x];
          }
        }
      });
      return obj;
    });
    setExportData(data);
  }, [curSegment, accDemandContext.graphData]);

  var daysBetween = getDaysBetweenDates(
    moment(startDate).toDate(),
    moment(endDate).toDate()
  );

  return (
    <ExportWrapper>
      <ExportButton
        name={`Demand`}
        disabled={accDemandContext.graphData.length === 0}
        data={{
          extra: {
            startDate: startDate,
            endDate: endDate,
            compareStartDate: enableCompare ? compareStartDate : '',
            compareEndDate: enableCompare ? compareEndDate : '',
            segment: curSegment,
            ...curZone.map((f) => f.name),
          },
          data: exportData,
        }}
      />
    </ExportWrapper>
  );
};

export default DemandExport;
