import Main from 'components/Main';
import Sticker from 'components/Sticker';
import DashboardSticker, {
  DashboardStickerProps,
} from 'components/Sticker/dashboardSticker';
import Label from 'components/input/Label';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAvailableFacilityZones,
  selectCurrentZones,
} from 'store/zone/selector';
import styled from 'styled-components';
import Select from 'components/input/Select';
import DashboardController, { DashboardResponseModel } from 'api/dashboard';
import moment from 'moment';
import Loader from 'components/Loader';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import {
  getDaysBetweenDates,
  getSameDayInAYear,
  getSameDayLastYear,
} from 'util/date';
import {
  DataPoint,
  GraphTop,
  LeftContent,
  RightContent,
} from 'components/Graph/GenericLineCharts';
import ExportButton from 'util/excel';

const DashboardWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StickerSection = styled.div`
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px 150px 150px;
  margin-bottom: 20px;
  padding-top: 30px;

  > div {
    grid-column: span 2;
  }

  .centered {
    grid-column: span 3;
    /* Span two columns */
    text-align: center;
  }

  > span {
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 20px;
    font-weight: bold;
  }
`;

enum DashboardMode {
  ANALYSIS = 1,
  FORECAST = 2,
}

const ModeSettings = {
  [DashboardMode.ANALYSIS]: {
    title: 'Analysis',
    key: DashboardMode.ANALYSIS,
  },
  [DashboardMode.FORECAST]: {
    title: 'Forecast',
    key: DashboardMode.FORECAST,
  },
};

var callId = '';
const Dashboard: FC = () => {
  // const data = [
  //   { value: 12345, title: 'Sticker 1' },
  //   { value: 67890, title: 'Sticker 2', diffValue: 5000 },
  //   { value: 54321, title: 'Sticker 3' },
  //   { value: 12345, title: 'Sticker 4' },
  //   { value: 67890, title: 'Sticker 5', diffValue: 5000 },
  //   { value: 54321, title: 'Sticker 6' },
  //   // Add more StickerProps objects as needed
  // ];

  const activeZones = useSelector(selectCurrentZones);
  var inistalStartDate = moment().subtract(1, 'weeks').subtract(1, 'days');
  const [zoneIds, setZoneIds] = useState<number[]>([]);
  const [segments, setSegments] = useState<number[]>([100, 101]);
  const [mode, setMode] = useState<DashboardMode>(DashboardMode.ANALYSIS);
  const [dashboardData, setDashboardData] = useState<DashboardResponseModel>();
  const [startDate, setStartDate] = useState<moment.Moment>(inistalStartDate);
  const [endDate, setEndDate] = useState<moment.Moment>(
    moment().add(-2, 'days')
  );
  const [compareDate, setComapreDate] = useState<moment.Moment>(
    getSameDayLastYear(inistalStartDate)
  );
  const [fetching, setFetching] = useState<boolean>(false);
  const [controller, setController] = useState<AbortController>();

  const [visualData, setVisualData] = useState<{
    total: DashboardStickerProps[];
    driveup: DashboardStickerProps[];
    booking: DashboardStickerProps[];
  }>({
    total: [],
    driveup: [],
    booking: [],
  });

  const _dashboardController = new DashboardController();

  useEffect(() => {
    setZoneIds(activeZones.map((f) => f.id));
  }, [activeZones]);

  useEffect(() => {
    if (zoneIds.length === 0) return;
    if (mode === DashboardMode.FORECAST) {
      setStartDate(moment());
      setEndDate(moment().add(1, 'months').subtract(1, 'days'));
      setComapreDate(getSameDayLastYear(moment()));
      setSegments([101]);
    } else {
      setStartDate(inistalStartDate);
      setEndDate(moment().add(-2, 'days'));
      setComapreDate(getSameDayLastYear(inistalStartDate));
      setSegments([100, 101]);
    }
    setDashboardData(undefined);
  }, [mode]);

  useEffect(() => {
    if (zoneIds.length !== 0) {
      if (controller) controller.abort();
      setFetching(true);
      setTimeout(() => {
        if (mode === DashboardMode.ANALYSIS) {
          fetchAnalysis();
        } else {
          fetchForecast();
        }
      }, 0);
    }
  }, [zoneIds, startDate, endDate, compareDate]);

  const fetchAnalysis = async () => {
    var daysBetween = Math.round(
      getDaysBetweenDates(startDate.toDate(), endDate.toDate())
    );

    let controller = new AbortController();
    setController(controller);
    const { signal } = controller;

    //GUID variable callId
    callId = moment().format('YYYYMMDDHHmmssSSS');

    var response = await _dashboardController.fetchAnalysis(
      {
        ZoneIds: zoneIds,
        Segments: [100, 101],
        StartDate: startDate.format('YYYY-MM-DD'),
        EndDate: moment(endDate)
          .startOf('day')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        CompareStartDate: compareDate.format('YYYY-MM-DD'),
        CompareEndDate: moment(compareDate)
          .add(daysBetween, 'days')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        CallId: callId,
      },
      signal
    );
    if (!!response) {
      console.log(response.callId, callId);
      if (response.callId !== callId) return;
      setDashboardData(response);
      setFetching(false);
      setController(undefined);
    }
  };

  const fetchForecast = async () => {
    var daysBetween = Math.round(
      getDaysBetweenDates(startDate.toDate(), endDate.toDate())
    );
    callId = moment().format('YYYYMMDDHHmmssSSS');
    let controller = new AbortController();
    setController(controller);
    const { signal } = controller;

    var response = await _dashboardController.fetchForecast(
      {
        ZoneIds: zoneIds,
        Segments: [100, 101],
        StartDate: startDate.format('YYYY-MM-DD'),
        EndDate: moment(endDate)
          .startOf('day')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        CompareStartDate: compareDate.format('YYYY-MM-DD'),
        CompareEndDate: moment(compareDate)
          .add(daysBetween, 'days')
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        CallId: callId,
      },
      signal
    );
    if (!!response) {
      if (response.callId !== callId) return;
      setDashboardData(response);
      setFetching(false);
      setController(undefined);
    }
  };

  useEffect(() => {
    let _accTotal: DashboardStickerProps[] = [];
    let _accBooking: DashboardStickerProps[] = [];
    let _accDriveup: DashboardStickerProps[] = [];
    var data = dashboardData?.current;
    var compare = dashboardData?.compare;

    var days = Math.ceil(
      getDaysBetweenDates(startDate.toDate(), endDate.toDate())
    );

    if (!!data && !!compare) {
      console.log(data, compare);
      if (segments.length !== 1) {
        _accTotal = [
          {
            value: Number(data!.total.toFixed(0)),
            diffValue: Number(compare!.total.toFixed(0)),
            title: 'Acc. Revenue',
          },
          {
            value: Number(data!.parkings.toFixed(0)),
            diffValue: Number(compare!.parkings.toFixed(0)),
            title: 'Amount of parkings',
          },
          {
            value: data!.amountOfDays,
            diffValue: compare!.amountOfDays,
            title: 'Amount of days',
          },
          {
            value: Number((data!.total / data!.amountOfDays).toFixed(2)),
            diffValue: Number(
              (compare!.total / compare!.amountOfDays).toFixed(2)
            ),
            title: 'Average rev per day',
          },
          {
            value: Number((data!.total / data!.parkings).toFixed(2)),
            diffValue: Number((compare!.total / compare!.parkings).toFixed(2)),
            title: 'Average rev per parking',
          },
          {
            value: Number((data!.amountOfDays / data!.parkings).toFixed(2)),
            diffValue: Number(
              (compare!.amountOfDays / compare!.parkings).toFixed(2)
            ),
            title: 'Average day per parking',
          },
        ];

        if (mode === DashboardMode.ANALYSIS) {
          var firstElem = _accTotal[0];
          firstElem.className = 'centered';

          _accTotal.splice(0, 0, {
            value: Number(data!.paxEntries!.toFixed(0)),
            diffValue: Number(compare!.paxEntries!.toFixed(0)),
            title: 'Passengers',
          });

          //insert revenue per pax on index 2

          _accTotal.splice(1, 0, {
            value: Number(data!.paxInland!),
            diffValue: Number(compare!.paxInland!),
            title: 'Domestic passengers',
            diffOverride: Number(
              (100 * (data.paxInland! / compare.paxInland! - 1)).toFixed(1)
            ),
          });

          _accTotal.splice(2, 0, {
            value: Number(data!.paxOutland!),
            diffValue: Number(compare!.paxOutland!),
            diffOverride: Number(
              (100 * (data.paxOutland! / compare.paxOutland! - 1)).toFixed(1)
            ),
            title: 'International passengers',
          });
          _accTotal.splice(4, 0, {
            value: Number((data!.total / data!.paxEntries!).toFixed(2)),
            diffValue: Number(
              (compare!.total / compare!.paxEntries!).toFixed(2)
            ),
            title: 'Revenue per passenger',
            className: 'centered',
          });
          _accTotal.splice(7, 0, {
            value: Number(data!.capacity.toFixed(2)),
            diffValue: Number(compare!.capacity.toFixed(2)),
            suffix: '%',
            title: 'Occupancy',

            diffOverride: Number(
              Number(data.capacity - compare.capacity).toFixed(1)
            ),
          });
        }
      }

      if (segments.includes(101)) {
        _accBooking = [
          {
            value: Number(data!.booking!.total.toFixed(0)),
            diffValue: Number(compare!.booking!.total.toFixed(0)),
            title: 'Acc. Booking Revenue',
          },
          {
            value: data!.booking!.parkings,
            diffValue: compare!.booking?.parkings,
            title: 'Amount of bookings',
          },
          {
            value: data!.booking!.amountOfDays,
            diffValue: compare!.booking?.amountOfDays,
            title: 'Amount of booking days',
          },
          {
            value: Number(
              (data!.booking!.total / data!.booking!.amountOfDays).toFixed(2)
            ),
            diffValue: Number(
              (
                compare!.booking!.total / compare!.booking!.amountOfDays
              ).toFixed(2)
            ),
            title: 'Average rev per booking day',
          },
          {
            value: Number(
              (data!.booking!.total / data!.booking!.parkings).toFixed(2)
            ),
            diffValue: Number(
              (compare!.booking!.total / compare!.booking!.parkings).toFixed(2)
            ),
            title: 'Average rev per booking',
          },
          {
            value: Number(
              (data!.booking!.amountOfDays / data!.booking!.parkings).toFixed(2)
            ),
            diffValue: Number(
              (
                compare!.booking!.amountOfDays / compare!.booking!.parkings
              ).toFixed(2)
            ),
            title: 'Average day per booking',
          },
        ];
        if (mode === DashboardMode.ANALYSIS) {
          _accBooking.push({
            value: Number(data!.booking!.capacity.toFixed(2)),
            diffValue: Number(compare!.booking!.capacity.toFixed(2)),
            suffix: '%',
            title: 'Occupancy',
            diffOverride: Number(
              Number(
                data.booking!.capacity - compare.booking!.capacity
              ).toFixed(1)
            ),
          });
        }
      }
      if (segments.includes(100)) {
        _accDriveup = [
          {
            value: Number(data!.driveup!.total.toFixed(0)),
            diffValue: Number(compare!.driveup!.total.toFixed(0)),
            title: 'Acc. Driveup Revenue',
          },
          {
            value: data!.driveup!.parkings,
            diffValue: compare!.driveup?.parkings,
            title: 'Amount of driveup parkings',
          },
          {
            value: data!.driveup!.amountOfDays,
            diffValue: compare!.driveup?.amountOfDays,
            title: 'Amount of driveup days',
          },
          {
            value: Number(
              (data!.driveup!.total / data!.driveup!.amountOfDays).toFixed(2)
            ),
            diffValue: Number(
              (
                compare!.driveup!.total / compare!.driveup!.amountOfDays
              ).toFixed(2)
            ),
            title: 'Average rev per driveup day',
          },
          {
            value: Number(
              (data!.driveup!.total / data!.driveup!.parkings).toFixed(2)
            ),
            diffValue: Number(
              (compare!.driveup!.total / compare!.driveup!.parkings).toFixed(2)
            ),
            title: 'Average rev per driveup',
          },
          {
            value: Number(
              (data!.driveup!.amountOfDays / data!.driveup!.parkings).toFixed(2)
            ),
            diffValue: Number(
              (
                compare!.driveup!.amountOfDays / compare!.driveup!.parkings
              ).toFixed(2)
            ),
            title: 'Average day per driveup',
          },
        ];

        if (mode === DashboardMode.ANALYSIS) {
          _accDriveup.push({
            value: Number(data!.driveup!.capacity.toFixed(2)),
            diffValue: Number(compare!.driveup!.capacity.toFixed(2)),
            suffix: '%',
            title: 'Occupancy',

            diffOverride: Number(
              Number(
                data.driveup!.capacity - compare.driveup!.capacity
              ).toFixed(1)
            ),
          });
        }
      }
    }
    setVisualData({
      total: _accTotal,
      driveup: _accDriveup,
      booking: _accBooking,
    });
  }, [dashboardData, segments]);

  const getDashboardData = () => {
    return (
      <>
        {visualData.total.length > 0 && (
          <StickerSection>
            <span>Total</span>
            {visualData.total.map((stickerData, index) => {
              return (
                <StickerContainer
                  className={stickerData.className ?? ''}
                  key={index}
                >
                  <DashboardSticker {...stickerData} />
                </StickerContainer>
              );
            })}
          </StickerSection>
        )}

        {visualData.booking.length > 0 && (
          <StickerSection>
            <span>Booking</span>
            {visualData.booking.map((stickerData, index) => (
              <StickerContainer key={index}>
                <DashboardSticker {...stickerData} />
              </StickerContainer>
            ))}
          </StickerSection>
        )}

        {visualData.driveup.length > 0 && (
          <StickerSection>
            <span>Driveup</span>
            {visualData.driveup.map((stickerData, index) => (
              <StickerContainer key={index}>
                <DashboardSticker {...stickerData} />
              </StickerContainer>
            ))}
          </StickerSection>
        )}
      </>
    );
  };

  return (
    <Main>
      <GraphTop>
        <LeftContent>
          <Label label={'Mode'}>
            <Select
              onChange={(eve) => {
                var value = Number(eve.target.value) as DashboardMode;
                setMode(value);
              }}
              value={mode}
            >
              {Object.keys(ModeSettings).map((modeKey) => (
                <option key={modeKey} value={modeKey}>
                  {ModeSettings[Number(modeKey) as DashboardMode].title}
                </option>
              ))}
            </Select>
          </Label>
          <Label label={'Segment'}>
            <Select
              onChange={(eve) => {
                var value = eve.target.value;
                if (value === 'null') {
                  setSegments([100, 101]);
                } else {
                  setSegments([Number(value)]);
                }
              }}
              value={segments.length != 1 ? 'null' : segments[0]}
            >
              <option value={'null'}>Total</option>
              <option value={101}>Booking</option>
              <option value={100}>Driveup</option>
            </Select>
          </Label>
          <Label label="From date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                var daysBetweenCompate = getDaysBetweenDates(
                  compareDate.toDate(),
                  startDate.toDate()
                );
                setStartDate(moment(date));
                setComapreDate(
                  moment(date).subtract(daysBetweenCompate, 'days')
                );

                var daysBetween = Math.ceil(endDate.diff(startDate, 'days'));
                if (moment(date) > endDate) {
                  setEndDate(moment(date));
                } else {
                  setEndDate(moment(date).add(daysBetween, 'days'));
                }
              }}
              selected={startDate.toDate()}
              maxDate={
                mode === DashboardMode.ANALYSIS
                  ? moment().add(-1, 'days').toDate()
                  : null
              }
              minDate={
                mode !== DashboardMode.ANALYSIS ? moment().toDate() : null
              }
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="To date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setEndDate(moment(date));
              }}
              selected={endDate.toDate()}
              maxDate={
                mode === DashboardMode.ANALYSIS
                  ? moment().add(-1, 'days').toDate()
                  : null
              }
              minDate={startDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>

          <Label label="From compare date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setComapreDate(moment(date));
              }}
              selected={compareDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="To compare date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {}}
              selected={moment(compareDate)
                .add(
                  getDaysBetweenDates(startDate.toDate(), endDate.toDate()),
                  'days'
                )
                .toDate()}
              disabled={true}
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
        </LeftContent>
        <RightContent>
          <ExportButton
            name={`Dashboard`}
            data={{
              extra: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                compareStartDate: compareDate.format('YYYY-MM-DD'),
                compareEndDate: moment(compareDate)
                  .add(
                    getDaysBetweenDates(startDate.toDate(), endDate.toDate()),
                    'days'
                  )
                  .format('YYYY-MM-DD'),
                ...activeZones.map((f) => f.name),
              },
              data: [
                Object.keys(visualData).reduce((acc, curr) => {
                  var key = curr as keyof typeof visualData;
                  visualData[key].forEach((elem) => {
                    acc[`${curr} ${elem.title} value`] = elem.value;
                    acc[`${curr} ${elem.title} diff value`] =
                      elem.diffValue ?? '0';
                  });
                  return acc;
                }, {} as DataPoint),
              ],
            }}
          />
        </RightContent>
      </GraphTop>
      <DashboardWrapper style={{ position: 'relative', minHeight: '400px' }}>
        {(!dashboardData || !!fetching) && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#ffffff',
              opacity: 0.35,
            }}
          >
            <Loader title={'Could not find data'} />
          </div>
        )}
        {!!dashboardData && !fetching && getDashboardData()}
      </DashboardWrapper>
    </Main>
  );
};

const StickerContainer = styled.div`
  flex: 0 0 calc(33.33% - 10px);
  margin: 5px;
`;

export default Dashboard;
