import { NotificationModel } from 'api/notifications';
import React from 'react';
import styled from 'styled-components';
import NotificationItem from './notificationItem';
import Label from 'components/input/Label';

interface Props {
  notifications: NotificationModel[];
}

const Wrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
`;
const Seperator = styled.div`
  height: 0px;
  margin: 0;
  padding: 0;
  margin-top: -1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
`;

const NoWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
`;

const NotificationList: React.FC<Props> = (props) => {
  const { notifications } = props;

  return (
    <Wrapper>
      {notifications.map((f, index) => (
        <>
          <NotificationItem notification={f} key={`${f.id}`} />
          <Seperator />
        </>
      ))}
      {notifications.length === 0 && (
        <NoWrapper>
          <Label label={'No new notifications'} />
        </NoWrapper>
      )}
    </Wrapper>
  );
};

export default NotificationList;
