import React, { FC, useContext } from 'react';
import styled, { css, useTheme } from 'styled-components';
import DayRowCell, { Cell } from './DayRowCell';

import { PivotDataModel } from 'api/adjustment';
import { CellDataModel, TableData, highlightColumn } from '../utils';
import { OverrideContext, ProductsContext } from '../TableDataProvider';

const TableWrapper = styled.div``;

const ColumnInfoCell = styled(Cell)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.lightBorder};

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 21;
    `}

  ${({ stickyLeft }) =>
    stickyLeft &&
    css`
      left: 0;
      z-index: 22;
    `}
`;
const RowInfoCell = styled(Cell)`
  min-width: max-content;
  padding: 3px;

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      left: 0;
      z-index: 20;
    `}
`;

const Row = styled.div`
  display: contents; // https://css-tricks.com/get-ready-for-display-contents/
  &:hover {
    .cellItem {
      background-image: ${({ theme }) => theme.palette.common.opacityHover};
    }
  }
`;

const Table = styled.div<{ noOfColumns: number; noOfRows: number }>`
  display: grid;
  position: relative;
  grid-template-columns: ${(props) =>
    `120px repeat(${props.noOfColumns}, 1fr)`};
  grid-template-rows: ${(props) => `30px repeat(${props.noOfRows}, 1fr)`};
  width: 100%;
  overflow-x: auto;
  border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
  max-height: 800px;
  max-width: 100%;
  ${ColumnInfoCell}, ${RowInfoCell} {
    cursor: default;
    font-weight: bold;
    border: hidden;
  }
`;

interface Props {
  data: TableData;
  pricingProducts: string[];
}

const DayTableGrid: FC<Props> = ({ data, pricingProducts }) => {
  const theme = useTheme();

  var dataValue = Array.from(data.values())?.entries()?.next()?.value as Map<
    string,
    PivotDataModel
  >[];

  const days = !!dataValue ? dataValue[1] : new Map<string, PivotDataModel>();
  const noOfDayCols = Array.from(days.values()).length ?? 21;
  const noOfRows = data.size ?? 31;

  return (
    <TableWrapper>
      <Table
        noOfColumns={noOfDayCols}
        noOfRows={noOfRows}
        onMouseLeave={() => {
          var activeCells = document.getElementsByClassName('active');
          if (activeCells.length > 0) {
            var activeCell = activeCells[0] as HTMLDivElement;
            activeCell.classList.remove('active');
          }
          const elements = document.querySelectorAll(`.highlight`);

          elements.forEach((element) => {
            (element as HTMLDivElement).classList.remove('highlight');
          });
        }}
      >
        {data && (
          <>
            <Row>
              <ColumnInfoCell
                sticky={true}
                stickyLeft={true}
                showDetails={false}
                style={{
                  borderBottom: `1px solid ${theme.palette.common.lightBorder}`,
                  borderRight: `1px solid ${theme.palette.common.lightBorder}`,
                }}
              ></ColumnInfoCell>
              {Array.from(days.values()).map((day, index) => {
                return (
                  <ColumnInfoCell
                    sticky={true}
                    showDetails={false}
                    className={`cellItem column-${index}`}
                    key={`columnCell-${index}`}
                    title={`${day.day} days`}
                    onMouseEnter={(e) => highlightColumn(e, -1, index)}
                    style={{
                      borderBottom: `1px solid ${theme.palette.common.lightBorder}`,
                    }}
                  >
                    {day.day}
                  </ColumnInfoCell>
                );
              })}
            </Row>
            {Array.from(data.values()).map((date, rowIndex) => {
              return (
                <Row key={`row-${rowIndex}`} className="rowGrid">
                  <RowInfoCell
                    sticky={true}
                    showDetails={false}
                    className="cellItem"
                    key={`rowCell-${rowIndex}`}
                    title={`Date: ${date.get('1')?.date}`}
                    style={{
                      borderRight: `1px solid ${theme.palette.common.lightBorder}`,
                    }}
                  >{`${date.get('1')?.date}`}</RowInfoCell>
                  {Array.from(date.values()).map((day, colIndex) => {
                    return (
                      <DayRowCell
                        x={rowIndex}
                        y={colIndex}
                        pricingProducts={pricingProducts}
                        key={`${date}:${day}-${colIndex}`}
                        cellData={day}
                        popOverAlignment={
                          day.day < noOfDayCols / 2
                            ? 'alignToRight'
                            : 'alignToLeft'
                        }
                      />
                    );
                  })}
                </Row>
              );
            })}
          </>
        )}
      </Table>
    </TableWrapper>
  );
};

export default DayTableGrid;
