import React, { useEffect, useState } from 'react';

//Using generic line charts component to display the data
import LineChart, {
  DataPoint,
  RenderCustomerXTick,
} from 'components/Graph/GenericLineCharts';

import styled from 'styled-components';
import {
  BookingPatternResponse,
  GraphController,
  OccupancyResponse,
} from 'api/graph';
import Main from 'components/Main';
import Label from 'components/input/Label';
import PTDatePicker from 'components/DateRangePicker/DatePicker';
import moment from 'moment';
import {
  getSpecialSelect,
  selectAvailableFacilityZones,
  selectAvailableZones,
  selectCurrentZones,
} from 'store/zone/selector';
import { useSelector } from 'react-redux';
import Input from 'components/input/Input';
import Select from 'components/input/Select';
import GraphLoader from 'components/Loader/graphLoader';
import Loader from 'components/Loader';
import { getDaysBetweenDates, getSameDayLastYear } from 'util/date';
import { useRouteMatch } from 'react-router';
import { FacilityPageProps } from 'topdefinitions';
import {
  FacilitySpecialZonePicker,
  getSpecialZonePickerValues,
} from 'store/ui/uiutil';
import Sticker from 'components/Sticker';
import { selectUiAllSelected } from 'store/ui/selector';
import ExportButton from 'util/excel';

const GraphTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 20px 0;
  justify-content: space-between;
  align-items: flex-start;
`;

const LeftContent = styled.div`
  display: flex;
  gap: 20px;
`;
const RightContent = styled.div`
  display: flex;
  gap: 40px;
`;

enum ViewMode {
  ANALYSIS = 1,
  FORECAST = 2,
}
const ModeSettings = {
  [ViewMode.ANALYSIS]: {
    title: 'Analysis',
    key: ViewMode.ANALYSIS,
  },
  [ViewMode.FORECAST]: {
    title: 'Forecast',
    key: ViewMode.FORECAST,
  },
};

export function getColorHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + (hash << 5) - hash;
  }

  const adjustedHash = (hash & 0x00ffffff).toString(16).toUpperCase();
  const adjustedColor =
    '#' + '00000'.substring(0, 6 - adjustedHash.length) + adjustedHash;

  // Convert hex color to RGB components
  const red = parseInt(adjustedColor.slice(1, 3), 16);
  const green = parseInt(adjustedColor.slice(3, 5), 16);
  const blue = parseInt(adjustedColor.slice(5, 7), 16);

  // Check if the color is light
  const isLight = red * 0.299 + green * 0.587 + blue * 0.114 > 186;

  // If the color is light, make adjustments
  if (isLight) {
    const factor = 0.7; // Adjust this factor to control darkness
    const adjustedRed = Math.floor(red * factor);
    const adjustedGreen = Math.floor(green * factor);
    const adjustedBlue = Math.floor(blue * factor);
    return `rgb(${adjustedRed}, ${adjustedGreen}, ${adjustedBlue})`;
  }

  return adjustedColor;
}

const Occupancy: React.FC = () => {
  var graphController = new GraphController();

  var inistalStartDate = moment().subtract(1, 'weeks');
  var initialEndDate = moment().add(-1, 'days');
  const activeZones = useSelector(selectCurrentZones);
  const [zoneIds, setZoneIds] = useState<number[]>([]);
  const [segments, setSegments] = useState<number[]>([100, 101]);
  const [mode, setMode] = useState<ViewMode>(ViewMode.ANALYSIS);
  const [fetching, setFetching] = useState<boolean>(false);
  const [_data, setData] = useState<OccupancyResponse>();
  const [startDate, setStartDate] = useState<moment.Moment>(inistalStartDate);
  const [endDate, setEndDate] = useState<moment.Moment>(initialEndDate);
  const [showCompare, setShowCompare] = useState<boolean>(false);
  const [compareDate, setCompareDate] = useState<moment.Moment>(
    getSameDayLastYear(inistalStartDate)
  );
  const [compareEndDate, setCompareEndDate] = useState<moment.Moment>(
    getSameDayLastYear(initialEndDate)
  );
  const [compareData, setCompareData] = useState<OccupancyResponse>();

  const match = useRouteMatch<FacilityPageProps['match']['params']>();
  const facilityId = match?.params?.facilityId;
  var special = getSpecialZonePickerValues(facilityId!);
  var zoneGIDs = activeZones.map((f) => f.gid);

  const allSelected = useSelector(selectUiAllSelected);
  const specialSelected = useSelector(getSpecialSelect);

  useEffect(() => {
    var _zoneIds = activeZones.map((f) => f.id);
    if (!arraysAreEqual(_zoneIds, zoneIds)) {
      setZoneIds(activeZones.map((f) => f.id));
    }
  }, [activeZones]);

  useEffect(() => {
    if (zoneIds.length !== 0) {
      if (mode === ViewMode.ANALYSIS) {
        var startDate = moment().subtract(1, 'weeks');
        var endDate = moment().add(-1, 'days');
        setStartDate(startDate);
        setEndDate(endDate);
        setCompareDate(getSameDayLastYear(startDate));
        setCompareEndDate(getSameDayLastYear(endDate));
      } else {
        var startDate = moment();
        var endDate = moment().add(1, 'weeks');
        setStartDate(startDate);
        setEndDate(endDate);
        setCompareDate(getSameDayLastYear(startDate));
        setCompareEndDate(getSameDayLastYear(endDate));
      }
    }
  }, [mode]);

  useEffect(() => {
    if (zoneIds.length !== 0) {
      setData(undefined);
      setFetching(true);
      setTimeout(() => {
        if (mode === ViewMode.ANALYSIS) {
          AnalysisData();
        } else {
          ForecastData();
        }
      }, 0);
    }
  }, [startDate, zoneIds, endDate]);

  useEffect(() => {
    if (showCompare && zoneIds.length !== 0) {
      setCompareData(undefined);
      setTimeout(() => {
        if (mode === ViewMode.ANALYSIS) {
          AnalysisData(true);
        } else {
          ForecastData(true);
        }
      }, 0);
    }
  }, [compareDate, showCompare, zoneIds, compareEndDate]);

  const AnalysisData = async (compare?: boolean) => {
    var result = await graphController.getOccupancyAnalysis({
      StartDate: (compare ? compareDate : startDate).format('YYYY-MM-DD'),
      EndDate: (compare ? moment(compareEndDate) : moment(endDate))
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      ZoneIds: zoneIds,
      CapacityDate: startDate.format('YYYY-MM-DD'),
      CapacityEndDate: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
      Segments: [100, 101],
    });
    if (!compare) {
      setData(result);
      setFetching(false);
    } else {
      setCompareData(result);
    }
  };

  const ForecastData = async (compare?: boolean) => {
    var result = await graphController.getOccupancyForecase({
      StartDate: (compare ? compareDate : startDate).format('YYYY-MM-DD'),
      EndDate: (compare ? moment(compareEndDate) : moment(endDate))
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      ZoneIds: zoneIds,
      CapacityDate: startDate.format('YYYY-MM-DD'),
      CapacityEndDate: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
      Segments: [100, 101],
    });
    if (!compare) {
      setData(result);
      setFetching(false);
    } else {
      setCompareData(result);
    }
  };

  const arraysAreEqual = (
    _arr1: (string | number)[],
    _arr2: (string | number)[]
  ) => {
    var arr1 = _arr1.sort();
    var arr2 = _arr2.sort();
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };
  var combinedDict = special.reduce((acc, curr: FacilitySpecialZonePicker) => {
    var list = curr.zoneIds.reduce((_acc, _curr) => {
      if (zoneGIDs.includes(_curr)) {
        _acc.push(activeZones.find((f) => f.gid === _curr)!.id);
      }
      return _acc;
    }, [] as number[]);

    if (list.length === curr.zoneIds.length) {
      acc[curr.id] = list;
    }
    return acc;
  }, {} as { [key: string]: number[] });

  var graphData = [] as DataPoint[];
  var maxValue = 100;
  var avg = 0;
  var cavg = 0;
  let specialIds = specialSelected;
  var activeIds = zoneIds;
  if (allSelected) {
    specialIds = [];
    activeIds = [];
  }
  specialIds.forEach((sid) => {
    var ids = combinedDict[sid];
    if (!!ids) activeIds = activeIds.filter((f) => ids.indexOf(f) === -1);
  });
  if (!!_data) {
    var data = _data.data;
    var _compareData = compareData?.data;

    var segment =
      segments.length === 2
        ? 'total_occ'
        : segments[0] === 100
        ? 'driveup_occ'
        : 'booking_occ';

    console.log(allSelected, specialIds);

    if (allSelected) {
      graphData = Object.keys(data[`${segment}` as keyof typeof data]).reduce(
        (acc, curr, idx) => {
          var los = Number(curr);
          var occupancy = Math.min(
            100,
            Number(data[`${segment}` as keyof typeof data][los])
          );
          acc.push({ LOS: curr, Occupancy: Number(occupancy) });

          return acc;
        },
        graphData as DataPoint[]
      );
      if (showCompare && !!_compareData) {
        Object.keys(
          _compareData[`${segment}` as keyof typeof _compareData]
        ).forEach((key) => {
          var index = graphData.findIndex((f) => f.LOS === key);
          var los = Number(key);
          var c_occu = Math.min(
            100,
            Number(
              _compareData![`${segment}` as keyof typeof _compareData][los]
            )
          );
          if (index !== -1) {
            graphData[index] = {
              ...graphData[index],
              C_Occupancy: c_occu,
            };
          } else {
            graphData.push({
              LOS: key,
              C_Occupancy: c_occu,
            });
          }
        });
      }
    }
    if (specialIds.length !== 0) {
      var specialGraphPoint: DataPoint[] = [];
      specialIds.forEach((special) => {
        var zoneIds = combinedDict[special];
        if (!!zoneIds) {
          if (!!data[`${segment}` as keyof typeof data]) {
            specialGraphPoint = Object.keys(
              data[`${segment}` as keyof typeof data]
            ).reduce((acc, curr, idx) => {
              var los = Number(curr);
              var occupancy = Math.min(
                100,
                Number(data[`${segment}` as keyof typeof data][los])
              );
              var graphPoint = acc.find((f) => f.LOS === curr);
              if (!!graphPoint) {
                if (graphPoint[special] === undefined) {
                  graphPoint[special] = 0;
                }
                graphPoint[special] =
                  Number(graphPoint[special]) + Number(occupancy);
              } else {
                acc.push({ LOS: curr, [special]: Number(occupancy) });
              }
              return acc;
            }, specialGraphPoint as DataPoint[]);
            if (
              showCompare &&
              !!compareData &&
              !!_compareData![`${segment}` as keyof typeof _compareData]
            ) {
              Object.keys(
                _compareData![`${segment}` as keyof typeof _compareData]
              ).forEach((key) => {
                var los = Number(key);
                var c_occupancy = Math.min(
                  100,
                  Number(
                    _compareData![`${segment}` as keyof typeof _compareData][
                      los
                    ]
                  )
                );
                var point = specialGraphPoint.find((f) => f.LOS === key);
                if (!!point) {
                  if (point[`C_${special}`] === undefined) {
                    point[`C_${special}`] = 0;
                  }
                  point[`C_${special}`] =
                    Number(point[`C_${special}`]) + c_occupancy;
                } else {
                  specialGraphPoint.push({
                    LOS: key,
                    [`C_${special}`]: c_occupancy,
                  });
                }
              });
            }
          }
        }
        // specialGraphPoint.forEach((point) => {
        //   point[special] = Number(
        //     (Number(point[special]) / zoneIds.length).toFixed(2)
        //   );
        //   if (point[`C_${special}`]) {
        //     point[`C_${special}`] = Number(
        //       (Number(point[`C_${special}`]) / zoneIds.length).toFixed(2)
        //     );
        //   }
        // });
      });
      graphData = specialGraphPoint;
    }
    if (activeIds.length !== 0) {
      activeIds.forEach((zoneId) => {
        if (!!data[`${segment}_${zoneId}`]) {
          graphData = Object.keys(
            data[`${segment}_${zoneId}` as keyof typeof data]
          ).reduce((acc, curr, idx) => {
            var los = Number(curr);
            var occupancy = Math.min(
              100,
              Number(data[`${segment}_${zoneId}` as keyof typeof data][los])
            );

            var graphPoint = acc.find((f) => f.LOS === curr);
            maxValue = Math.max(occupancy, maxValue);
            if (!!graphPoint) {
              graphPoint[zoneId.toString()] = occupancy;
            } else {
              acc.push({ LOS: curr, [zoneId.toString()]: occupancy });
            }

            return acc;
          }, graphData as DataPoint[]);
        }
        if (
          showCompare &&
          !!_compareData &&
          !!_compareData[`${segment}_${zoneId}`]
        ) {
          Object.keys(
            _compareData[`${segment}_${zoneId}` as keyof typeof _compareData]
          ).forEach((key) => {
            var los = Number(key);
            var c_occupancy = Math.min(
              100,
              Number(
                _compareData![
                  `${segment}_${zoneId}` as keyof typeof _compareData
                ][los]
              )
            );
            var point = graphData.find((f) => f.LOS === key);
            if (!!point) {
              point[`C_${zoneId}`] = c_occupancy;
            } else {
              graphData.push({
                LOS: key,
                [`C_${zoneId}`]: c_occupancy,
              });
            }
          });
        }
      });
    }
    var index = 0;
    avg = Object.keys(data[segment as keyof typeof data]).reduce(
      (acc, curr, idx) => {
        var los = Number(curr);
        var occupancy = Number(data[segment as keyof typeof data][los]);

        acc += occupancy;
        index = idx + 1;
        return acc;
      },
      0
    );
    avg = avg / index;
    var cindex = 0;
    if (!!_compareData) {
      cavg = Object.keys(_compareData[segment as keyof typeof data]).reduce(
        (acc, curr, idx) => {
          var los = Number(curr);
          var occupancy = Number(
            _compareData![segment as keyof typeof _compareData][los]
          );

          acc += occupancy;
          cindex = idx + 1;
          return acc;
        },
        0
      );
      cavg = cavg / cindex;
    }
  }

  var lines = [] as { key: string; name: string; color: string }[];
  var toolTip = {} as {
    [key: string]: {
      title: string;
      color: string;
      formatter: (value: any) => any;
    };
  };
  if (allSelected) {
    lines.push({
      key: 'Occupancy',
      name: 'Occupancy',
      color:
        segments.length == 2
          ? 'black'
          : segments[0] === 100
          ? '#8957A0'
          : '#F7A379',
    });
    lines.push({
      key: 'C_Occupancy',
      name: 'Compare Occupancy',
      color:
        segments.length == 2
          ? '#adb8e7'
          : segments[0] === 100
          ? '#d2a7e6'
          : '#eec8b2',
    });
    toolTip['Occupancy'] = {
      title: 'Occupancy',
      color:
        segments.length == 2
          ? '#adb8e7'
          : segments[0] === 100
          ? '#8957A0'
          : '#F7A379',
      formatter: (value: any) => {
        return `${Number(value).toFixed(2)}%`;
      },
    };
    toolTip['C_Occupancy'] = {
      title: 'Compare Occupancy',
      color:
        segments.length == 2
          ? 'grey'
          : segments[0] === 100
          ? '#d2a7e6'
          : '#eec8b2',
      formatter: (value: any) => {
        return `${Number(value).toFixed(2)}%`;
      },
    };
  }
  if (specialIds.length !== 0) {
    specialIds.forEach((special) => {
      var cPoint =
        graphData.length > 0 ? graphData[0][`C_${special}`] : undefined;
      lines.push({
        key: special,
        name: 'Occupancy ' + special,
        color: getColorHash(special),
      });
      toolTip[special] = {
        title: 'Occupancy ' + special,
        color: getColorHash(special),
        formatter: (value: any) => {
          return `${Number(value).toFixed(2)}%`;
        },
      };
      if (!!cPoint && showCompare) {
        lines = [
          ...lines,
          {
            key: `C_${special}`,
            name: 'Occupancy ' + special + ' prev. period',
            color: getColorHash(special + '_compare'),
          },
        ];
        toolTip[`C_${special}`] = {
          title: 'Occupancy ' + special + ' prev. period',
          color: getColorHash(special + '_compare'),
          formatter: (value: any) => {
            return `${Number(value).toFixed(2)}%`;
          },
        };
      }
    });
  }
  if (activeIds.length !== 0) {
    activeIds.forEach((zoneId) => {
      var zone = activeZones.find((f) => f.id === zoneId);
      console.log(graphData);
      var cPoint =
        graphData.length > 0 ? graphData[0][`C_${zoneId}`] : undefined;
      if (!!zone) {
        lines = [
          ...lines,
          {
            key: zoneId.toString(),
            name: 'Occupancy ' + zone!.name,
            color: getColorHash(zone!.gid),
          },
        ];
        toolTip[zoneId] = {
          title: 'Occupancy ' + zone!.name,
          color: getColorHash(zone!.gid),
          formatter: (value: any) => {
            return `${Number(value).toFixed(2)}%`;
          },
        };
      }
      if (!!zone && !!cPoint && showCompare) {
        lines = [
          ...lines,
          {
            key: `C_${zoneId}`,
            name: 'Occupancy ' + zone!.name + ' prev. period',
            color: getColorHash(zone!.gid + '_compare'),
          },
        ];
        toolTip[`C_${zoneId}`] = {
          title: 'Occupancy ' + zone!.name + ' prev. period',
          color: getColorHash(zone!.gid + '_compare'),
          formatter: (value: any) => {
            return `${Number(value).toFixed(2)}%`;
          },
        };
      }
    });
  }

  return (
    <Main>
      <GraphTop>
        <LeftContent>
          <Label label={'Mode'}>
            <Select
              onChange={(eve) => {
                var value = Number(eve.target.value) as ViewMode;
                setMode(value);
              }}
              value={mode}
            >
              {Object.keys(ModeSettings).map((modeKey) => (
                <option key={modeKey} value={modeKey}>
                  {ModeSettings[Number(modeKey) as ViewMode].title}
                </option>
              ))}
            </Select>
          </Label>
          <Label label={'Segment'}>
            <Select
              onChange={(eve) => {
                var value = eve.target.value;
                if (value === 'null') {
                  setSegments([100, 101]);
                } else {
                  setSegments([Number(value)]);
                }
              }}
              value={segments.length != 1 ? 'null' : segments[0]}
            >
              <option value={'null'}>Total</option>
              <option value={101}>Booking</option>
              <option value={100}>Driveup</option>
            </Select>
          </Label>
          <Label label="From date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setStartDate(moment(date));
                var daysBetween = getDaysBetweenDates(
                  startDate.toDate(),
                  endDate.toDate()
                );
                var daysBetweenCompare = getDaysBetweenDates(
                  compareDate.toDate(),
                  startDate.toDate()
                );
                setCompareDate(moment(date).add(-daysBetweenCompare, 'days'));
                if (moment(date) > endDate) {
                  setEndDate(moment(date));
                } else {
                  setCompareEndDate(
                    moment(date).add(-daysBetweenCompare + daysBetween, 'days')
                  );
                  setEndDate(moment(date).add(daysBetween, 'days'));
                }
              }}
              selected={startDate.toDate()}
              maxDate={
                mode === ViewMode.ANALYSIS
                  ? moment().add(-1, 'days').toDate()
                  : null
              }
              minDate={mode !== ViewMode.ANALYSIS ? moment().toDate() : null}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="To date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {
                setEndDate(moment(date));
                var daysBetween = getDaysBetweenDates(
                  compareEndDate.toDate(),
                  endDate.toDate()
                );
                setCompareEndDate(moment(date).add(-daysBetween, 'days'));
              }}
              selected={endDate.toDate()}
              maxDate={
                mode === ViewMode.ANALYSIS
                  ? moment().add(-1, 'days').toDate()
                  : null
              }
              minDate={startDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <input
            style={{ marginRight: '-7px', marginTop: '9px' }}
            type="checkbox"
            checked={showCompare}
            onChange={(evt) => {
              setShowCompare(!showCompare);
            }}
          />
          <Label label="Compare from date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              disabled={!showCompare}
              onChange={(date: Date) => {
                setCompareDate(moment(date));
                var daysBetween = getDaysBetweenDates(
                  startDate.toDate(),
                  endDate.toDate()
                );
                setCompareEndDate(moment(date).add(daysBetween, 'days'));
              }}
              selected={compareDate.toDate()}
              dropdownMode="select"
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
          <Label label="Compare to date">
            <PTDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={(date: Date) => {}}
              selected={compareEndDate.toDate()}
              disabled={true}
              selectsStart
              showMonthDropdown
              showYearDropdown
            />
          </Label>
        </LeftContent>
        <RightContent>
          {avg !== 0 && (
            <Sticker
              title={'Average occupancy'}
              value={Number(avg.toFixed(2))}
              unit={'%'}
            />
          )}
          {showCompare && cavg !== 0 && (
            <Sticker
              title={'Avg prev. period'}
              value={Number(cavg.toFixed(2))}
              unit={'%'}
            />
          )}
          <ExportButton
            name={'Occupancy'}
            data={{
              data: graphData.map((f) => {
                var obj = {
                  Date: moment(startDate)
                    .add(Number(f.LOS), 'days')
                    .format('YYYY-MM-DD'),
                } as any;
                if (showCompare) {
                  obj['Compare Date'] = moment(compareDate)
                    .add(Number(f.LOS), 'days')
                    .format('YYYY-MM-DD');
                }
                obj.LOS = f.LOS;
                Object.keys(f).forEach((key) => {
                  if (key !== 'LOS') {
                    if (key.startsWith('C_')) {
                      var keySplit = key.split('_');
                      if (!isNaN(keySplit[1] as any)) {
                        var zoneId = Number(keySplit[1]);
                        var zone = activeZones.find((f) => f.id === zoneId);
                        if (!!zone) {
                          obj[`Compare ${zone.name}`] = f[key];
                        }
                      } else {
                        obj[`Compare ${keySplit[1]}`] = f[key];
                      }
                    } else {
                      if (!isNaN(key as any)) {
                        var zoneId = Number(key);
                        var zone = activeZones.find((f) => f.id === zoneId);
                        if (!!zone) {
                          obj[zone.name] = f[key];
                        }
                      } else {
                        obj[key] = f[key];
                      }
                    }
                  }
                });

                return obj;
              }),
              extra: {
                average: avg,
                compare_avg: cavg,
                startDate: startDate,
                endDate: endDate,
                compareStartDate: compareDate,
                compareEndDate: compareEndDate,
                segments: segments,
                ...zoneIds.map((f) => {
                  var zone = activeZones.find((z) => z.id === f);
                  return zone?.name;
                }),
              },
            }}
          />
        </RightContent>
      </GraphTop>
      <div style={{ position: 'relative' }}>
        <LineChart
          startDate={startDate}
          customerXTick={(props) => {
            return (
              <RenderCustomerXTick
                props={props}
                date={moment(startDate)
                  .startOf('days')
                  .add(Number(props.payload.value), 'days')}
              />
            );
          }}
          strokeWidth={3}
          withDots={true}
          xTicks={
            getDaysBetweenDates(startDate.toDate(), endDate.toDate()) ?? 0
          }
          yTicks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          yTickFormatter={(value: any) => {
            return `${value}%`;
          }}
          maxYValue={maxValue + 10}
          xTickFormatter={(value: any) => {
            var days = Number(value);
            return moment(startDate).add(days, 'days').format('DD/MM');
          }}
          data={graphData}
          tooltip={{
            LOS: {
              title: 'Date',
              color: 'black',
              formatter: (value: any) => {
                var days = Number(value);
                return moment(startDate).add(days, 'days').format('YYYY-MM-DD');
              },
            },
            ...toolTip,
          }}
          lines={lines}
          xDataKey="LOS"
        />
        {(graphData.length === 0 || !!fetching) && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#ffffff',
              opacity: 0.35,
            }}
          >
            <Loader title={'Could not find data'} forceError={!!_data} />
          </div>
        )}
      </div>
    </Main>
  );
};

export default Occupancy;
