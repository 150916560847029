import Button from 'components/input/Button';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import AdjustmentPopOver from './AdjustmentPopOver';
import { CellDataModel, highlightColumn } from '../utils';
import useAdjustmentsData from '../useAdjustmentsData';
import { ProductView, ProductsContext } from '../TableDataProvider';
import { useSelector } from 'react-redux';
import { hasEditRights } from 'store/uiSettings/selectors';
import moment from 'moment';

export const Cell = styled.div<{
  showDetails: boolean;
  cellValue?: number;
  sticky?: boolean;
  stickyLeft?: boolean;
  topBlack?: boolean;
  leftBlack?: boolean;
  bottomBlack?: boolean;
  rightBlack?: boolean;
}>`
  cursor: pointer;
  padding: 2px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
  min-width: 2rem;
  text-align: center;
  position: relative;

  ${(props) => props.leftBlack && 'border-left: 1px solid black;'}
  ${(props) => props.topBlack && 'border-top: 1px solid black;'}
  ${(props) => props.bottomBlack && 'border-bottom: 1px solid black;'}
  ${(props) => props.rightBlack && 'border-right: 1px solid black;'}

  &.highlight {
    background-image: linear-gradient(
      ${({ theme }) => theme.palette.common.opacityHover},
      ${({ theme }) => theme.palette.common.opacityHover}
    );
  }
  &.active {
    background-image: linear-gradient(
      ${({ theme }) => theme.palette.common.opacityActive},
      ${({ theme }) => theme.palette.common.opacityActive}
    );
  }
  &.active {
    color: black;
    border: 1px solid grey;
  }
  ${(props) =>
    props.showDetails &&
    css`
      border: 1px solid grey;
      background-color: ${props.theme.palette.common.lightBorder};
    `}

  ${(props) =>
    !!props.cellValue &&
    props.cellValue > 0 &&
    css`
      background-color: #8957a04d;
    `}

    ${(props) =>
    !!props.cellValue &&
    props.cellValue < 0 &&
    css`
      background-color: #00000013;
    `}

    &.overridden {
    font-weight: bold;
    border: 1px solid ${({ theme }) => theme.palette.common.orange};
  }
`;

const PopoverHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 2px;
`;

interface RowCellProps {
  cellData: CellDataModel;
  x: number;
  y: number;
  pricingProducts: string[];
  popOverAlignment: 'alignToLeft' | 'alignToRight';
}
const DayRowCell: FC<RowCellProps> = ({
  cellData,
  x,
  y,
  popOverAlignment,
  pricingProducts,
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number>();
  const [overrideCell, setOverrideCell] = useState<CellDataModel>();
  const [diffInputDisplay, setDiffInputDisplay] = useState<number>(
    cellData.difference
  );
  const { overrides, addOverride, removeOverride } = useAdjustmentsData();
  const product = useContext(ProductsContext);
  const canEdit = useSelector(hasEditRights);
  const [borders, setBorders] = useState<{
    top: boolean;
    left: boolean;
    bottom: boolean;
    right: boolean;
  }>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    if (overrides.overrides[product.selectedProduct]) {
      const override = overrides.overrides[product.selectedProduct].find(
        (overrides) =>
          overrides.date === cellData.date && overrides.day === cellData.day
      );
      setOverrideCell(() => override);

      if (override) {
        setDiffInputDisplay(() => override.difference);
      } else {
        setDiffInputDisplay(() => cellData.difference);
      }
    }
  }, [overrides.overrides[product.selectedProduct]]);

  useEffect(() => {
    if (inputValue) {
      updateDiff(inputValue);
    }
  }, [inputValue]);

  const displayPopOver = (show: boolean) => {
    setShowDetails(show);
  };

  const updateDiff = (override: number) => {
    if (cellData.yesterday && override)
      setDiffInputDisplay(override - cellData.yesterday);
  };

  console.log('PRICING PRODUCTS', pricingProducts);
  useEffect(() => {
    var yday = moment(cellData.date)
      .add(cellData.day - 1, 'days')
      .toISOString();
    var today = moment(cellData.date).add(cellData.day, 'days').toISOString();
    var topLeft = pricingProducts.includes(yday);
    var botRight = pricingProducts.includes(today);

    console.log('Dates', yday, today, topLeft, botRight);
    var borderData = {
      top: topLeft,
      left: topLeft,
      bottom: botRight,
      right: botRight,
    };
    console.log('Borders', borderData);
    setBorders(borderData);
  }, [pricingProducts]);

  return (
    <>
      <Cell
        key={`${cellData.date}:${cellData.day}`}
        topBlack={borders.top}
        leftBlack={borders.left}
        bottomBlack={borders.bottom}
        rightBlack={borders.right}
        tabIndex={0}
        onClick={(e) => {
          if (!canEdit) return;
          e.stopPropagation();
          displayPopOver(true);
        }}
        onKeyPress={(e) => {
          if (!canEdit) return;
          e.stopPropagation();
          displayPopOver(true);
        }}
        className={`cellItem row-${x} column-${y} ${
          !!overrideCell ? 'overridden' : ''
        }`}
        showDetails={showDetails}
        onMouseEnter={(e) => highlightColumn(e, x, y)}
        /* For render style */
        cellValue={
          !!overrideCell
            ? overrideCell[product.productView as keyof typeof ProductView]
            : cellData[product.productView as keyof typeof ProductView]
        }
      >
        {/* Display in the cell */}
        {!!overrideCell
          ? overrideCell[product.productView as keyof typeof ProductView]
          : cellData[product.productView as keyof typeof ProductView]}
        {showDetails && (
          <AdjustmentPopOver
            headerContent={
              <PopoverHeader>
                <div>{`Date: ${cellData.date}`}</div>
                <div>{`Days: ${cellData.day}`}</div>
              </PopoverHeader>
            }
            onClose={() => {
              displayPopOver(false);
              setInputValue(undefined);
              setDiffInputDisplay(
                overrideCell?.difference ?? cellData.difference
              ); // Back to original value
            }}
            alignment={popOverAlignment}
          >
            {/* Pop-over Form */}
            <Label label="Yesterday">
              <Input
                name="Yesterday"
                defaultValue={`${
                  overrideCell?.yesterday || cellData.yesterday
                }`}
                disabled
              ></Input>
            </Label>
            <Label label="Today">
              <Input
                name="Today"
                defaultValue={
                  inputValue
                    ? inputValue
                    : `${overrideCell?.today || cellData.today}`
                }
                disabled
              ></Input>
            </Label>
            <Label label="Diff">
              <Input
                name="Diff"
                value={diffInputDisplay}
                type="number"
                disabled
              ></Input>
            </Label>
            <Label label="Override">
              <Input
                name="Override"
                defaultValue={overrideCell?.override ?? ''}
                type="number"
                onChange={(e) => {
                  setInputValue(Number(e.target.value));
                  updateDiff(Number(e.target.value));
                }}
              ></Input>
            </Label>

            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (inputValue)
                  addOverride(overrideCell || cellData, inputValue);
                displayPopOver(false);
              }}
            >
              Add override
            </Button>
            {overrideCell && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  removeOverride(overrideCell);
                  setInputValue(undefined);
                  setDiffInputDisplay(
                    overrideCell.difference ?? cellData.difference
                  ); // Back to original value
                  displayPopOver(false);
                }}
              >
                Reset
              </Button>
            )}
          </AdjustmentPopOver>
        )}
      </Cell>
    </>
  );
};

export default DayRowCell;
